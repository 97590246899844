import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL+"/api/v1/currency";

class CurrencyService {

    getActualCurrency(storeId){
        return axios.get(API_BASE_URL+'/actual/'+ storeId, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    create(currency, storeId){
        return axios.put(API_BASE_URL+ '/'+storeId, currency, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    getAll(storeId){
        return axios.get(API_BASE_URL+ '/'+storeId, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }
}

export default new CurrencyService()