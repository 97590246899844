import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Scrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import logo from "../../assets/img/costic/icon.png";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Sidenavigation extends Component {
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
  }

  removeoverlay = () => {
    $(".ms-body").toggleClass("ms-aside-left-open");
    $("#ms-side-nav").toggleClass("ms-aside-open");
    $(".ms-aside-overlay.ms-overlay-left").toggleClass("d-block");
  };

  componentDidMount() {
    if (cookies.get("roles") === "KITCHEN_WORKER") {
      this.removeoverlay();
    }
    function setActiveMenuItem() {
      $(".ms-main-aside .menu-item>a").on("click", function () {
        $(this).removeAttr("href");
        var element = $(this).parent("li");
        if (element.hasClass("active")) {
          element.removeClass("active");
          element.find("li").removeClass("active");
          element.find(".collapse").slideUp();
        } else {
          element.addClass("active");
          element.children(".collapse").slideDown();
          element.siblings("li").children(".collapse").slideUp();
          element.siblings("li").removeClass("active");
          element.siblings("li").find("li").removeClass("active");
          element.siblings("li").find(".collapse").slideUp();
        }
      });
    }
    setActiveMenuItem();
  }

  signOut() {
    cookies.set("token", "");
    window.location.reload();
  }

  render() {
    return (
      <div>
        <div
          className="ms-aside-overlay ms-overlay-left ms-toggler"
          onClick={this.removeoverlay}
        ></div>
        <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
        <Scrollbar
          id="ms-side-nav"
          className="side-nav fixed ms-aside-scrollable ms-aside-left"
        >
          {/* Logo */}
          <div className="logo-sn ms-d-block-lg">
            <Link className="pl-0 ml-0 text-center" to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          {/* Navigation */}
          <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
            {(cookies.get("roles") === "MASTER" ||
              cookies.get("roles") === "MANAGER") && (
              <li className="menu-item">
                <Link to="#" className="has-chevron">
                  {" "}
                  <span>
                    <i className="fas fa-clipboard-list fs-16" />
                    Rechnungen
                  </span>
                </Link>

                <ul
                  id="product"
                  className="collapse"
                  aria-labelledby="product"
                  data-parent="#side-nav-accordion"
                >
                  <li>
                    <Link to="/">
                      {" "}
                      <span>Rechnungen</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {(cookies.get("roles") === "MASTER" ||
              cookies.get("roles") === "MANAGER") && (
              <li className="menu-item">
                <Link to="#" className="has-chevron">
                  {" "}
                  <span>
                    <i className="fas fa-list fs-16" />
                    Angebote
                  </span>
                </Link>

                <ul
                  id="product"
                  className="collapse"
                  aria-labelledby="product"
                  data-parent="#side-nav-accordion"
                >
                  <li>
                    <Link to="/offers">
                      {" "}
                      <span>Angebote</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {(cookies.get("roles") === "MASTER" ||
              cookies.get("roles") === "MANAGER") && (
              <li className="menu-item">
                <Link to="#" className="has-chevron">
                  {" "}
                  <span>
                    <i className="fas fa-file fs-16" />
                    Vorlagen
                  </span>
                </Link>

                <ul
                  id="product"
                  className="collapse"
                  aria-labelledby="product"
                  data-parent="#side-nav-accordion"
                >
                  <li>
                    <Link to="/footers">
                      {" "}
                      <span>Fussnoten</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/templates">
                      {" "}
                      <span>Textvorlagen</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/documents">
                      {" "}
                      <span>Dokumente</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            <li className="menu-item">
              {(cookies.get("roles") === "MASTER" ||
                cookies.get("roles") === "WORKER") && (
                <Link to="#" className="has-chevron">
                  {" "}
                  <span>
                    <i className="fa fa-clock fs-16" />
                    Zeiterfassung
                  </span>
                </Link>
              )}
              <ul
                id="customer"
                className="collapse"
                aria-labelledby="customer"
                data-parent="#side-nav-accordion"
              >
                {(cookies.get("roles") === "MASTER" ||
                  cookies.get("roles") === "MANAGER") && (
                  <li>
                    {" "}
                    <Link to="/worker-sheets">Arbeitsscheine</Link>
                  </li>
                )}
                {(cookies.get("roles") === "MASTER" ||
                  cookies.get("roles") === "WORKER") && (
                  <li>
                    {" "}
                    <Link to="/worker-times">Arbeitszeiten</Link>
                  </li>
                )}
              </ul>
            </li>
            <li className="menu-item">
              {(cookies.get("roles") === "MASTER" ||
                cookies.get("roles") === "DRIVER") && (
                <Link to="#" className="has-chevron">
                  {" "}
                  <span>
                    <i className="fa fa-building fs-16" />
                    Mitarbeiter
                  </span>
                </Link>
              )}
              <ul
                id="customer"
                className="collapse"
                aria-labelledby="customer"
                data-parent="#side-nav-accordion"
              >
                <li>
                  {" "}
                  <Link to="/workers">Mitarbeiter</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/add-worker">Mitarbeiter hinzufügen</Link>
                </li>
              </ul>
            </li>
            <li className="menu-item">
              {(cookies.get("roles") === "MASTER" ||
                cookies.get("roles") === "DRIVER") && (
                <Link to="#" className="has-chevron">
                  {" "}
                  <span>
                    <i className="fa fa-briefcase fs-16" />
                    Firma
                  </span>
                </Link>
              )}
              <ul
                id="customer"
                className="collapse"
                aria-labelledby="customer"
                data-parent="#side-nav-accordion"
              >
                <li>
                  {" "}
                  <Link to="/company">Firmendaten</Link>
                </li>
              </ul>
            </li>
            {(cookies.get("roles") === "MASTER" ||
              cookies.get("roles") === "MANAGER") && (
              <li className="menu-item">
                <Link to="#" className="has-chevron">
                  {" "}
                  <span>
                    <i className="fas fa-user-friends fs-16" />
                    Kunden{" "}
                  </span>
                </Link>
                <ul
                  id="customer"
                  className="collapse"
                  aria-labelledby="customer"
                  data-parent="#side-nav-accordion"
                >
                  <li>
                    {" "}
                    <Link to="/customer-list">Kunden</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/add-customer">Kunde hinzufügen</Link>
                  </li>
                </ul>
              </li>
            )}
            {(cookies.get("roles") === "MASTER" ||
              cookies.get("roles") === "MANAGER") && (
              <li className="menu-item">
                <Link to="#" className="has-chevron">
                  {" "}
                  <span>
                    <i className="fa fa-dolly-flatbed fs-16" />
                    Lieferanten
                  </span>
                </Link>
                <ul
                  id="customer"
                  className="collapse"
                  aria-labelledby="customer"
                  data-parent="#side-nav-accordion"
                >
                  <li>
                    <Link to="/suppliers">
                      {" "}
                      <span>Lieferanten</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/add-supplier">
                      {" "}
                      <span>Lieferant hinzufügen</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/import-supplier">
                      {" "}
                      <span>Lieferanten Importieren</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {(cookies.get("roles") === "MASTER" ||
              cookies.get("roles") === "MANAGER") && (
              <li className="menu-item">
                <Link to="#" className="has-chevron">
                  {" "}
                  <span>
                    <i className="fa fa-puzzle-piece fs-16" />
                    Artikel{" "}
                  </span>
                </Link>
                <ul
                  id="product"
                  className="collapse"
                  aria-labelledby="product"
                  data-parent="#side-nav-accordion"
                >
                  <li>
                    <Link to="/shopping">
                      {" "}
                      <span>Einkauf</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/shopping-articles">
                      {" "}
                      <span>Einkaufsartikel</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {cookies.get("roles") === "MASTER" && (
              <li className="menu-item">
                <Link to="#" className="has-chevron">
                  {" "}
                  <span>
                    <i className="fa fa-store fs-16" />
                    Benutzer{" "}
                  </span>
                </Link>
                <ul
                  id="product"
                  className="collapse"
                  aria-labelledby="product"
                  data-parent="#side-nav-accordion"
                >
                  <Link to="/user-list">
                    {" "}
                    <span>Benutzer</span>
                  </Link>
                </ul>
              </li>
            )}
          </ul>
          {cookies.get("token") ===
            "b5c9667644da67241316db50cc788bfd078849de" && (
            <div>
              <span
                style={{
                  paddingLeft: "20px",
                  paddingTop: "10px",
                }}
              >
                Eingeloggt: {cookies.get("user")}
              </span>
              <button
                onClick={this.signOut}
                style={{ margin: "5px", backgroundColor: "#ff6877" }}
              >
                <FontAwesomeIcon
                  title="Logout"
                  size="2x"
                  icon={faRightFromBracket}
                />
              </button>
            </div>
          )}
        </Scrollbar>
      </div>
    );
  }
}

export default Sidenavigation;
