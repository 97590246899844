import React, { Component } from 'react'
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import ArticleService from '../services/ArticleService';
import { Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import PropTypes from 'prop-types';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    translate
} from 'react-switch-lang';
import en from '../languages/en.json';
import de from '../languages/de.json';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
setTranslations({ en, de });
var userLang = navigator.language || navigator.userLanguage;
setDefaultLanguage(userLang);
setLanguageCookie();

class AddShoppingArticleItemComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shoppingId: this.props.match.params.id,
            shoppingArticleId: 0,
            taxFee: 0,
            priceWithTax: 0,
            priceWithoutTax: 0,
            amount: 0,
            total: 0,
            taxFees: [],
            shoppingArticles: [],
            shoppingArticlesByShopping: []
        }
        this.changeTaxFeeHandler = this.changeTaxFeeHandler.bind(this);
        this.changePriceWithTaxHandler = this.changePriceWithTaxHandler.bind(this);
        this.saveOrUpdate = this.saveOrUpdate.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        ArticleService.getAllTaxFees().then((res2) => {
            ArticleService.getAllArticleAmounts().then((res3) => {
                ArticleService.getAllArticleComponentsById(this.state.shoppingId).then((res4) => {
                    this.setState({
                        taxFees: res2.data,
                        shoppingArticles: res3.data,
                        shoppingArticlesByShopping: res4.data,
                        shoppingArticleId: res3.data[0].id,
                        priceWithTax: res3.data[0].lastPriceWithTax,
                        priceWithoutTax: res3.data[0].lastPriceWithoutTax,
                        taxFee: Number((res3.data[0].lastPriceWithoutTax / (res3.data[0].lastPriceWithTax / 100)) - 100).toFixed(2),
                    })
                })
            })
        })
    }

    saveOrUpdate = (e) => {
        e.preventDefault();
        let a = {
            amount: this.state.amount,
            priceWithTax: this.state.priceWithTax,
            priceWithoutTax: this.state.priceWithoutTax,
            total: Number(this.state.amount * this.state.priceWithoutTax).toFixed(2)
        };
        console.log('ShoppingArticleItem => ' + JSON.stringify(a));
        ArticleService.createShoppingArticleItem(this.state.shoppingId, this.state.shoppingArticleId, this.state.taxFee, a).then(res => {
            window.location.reload();
        });
    }

    changePriceWithTaxHandler = (event) => {
        var taxfeeNew = this.state.taxFee;
        var priceWithTaxNew = event.target.value;
        this.setState({ priceWithTax: priceWithTaxNew, priceWithoutTax: Number(Number(priceWithTaxNew) + ((priceWithTaxNew * taxfeeNew) / 100)).toFixed(2) });
    }

    findTaxFee(taxFee) {
        return this.state.taxFees.filter(item => {
            return item.taxFee.value == taxFee
        })
    }

    changeTaxFeeHandler = (event) => {
        var taxfeeNew = event.target.value;
        var priceWithTax = this.state.priceWithTax;
        this.setState({ taxFee: taxfeeNew, priceWithoutTax: Number(Number(priceWithTax) + (priceWithTax * taxfeeNew / 100)).toFixed(2) });
    }

    changeAmountHandler = (event) => {
        this.setState({ amount: event.target.value });
    }

    changeShoppingArticleIdHandler = (event) => {
        var id = event.target.value;
        this.setState({
            shoppingArticleId: id,
            priceWithTax: this.findOne(id)[0].lastPriceWithoutTax == null ? 0 : this.findOne(id)[0].lastPriceWithTax,
            priceWithoutTax: this.findOne(id)[0].lastPriceWithoutTax == null ? 0 : this.findOne(id)[0].lastPriceWithoutTax,
            taxFee: this.findOne(id)[0].lastPriceWithoutTax == null ? 0 : Number((this.findOne(id)[0].lastPriceWithoutTax / (this.findOne(id)[0].lastPriceWithTax / 100)) - 100).toFixed(2)
        });
    }

    deleteOne(id){
        ArticleService.deleteShoppingArticleItem(id,this.state.shoppingId).then(res => {
            window.location.reload();
        });
    };

    findOne(id) {
        return this.state.shoppingArticles.filter(item => {
            return item.id == id
        })
    };

    cancel = () => {
        this.props.history.push('/shopping/');
    }

    check(id) {
        ArticleService.checkShopping(id).then(res2 => {
            this.props.history.push('/shopping/');
        });
    }

    render() {
        if (cookies.get('token') !== 'b5c9667644da67241316db50cc788bfd078849de') {
            return <Redirect to="/login" />;
        }
        const { t } = this.props;
        return (
            <div>
                <br></br>
                <div className="container">
                    <div className="row">
                        <div className="card col-md-3 offset-md-1">
                            <div className="card-body">
                                <label style={{ fontSize: '17px', textDecoration: 'underline' }}> Einkaufartikel hinzufügen: </label>
                                <div>
                                    <label> Einkaufsartikel: (*) </label>
                                    <select className={'custom-select'} onChange={(evt) => this.changeShoppingArticleIdHandler(evt)}>
                                        {this.state.shoppingArticles.map((e, key) => {
                                            return <option key={key} value={e.id}>{e.name}</option>;
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <label> Menge: (*) </label>
                                    <input placeholder="Menge" name="Menge" className="form-control"
                                        value={this.state.amount} onChange={(evt) => this.changeAmountHandler(evt)} />
                                </div>
                                <div>
                                    <label> Netto Einkaufspreis: (*) </label>
                                    <input placeholder="Preis" name="Preis" className="form-control"
                                        value={this.state.priceWithTax} onChange={(evt) => this.changePriceWithTaxHandler(evt)} />
                                </div>
                                <div>
                                    <label> Steuersatz: (*) </label>
                                    <select className={'custom-select'} onChange={(evt) => this.changeTaxFeeHandler(evt)}>
                                        {this.state.taxFees.map((e, key) => {
                                            if (e.value == this.state.taxFee) {
                                                return <option key={key} value={e.value} selected>{e.value} %</option>;
                                            }
                                            return <option key={key} value={e.value} >{e.value} %</option>;
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <label> Brutto Einkaufspreis: (*) </label>
                                    <input placeholder="Preis" disabled name="Bruttopreis" className="form-control"
                                        value={this.state.priceWithoutTax} />
                                </div>
                                <Icon name="save" tooltip="Einkaufartikel hinzufügen" theme="light" size="medium" onClick={this.saveOrUpdate} />
                                <Icon name="check" tooltip="Einkauf bestätigen" theme="light" size="medium" onClick={() => this.check(this.state.shoppingId)} />
                                <Icon name="undo" tooltip="Zurück zu den Einkäufen" theme="light" size="medium" onClick={this.cancel.bind(this)} />
                            </div>
                        </div>
                        <div className="card col-md-7 offset-md-1">
                            <div className="card-body">
                                <span style={{ fontSize: '17px', textDecoration: 'underline' }}>Einkaufsartikel:</span>
                                <br></br>
                                <br></br>
                                {Object.keys(this.state.shoppingArticlesByShopping).map(key =>
                                    <div>
                                        <b>{JSON.stringify(key).split("name=")[1].split(",")[0]}</b>
                                        <br></br>
                                        {this.state.shoppingArticlesByShopping[key].map(value =>
                                            <div>
                                                {value.shoppingArticle.name}, <b>Menge:</b> {value.amount}, <b>Preis:</b> {value.priceWithoutTax}, <b>Gesamt:</b> {value.total} <button onClick={() => this.deleteOne(value.id)}>-</button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AddShoppingArticleItemComponent.propTypes = {
    t: PropTypes.func.isRequired,
};

export default compose(
    withRouter,
)(translate(AddShoppingArticleItemComponent))