import React, { Component } from "react";
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { translate } from "react-switch-lang";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import UserService from "../services/UserService";
const cookies = new Cookies();

class EditUserComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nr: 0,
      name: "",
      station: 0,
      stationId: 0,
      stations: [],
    };
  }

  saveOrUpdate = (e) => {
    e.preventDefault();
    let u = {
      name: this.state.name,
      station: this.state.station,
    };
    console.log("User => " + JSON.stringify(u));
    UserService.update(u, this.props.id).then((res) => {
      this.props.history.push("/user-list/");
    });
  };

  componentDidMount() {
    UserService.getById(this.props.id).then((res) => {
        this.setState({
          name: res.data.name,
      });
    });
  }

  changeNameHandler = (event) => {
    this.setState({ name: event.target.value });
  };

  changeStationHandler = (event) => {
    var id = event.target.value;
    this.setState({
      station: this.state.stations.find(one => one.id == id),
    });
  };

  cancel() {
    this.props.history.push("/user-list/");
  }

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="card col-md-6 offset-md-3 offset-md-3">
              <div className="card-body">
                <div className="form-group">
                  <label> Nummer: (*) </label>
                  <input
                    className="form-control"
                    value={this.state.nr}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label> Name: (*) </label>
                  <input
                    placeholder="Name"
                    name="name"
                    className="form-control"
                    value={this.state.name}
                    onChange={this.changeNameHandler}
                  />
                </div>
                <Icon
                  name="save"
                  tooltip="Speichern"
                  theme="light"
                  size="medium"
                  onClick={this.saveOrUpdate}
                />
                <Icon
                  name="undo"
                  tooltip="Zurück zu den Artikeln"
                  theme="light"
                  size="medium"
                  onClick={this.cancel.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
EditUserComponent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(withRouter)(translate(EditUserComponent));
