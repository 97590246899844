import React, { Component } from 'react';
import Sidenavigation from '../layouts/Sidenavigation';
import Topnavigation from '../layouts/Topnavigation';
import Quickbar from '../layouts/Quickbar';
import EditArticleComponent from '../EditArticleComponent';
import EditUserComponent from '../EditUserComponent';

class EditUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            articleId: this.props.match.params.id
        }
    }

    render() {
        return (
            <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                <Sidenavigation />
                <main className="body-content">
                    <Topnavigation />
                    <EditUserComponent id={this.state.articleId}/>
                </main>
                <Quickbar />
            </div>
        );
    }
}

export default EditUser;