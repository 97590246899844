import React, { Component } from 'react'
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import ArticleService from '../services/ArticleService';
import { Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import PropTypes from 'prop-types';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    translate
} from 'react-switch-lang';
import en from '../languages/en.json';
import de from '../languages/de.json';
import ProducentService from '../services/ProducentService';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

setTranslations({ en, de });
var userLang = navigator.language || navigator.userLanguage;
setDefaultLanguage(userLang);
setLanguageCookie();

class AddShoppingItemComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expandedTicket: true,
            shoppingId: this.props.match.params.id,
            name: '',
            nr: '',
            externNr: '',
            quantity: 0,
            quantityPure: 0,
            taxFee: 0,
            pack: 0,
            priceWithTax: 0,
            priceWithoutTax: 0,
            shoppingArticleUnit: 0,
            amount: 0,
            supplierId: 0,
            taxFees: [],
            packs: [],
            units: [],
            suppliers: [],
            error:''
        }
        this.changeNameHandler = this.changeNameHandler.bind(this);
        this.changeNrHandler = this.changeNrHandler.bind(this);
        this.changeExternNrHandler = this.changeExternNrHandler.bind(this);
        this.changeQuantityHandler = this.changeQuantityHandler.bind(this);
        this.changeQuantityPureHandler = this.changeQuantityPureHandler.bind(this);
        this.changeTaxFeeHandler = this.changeTaxFeeHandler.bind(this);
        this.changePriceWithTaxHandler = this.changePriceWithTaxHandler.bind(this);
        this.changearticleComponentUnitHandler = this.changearticleComponentUnitHandler.bind(this);
        this.changeSupplierHandler = this.changeSupplierHandler.bind(this);
        this.changePackHandler = this.changePackHandler.bind(this);
        this.saveOrUpdate = this.saveOrUpdate.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        ArticleService.getAllTaxFees().then((res2) => {
            ArticleService.getAllUnits().then((res3) => {
                ArticleService.getAllPacks().then((res4) => {
                    ProducentService.getAllSuppliers().then((res) => {
                        this.setState({
                            taxFees: res2.data,
                            units: res3.data,
                            packs: res4.data,
                            shoppingArticleUnit: res3.data[0].unit,
                            pack: res4.data[0].pack,
                            supplierId: res.data[0].id,
                            suppliers: res.data
                        })
                    })
                })
            })
        })
    }

    saveOrUpdate = (e) => {
        e.preventDefault();
        let a = {
            name: this.state.name,
            nr: this.state.nr,
            externNr: this.state.externNr,
            quantity: this.state.quantity,
            quantityPure: this.state.quantityPure,
            pack: this.state.pack,
            shoppingArticleUnit: this.state.shoppingArticleUnit,
        };
        console.log('ShoppingArticle => ' + JSON.stringify(a));
        ArticleService.createShoppingArticle(this.state.supplierId, a).then(res => {
            this.setState({
                error:''
            })
            this.props.history.push('/shopping-articles/');
        }).catch(error => {
            this.setState({
                error:'Einkaufsartikelnummer leer oder doppelt vorhanden!'
            })
        })
    }

    changePriceWithTaxHandler = (event) => {
        var taxfeeNew = this.state.taxFee;
        var priceWithTaxNew = event.target.value;
        if (this.findTaxFee(taxfeeNew)[0] != undefined) {
            this.setState({ priceWithTax: priceWithTaxNew, priceWithoutTax: Number(Number(priceWithTaxNew) + ((priceWithTaxNew * this.findTaxFee(taxfeeNew)[0].value) / 100)).toFixed(2) });
        }
    }

    findTaxFee(taxFee) {
        return this.state.taxFees.filter(item => {
            return item.taxFee == taxFee
        })
    };

    changeTaxFeeHandler = (event) => {
        var taxfeeNew = event.target.value;
        var priceWithTax = this.state.priceWithTax;
        if (this.findTaxFee(taxfeeNew)[0] != undefined) {
            this.setState({ taxFee: taxfeeNew, priceWithoutTax: Number(Number(priceWithTax) + ((priceWithTax * this.findTaxFee(taxfeeNew)[0].value) / 100)).toFixed(2) });
        }
    }

    changearticleComponentUnitHandler = (event) => {
        this.setState({ shoppingArticleUnit: event.target.value });
    }

    changeSupplierHandler = (event) => {
        this.setState({ supplierId: event.target.value });
    }

    changePackHandler = (event) => {
        this.setState({ pack: event.target.value });
    }

    changeNameHandler = (event) => {
        this.setState({ name: event.target.value });
    }

    changeQuantityHandler = (event) => {
        this.setState({ quantity: event.target.value });
    }

    changeQuantityPureHandler = (event) => {
        this.setState({ quantityPure: event.target.value });
    }

    changeNrHandler = (event) => {
        this.setState({ nr: event.target.value });
    }

    changeExternNrHandler = (event) => {
        this.setState({ externNr: event.target.value });
    }

    cancel = () => {
        this.props.history.push('/shopping/');
    }

    toggleExpandedTicket() {
        this.setState({ expandedTicket: !this.state.expandedTicket });
    }

    render() {
        if (cookies.get('token') !== 'b5c9667644da67241316db50cc788bfd078849de') {
            return <Redirect to="/login" />;
        }
        const { t } = this.props;
        return (
            <div>
                <br></br>
                <div className="container">
                    <div className="row">
                        <div className="card col-md-6 offset-md-1 offset-md-1">
                            <div className="card-body">
                                <label style={{ fontSize: '17px', textDecoration: 'underline' }}> Einkaufartikeldaten: </label>
                                <div className="form-group">
                                    <label> Name: </label>
                                    <input placeholder="Name" name="Name" className="form-control"
                                        value={this.state.name} onChange={this.changeNameHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Lieferant: </label>
                                    <select className={'custom-select'} onChange={(evt) => this.changeSupplierHandler(evt)}>
                                        {this.state.suppliers.map((e, key) => {
                                            return <option key={key} value={e.id} >{e.name}</option>;
                                        })}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label> Inhalt (Roh): </label>
                                    <input placeholder="Menge (Roh)" name="Name" className="form-control"
                                        value={this.state.quantity} onChange={this.changeQuantityHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Inhalt (Rein): </label>
                                    <input placeholder="Menge (Rein)" name="Name" className="form-control"
                                        value={this.state.quantityPure} onChange={this.changeQuantityPureHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Einheit: </label>
                                    <select className={'custom-select'} onChange={(evt) => this.changearticleComponentUnitHandler(evt)}>
                                        {this.state.units.map((e, key) => {
                                            return <option key={key} value={e.unit} >{e.name}</option>;
                                        })}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label> Verpackungsform: </label>
                                    <select className={'custom-select'} onChange={(evt) => this.changePackHandler(evt)}>
                                        {this.state.packs.map((e, key) => {
                                            return <option key={key} value={e.pack} >{t(e.name.toLowerCase())}</option>;
                                        })}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label> Artikelnummer: </label>
                                    <input placeholder="Nummer" name="Nummer" className="form-control"
                                        value={this.state.nr} onChange={this.changeNrHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Lieferantennummer: </label>
                                    <input placeholder="Nummer Extern" name="Name" className="form-control"
                                        value={this.state.externNr} onChange={this.changeExternNrHandler} />
                                </div>
                                {this.state.error.length>1 && <p style={{color:'red'}}>{this.state.error}</p>}
                                <Icon name="save" tooltip="Speichern" theme="light" size="medium" onClick={this.saveOrUpdate} />
                                <Icon name="undo" tooltip="Zurück zu den Einkäufen" theme="light" size="medium" onClick={this.cancel.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AddShoppingItemComponent.propTypes = {
    t: PropTypes.func.isRequired,
};

export default compose(
    withRouter,
)(translate(AddShoppingItemComponent))