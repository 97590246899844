import React, { Component } from "react";
import Sidenavigation from "../layouts/Sidenavigation";
import Topnavigation from "../layouts/Topnavigation";
import Breadcrumb from "../sections/Orders/Breadcrumb";
import Quickbar from "../layouts/Quickbar";
import { Link, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import ListTimesComponent from "../ListTimesComponent";
const cookies = new Cookies();

class Times extends Component {
  render() {
    if (cookies.get("token") === "b5c9667644da67241316db50cc788bfd078849de") {
      return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
          <Sidenavigation />
          <main className="body-content">
            <Topnavigation />
            <div className="ms-content-wrapper">
              <div className="row">
                <div className="col-md-12">
                  <Breadcrumb />
                  <ListTimesComponent />
                </div>
              </div>
            </div>
          </main>
          <Quickbar />
        </div>
      );
    } else {
      return <Redirect to="/login" />;
    }
  }
}

export default Times;
