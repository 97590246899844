import React, { Component } from "react";
import Icon from "react-crud-icons";
import "react-crud-icons/dist/css/react-crud-icons.css";
import Countries from "react-select-country";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import FileBase64 from "react-file-base64";
import UserService from "../services/UserService";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { translate } from "react-switch-lang";

const cookies = new Cookies();

class EditWorkerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      name: "",
      surname: "",
      street: "",
      number: "",
      postalCode: "",
      city: "",
      country: "",
      telefonNumber: "",
      telefonNumber2: "",
      taxNumber: "",
      retirementNumber: "",
      insuranceNumber: "",
      iban: "",
      available: false,
      files: [],
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.changeCountryHandler = this.changeCountryHandler.bind(this);
    this.saveOrUpdate = this.saveOrUpdate.bind(this);
  }

  componentDidMount() {
    UserService.getWorkerById(this.state.id).then((res) => {
      let worker = res.data;
      this.setState({
        name: worker.name,
        surname: worker.surname,
        street: worker.street,
        number: worker.number,
        postalCode: worker.postalCode,
        city: worker.city,
        country: worker.country,
        telefonNumber: worker.telefonNumber,
        telefonNumber2: worker.telefonNumber2 || "",
        taxNumber: worker.taxNumber,
        retirementNumber: worker.retirementNumber,
        insuranceNumber: worker.insuranceNumber,
        iban: worker.iban,
        available: worker.available,
        identificationPicture: worker.identificationPicture,
      });
    });
  }

  saveOrUpdate = (e) => {
    e.preventDefault();
    let worker = {
      id: this.state.id,
      name: this.state.name,
      surname: this.state.surname,
      street: this.state.street,
      number: this.state.number,
      postalCode: this.state.postalCode,
      city: this.state.city,
      country: this.state.country,
      telefonNumber: this.state.telefonNumber,
      telefonNumber2: this.state.telefonNumber2,
      taxNumber: this.state.taxNumber,
      retirementNumber: this.state.retirementNumber,
      insuranceNumber: this.state.insuranceNumber,
      iban: this.state.iban,
      identificationPicture: this.state.files[0].base64,
    };

    UserService.updateWorker(worker).then((res) => {
      this.props.history.push("/workers/");
    });
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeCountryHandler = (event) => {
    this.setState({
      country: event.target.options[event.target.selectedIndex].text,
    });
  };

  getFiles(files) {
    this.setState({ files: files });
  }

  cancel = () => {
    this.props.history.push("/workers/");
  };

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }

    return (
      <div className="container">
        <br />
        <div className="row">
          <div className="card col-md-6 offset-md-3">
            <div className="card-body">
              <div className="form-group">
                <label> Name: </label>
                <input
                  placeholder="Name"
                  name="name"
                  className="form-control"
                  value={this.state.name}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="form-group">
                <label> Nachname: </label>
                <input
                  placeholder="Nachname"
                  name="surname"
                  className="form-control"
                  value={this.state.surname}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="form-group">
                <label> Telefonnummer: </label>
                <input
                  placeholder="Telefonnummer"
                  name="telefonNumber"
                  className="form-control"
                  value={this.state.telefonNumber}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="form-group">
                <label> Telefonnummer 2: </label>
                <input
                  placeholder="Telefonnummer 2"
                  name="telefonNumber2"
                  className="form-control"
                  value={this.state.telefonNumber2}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="form-group">
                <label> Straße: </label>
                <input
                  placeholder="Straße"
                  name="street"
                  className="form-control"
                  value={this.state.street}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="form-group">
                <label> Hausnummer: </label>
                <input
                  placeholder="Hausnummer"
                  name="number"
                  className="form-control"
                  value={this.state.number}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="form-group">
                <label> Postleitzahl: </label>
                <input
                  placeholder="Postleitzahl"
                  name="postalCode"
                  className="form-control"
                  value={this.state.postalCode}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="form-group">
                <label> Stadt: </label>
                <input
                  placeholder="Stadt"
                  name="city"
                  className="form-control"
                  value={this.state.city}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="form-group">
                <label> Land: </label>
                <Countries
                  className="form-control"
                  ref="cList"
                  name="country"
                  empty=" -- Land auswählen --"
                  onChange={this.changeCountryHandler}
                />
              </div>

              <div className="form-group">
                <label> Steuernummer: </label>
                <input
                  placeholder="Steuernummer"
                  name="taxNumber"
                  className="form-control"
                  value={this.state.taxNumber}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="form-group">
                <label> RV-Nummer: </label>
                <input
                  placeholder="RV-Nummer"
                  name="retirementNumber"
                  className="form-control"
                  value={this.state.retirementNumber}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="form-group">
                <label> KV-Nummer: </label>
                <input
                  placeholder="KV-Nummer"
                  name="insuranceNumber"
                  className="form-control"
                  value={this.state.insuranceNumber}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="form-group">
                <label> IBAN: </label>
                <input
                  placeholder="IBAN"
                  name="iban"
                  className="form-control"
                  value={this.state.iban}
                  onChange={this.changeHandler}
                />
              </div>
              <div className="form-group">
                <label> Ausweisfoto hochladen </label>
                <div className="form-group">
                  <FileBase64
                    multiple={true}
                    onDone={this.getFiles.bind(this)}
                  />
                </div>
                <div className="text-center">
                  {this.state.files.length <= 2 &&
                    this.state.files.map((file, i) => {
                      return (
                        <div style={{ marginBottom: "10px" }}>
                          <img
                            width={"100px"}
                            height={"100px"}
                            alt={file.name}
                            key={i}
                            src={file.base64}
                          />
                        </div>
                      );
                    })}
                  {this.state.files.length <= 0 && (
                    <img
                      alt={this.state.id}
                      src={this.state.identificationPicture}
                    />
                  )}
                </div>
              </div>
              <Icon
                name="save"
                tooltip="Speichern"
                theme="light"
                size="medium"
                onClick={this.saveOrUpdate}
              />
              <Icon
                name="undo"
                tooltip="Zurück zur Mitarbeiterliste"
                theme="light"
                size="medium"
                onClick={this.cancel}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withRouter)(translate(EditWorkerComponent));
