import React, { Component } from 'react'
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import { Redirect } from "react-router-dom";
import OrderService from '../services/OrderService';
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import {
    translate,
} from 'react-switch-lang';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class AddCarComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            model: '',
            km: '',
            defects: [],
            types: [],
            type: '',
            average: 0
        }
        this.changeNameHandler = this.changeNameHandler.bind(this);
        this.changeModelHandler = this.changeModelHandler.bind(this);
        this.changeKmHandler = this.changeKmHandler.bind(this);
        this.changeAverageHandler = this.changeAverageHandler.bind(this);
        this.saveOrUpdate = this.saveOrUpdate.bind(this);
    }

    saveOrUpdate = (e) => {
        var defects = [];
        this.state.defects.forEach(element => {
            if (element.length > 1) {
                defects.push({ 'name': element });
            }
        })
        e.preventDefault();
        let c = {
            name: this.state.name,
            model: this.state.model,
            km: this.state.km,
            type: this.state.type,
            average: this.state.average,
            defects: defects
        };
        console.log('Car => ' + JSON.stringify(c));
        OrderService.createCar(c).then(res => {
            this.props.history.push('/driver-list/');
        });
    }

    changeNameHandler = (event) => {
        this.setState({ name: event.target.value });
    }

    changeModelHandler = (event) => {
        this.setState({ model: event.target.value });
    }

    changeKmHandler = (event) => {
        this.setState({ km: event.target.value });
    }

    changeTypeHandler = (event) => {
        this.setState({ type: event.target.value });
    }

    changeAverageHandler = (event) => {
        this.setState({ average: event.target.value });
    }

    cancel() {
        this.props.history.push('/driver-list/');
    }

    changeDefect(i, event) {
        let defects = [...this.state.defects];
        defects[i] = event.target.value;
        this.setState({ defects });
    }

    addDefect() {
        this.setState(prevState => ({ defects: [...prevState.defects, ''] }))
    }

    removeDefect(i) {
        let defects = [...this.state.defects];
        defects.splice(i, 1);
        this.setState({ defects });
    }

    componentDidMount() {
        OrderService.getCarTypes().then((res) => {
            this.setState({ types: res.data, type: res.data[0] });
        });
    }

    render() {
        if (cookies.get('token') !== 'b5c9667644da67241316db50cc788bfd078849de') {
            return <Redirect to="/login" />;
        }
        return (
            <div>
                <br></br>
                <div className="container">
                    <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3">
                            <div className="card-body">
                                <div className="form-group  has-success">
                                    <label for="inputSuccess"> Name (*): </label>
                                    <input placeholder="Name" id="inputSuccess" className="form-control"
                                        value={this.state.name} onChange={this.changeNameHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Modell: </label>
                                    <input placeholder="Modell" className="form-control"
                                        value={this.state.model} onChange={this.changeModelHandler} />
                                </div>
                                <div className="form-group">
                                    <label> KM Stand: </label>
                                    <input placeholder="KM Stand" className="form-control"
                                        value={this.state.km} onChange={this.changeKmHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Typ: </label>
                                    <select className={'custom-select'} value={this.state.type} onChange={(evt) => this.changeTypeHandler(evt)}>
                                        {this.state.types.map((e, key) => {
                                            return <option key={key} value={e}>{e}</option>;
                                        })}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label> Durchschnittsverbrauch: (pro 100 km) </label>
                                    <input placeholder="Postleitzahl" className="form-control"
                                        value={this.state.average} onChange={this.changeAverageHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Mängel: </label>
                                    <br></br>
                                    {this.state.defects.length<5 && this.state.defects.map((el, i) =>
                                        <div key={i}>
                                            <ul>
                                                <li><input style={{width:'545px'}} className="form-control" type="text" value={el || ''} onChange={(event) => this.changeDefect(i, event)} /></li>
                                                <li><Icon name="remove" tooltip="Löschen" theme="light" size="medium" onClick={() => this.removeDefect(i)} /></li>
                                            </ul>
                                        </div>
                                    )}
                                    <Icon name="add" tooltip="Hinzufügen" theme="light" size="medium" onClick={this.addDefect.bind(this)} />
                                </div>
                                <Icon name="save" tooltip="Speichern" theme="light" size="medium" onClick={this.saveOrUpdate} />
                                <Icon name="undo" tooltip="Zurück zur Fahrerdatenbank" theme="light" size="medium" onClick={this.cancel.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default compose(
    withRouter,
  )(translate(AddCarComponent))