import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BillService from '../../../services/BillService';
import Breadcrumb from './Breadcrumb'
import Moment from 'moment';

class Detailcontent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            bill: null
        }
        Moment.locale('de');
    }

    componentDidMount() {
        BillService.getById(this.props.id).then((res) => {
            this.setState({ bill: res.data});
        });
    }

    render() {
        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb/>
                        <div className="ms-panel">
                            <div className="ms-panel-header header-mini">
                                <div className="d-flex justify-content-between">
                                    <h6>Rechnungsnummer: {this.state.bill && this.state.bill.id}</h6>
                                </div>
                            </div>
                            <div className="ms-panel-body">
                                {/* Invoice To */}
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <div className="invoice-address">
                                            <p className="mb-0"><b>Kunde:</b></p>
                                            <p className="mb-0">{this.state.bill && this.state.bill.item.client.name}</p>
                                            <p className="mb-0">{this.state.bill && this.state.bill.item.client.email}</p>
                                            <p className="mb-0">{this.state.bill && this.state.bill.item.client.street} {this.state.bill && this.state.bill.item.client.postalCode} {this.state.bill && this.state.bill.item.client.city}</p>
                                            <p className="mb-0">{this.state.bill && this.state.bill.item.client.country}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-md-right">
                                        <ul className="invoice-date">
                                            <li>Bestellungsdatum : {this.state.bill && Moment(this.state.bill.item.created).format('D/M/YYYY HH:mm')}</li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Invoice Table */}
                                <div className="ms-invoice-table table-responsive mt-5">
                                    <table className="table table-hover text-right thead-light">
                                        <thead>
                                            <tr className="text-capitalize">
                                                <th className="text-center w-5">Artikelnummer</th>
                                                <th className="text-left">Artikelname</th>
                                                <th>Menge</th>
                                                <th>Preis</th>
                                                <th>Summe</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.bill && this.state.bill.item.positions.map((item,i) =>
                                            <tr>
                                                <td className="text-center">{item.article.nr}</td>
                                                <td className="text-left">{item.article.name}</td>
                                                <td>{item.quantity}</td>
                                                <td>{Number(item.price).toFixed(2)}</td>
                                                <td>{Number(item.total).toFixed(2)}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={4}>Gesamt:</td>
                                                <td>{this.state.bill && Number(this.state.bill.item.positions.reduce((a, v) => a = a + v.total, 0)).toFixed(2)}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div className="invoice-buttons text-right"> <Link to="#" className="btn btn-primary mr-2">Rechnung ausdrucken</Link>
                                    <Link to="#" className="btn btn-primary">Rechnung senden</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Detailcontent;