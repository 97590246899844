import React, { Component } from "react";
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import ArticleService from "../services/ArticleService";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import Cookies from "universal-cookie";
import OrderService from "../services/OrderService";
const cookies = new Cookies();

class AddDocumentComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      body: "",
      footer: "",
      isSaving: false,
      footers: [],
      selectedFooters: [],
      templates: [],
      selectedTemplates: [],
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSave = async (e) => {
    e.preventDefault();
    let c = {
      contractTerm: this.state.contractTerm,
      title: this.state.title,
    };
    console.log("Document => " + JSON.stringify(c));
    OrderService.createDocument(
      this.state.selectedFooters.length>0?this.state.selectedFooters:0,
      this.state.selectedTemplates.length>0?this.state.selectedTemplates:0,
      c
    ).then((res) => {
      this.props.history.push("/documents");
    });
  };

  componentDidMount() {
    OrderService.getFooters().then((res3) => {
      OrderService.getTemplates().then((res4) => {
        this.setState({
          footers: res3.data,
          templates: res4.data,
        });
      });
    });
  }

  cancel = () => {
    this.props.history.push("/templates/");
  };

  changeFooterHandler = (event) => {
    const selectedOptions = event.target.selectedOptions;
    const selectedFooters = Array.from(selectedOptions).map(
      (option) => option.value
    );
    this.setState({
      selectedFooters: selectedFooters,
    });
  };

  changeTemplateHandler = (event) => {
    const selectedOptions = event.target.selectedOptions;
    const selectedTemplates = Array.from(selectedOptions).map(
      (option) => option.value
    );
    this.setState({
      selectedTemplates: selectedTemplates,
    });
  };

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }

    const { title, body, footer, isSaving } = this.state;

    return (
      <div>
        <br />
        <div className="container">
          <div className="row">
            <div className="card col-md-6 offset-md-3 offset-md-3">
              <div className="card-body">
                <div className="document-container">
                  <div className="document-header">
                    <label>Betreff: </label>
                    <input
                      type="text"
                      name="title"
                      value={title}
                      style={{width:"400px"}}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group has-success">
                    <label>Textvorlagen für den Inhalt</label>
                    <select
                      multiple
                      placeholder="Textvorlagen"
                      name="billing"
                      className="form-control"
                      value={this.state.selectedTemplates}
                      onChange={this.changeTemplateHandler}
                    >
                      {this.state.templates.map((e, key) => {
                        return (
                          <option key={key} value={e.id} title={e.name}>
                            {e.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group has-success">
                    <label>Fussnoten</label>
                    <select
                      multiple
                      placeholder="Fussnoten"
                      name="billing"
                      className="form-control"
                      value={this.state.selectedFooters}
                      onChange={this.changeFooterHandler}
                    >
                      {this.state.footers.map((e, key) => {
                        return (
                          <option key={key} value={e.id} title={e.name}>
                            {e.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <button className="btn btn-success btn-sm" onClick={this.handleSave} disabled={isSaving}>
                  {isSaving ? "Dokument wird gespeichert..." : "Dokument generieren"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withRouter)(AddDocumentComponent);
