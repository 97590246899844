import React, { Component } from "react";
import ClientService from "../services/ClientService";
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Moment from "moment";
import "moment/locale/de";
import compose from "recompose/compose";
import { translate } from "react-switch-lang";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import ArticleService from "../services/ArticleService";
import DatePicker from "react-datepicker";
import { ThemeProvider } from "@emotion/react";
import OrderService from "../services/OrderService";
const cookies = new Cookies();

class EditOrderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: null,
      isDivOpen: false,
      timeFrom: new Date(),
      timeTo: new Date(),
      clients: [],
      successMessage: "",
      data: [],
      id: this.props.match.params.id,
      search: "",
      searchClient: "",
      description: "",
      filterValue: "",
      cId: 0,
      offerId: 0,
      loading: true,
      taxFees: [],
      offers: [],
      limits: [],
      shoppingArticles: [],
      limit: 0,
      taxFee: 0,
      priceWithTax: 0,
      priceWithoutTax: 0,
      taxFeeArticle: 0,
      priceWithTaxArticle: 0,
      priceWithoutTaxArticle: 0,
      yes: false,
      addressId: 0,
      articles: [],
      onlyArticles: false,
      onlyBill: false,
      onlyAll: false,
    };
    this.onDropdownSelectedClient = this.onDropdownSelectedClient.bind(this);
    this.onChangeValueAddress = this.onChangeValueAddress.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    ArticleService.getAllTaxFees().then((res2) => {
      ArticleService.getAllLimits().then((res3) => {
        OrderService.getOffers().then((res4) => {
          ArticleService.getAllArticleAmounts().then((res5) => {
            const orderId = this.state.id;
            OrderService.getById(orderId).then((res) => {
              const orderData = res.data;
              this.setState({
                shoppingArticles: res5.data.filter(
                  (one) => one.service === false
                ),
                timeFrom: orderData.timeFrom,
                timeTo: orderData.timeTo,
                priceWithTax: orderData.priceWithTax,
                taxFee: orderData.fee,
                priceWithoutTax: orderData.priceWithoutTax,
                limit: orderData.paymentLimit,
                client: orderData.client,
                description: orderData.description,
                addressId: orderData.address.id,
                offers: res4.data,
                taxFees: res2.data,
                limits: res3.data,
                articles: orderData.articles
                  ? orderData.articles.filter(
                      (one) => one.shoppingArticle.service === false
                    )
                  : [],
                name: res5.data.filter((one) => one.service === false)[0].name,
                onlyAll: orderData.onlyAll,
                onlyBill: orderData.onlyBill,
                onlyArticles: orderData.onlyArticles,
              });
            });
          });
        });
      });
    });
  }

  save() {
    var editedOrder = {
      timeFrom: this.state.timeFrom,
      timeTo: this.state.timeTo,
      priceWithTax: this.state.priceWithTax,
      fee: this.state.taxFee,
      priceWithoutTax: this.state.priceWithoutTax,
      paymentLimit: this.state.limit,
      client: this.state.client,
      description: this.state.description,
      id: this.props.match.params.id,
      articles: this.state.articles,
      onlyAll: this.state.onlyAll,
      onlyBill: this.state.onlyBill,
      onlyArticles: this.state.onlyArticles,
    };
    // Call the service method for updating the order
    ArticleService.saveBill(
      editedOrder,
      this.state.addressId,
      this.state.offerId
    ).then((res) => {
      this.props.history.push("/");
    });
  }

  onDropdownSelected(e) {
    var self = this;
    ClientService.getClientsByPId(e.target.value).then((res) => {
      let p = res.data;
      self.setState({
        clients: p,
        cId: p[0].id,
      });
    });
  }

  onDropdownSelectedClient = (event) => {
    let one = event.target.value;
    ClientService.getById(one).then((res) => {
      let c = res.data;
      this.setState({
        client: c,
      });
    });
  };

  onDropdownSelectedOffer = (event) => {
    let one = event.target.value;
    this.setState({
      offerId: one,
      priceWithTax: this.state.offers.filter((o) => o.id == one)[0]
        .priceWithTax,
    });
  };

  toggleDivVisibility() {
    // Toggle the visibility of the div based on the checkbox state
    this.setState((prevState) => ({
      isDivOpen: !prevState.isDivOpen,
      offerId: this.state.isDivOpen === false ? 0 : this.state.offerId,
    }));
  }

  cancel() {
    this.props.history.push("/");
  }

  setSearch(search) {
    if (search.length > 1) {
      ClientService.getBySearch(search).then((res) => {
        this.setState({
          searchClient: search,
          clients: res.data,
          client: res.data[0],
        });
      });
    }
  }

  changeAmountHandler = (event) => {
    var taxfeeNew = this.state.taxFee;
    var priceWithTaxNew = event;
    if (this.findTaxFee(taxfeeNew)[0] !== undefined) {
      this.setState({
        priceWithTax: priceWithTaxNew,
        priceWithoutTax: Number(
          Number(priceWithTaxNew) +
            (priceWithTaxNew * this.findTaxFee(taxfeeNew)[0].value) / 100
        ).toFixed(2),
      });
    } else {
      this.setState({
        priceWithTax: priceWithTaxNew,
      });
    }
  };

  changeAmountHandler = (event) => {
    this.setState({
      amount: event,
    });
  };

  changeAmountArticleHandler = (event) => {
    var taxfeeNew = this.state.taxFeeArticle;
    var priceWithTaxNew = event;
    if (this.findTaxFee(taxfeeNew)[0] !== undefined) {
      this.setState({
        priceWithTaxArticle: priceWithTaxNew,
        priceWithoutTaxArticle: Number(
          Number(priceWithTaxNew) +
            (priceWithTaxNew * this.findTaxFee(taxfeeNew)[0].value) / 100
        ).toFixed(2),
      });
    } else {
      this.setState({
        priceWithTaxArticle: priceWithTaxNew,
      });
    }
  };

  changePriceHandler = (event) => {
    var taxfeeNew = this.state.taxFee;
    var priceWithTaxNew = event;
    if (this.findTaxFee(taxfeeNew)[0] !== undefined) {
      this.setState({
        priceWithTax: priceWithTaxNew,
        priceWithoutTax: Number(
          Number(priceWithTaxNew) +
            (priceWithTaxNew * this.findTaxFee(taxfeeNew)[0].value) / 100
        ).toFixed(2),
      });
    } else {
      this.setState({
        priceWithTax: priceWithTaxNew,
      });
    }
  };

  changeTaxFeeHandler = (event) => {
    var taxfeeNew = event.target.value;
    var priceWithTax = this.state.priceWithTax;
    if (this.findTaxFee(taxfeeNew)[0] !== undefined) {
      this.setState({
        taxFee: taxfeeNew,
        priceWithoutTax: Number(
          Number(priceWithTax) +
            (priceWithTax * this.findTaxFee(taxfeeNew)[0].value) / 100
        ).toFixed(2),
      });
    }
  };

  changeTaxFeeArticleHandler = (event) => {
    var taxfeeNew = event;
    var priceWithTax = this.state.priceWithTaxArticle;
    if (this.findTaxFee(taxfeeNew)[0] !== undefined) {
      this.setState({
        taxFeeArticle: taxfeeNew,
        priceWithoutTaxArticle: Number(
          Number(priceWithTax) +
            (priceWithTax * this.findTaxFee(taxfeeNew)[0].value) / 100
        ).toFixed(2),
      });
    }
  };

  deleteOne(id) {
    const newList = this.state.articles.filter((item) => item.id !== id);
    this.setState({
      articles: newList,
    });
  }

  changeTaxFeeHandler = (event) => {
    var taxfeeNew = event.target.value;
    var priceWithTax = this.state.priceWithTax;
    if (this.findTaxFee(taxfeeNew)[0] !== undefined) {
      this.setState({
        taxFee: taxfeeNew,
        priceWithoutTax: Number(
          Number(priceWithTax) +
            (priceWithTax * this.findTaxFee(taxfeeNew)[0].value) / 100
        ).toFixed(2),
      });
    }
  };

  changeLimitHandler = (event) => {
    var paymentLimitNew = event.target.value;
    this.setState({
      limit: paymentLimitNew,
    });
  };

  changeDescriptionHandler = (event) => {
    var paymentLimitNew = event.target.value;
    this.setState({
      description: paymentLimitNew,
    });
  };

  findTaxFee(taxFee) {
    return this.state.taxFees.filter((item) => {
      return item.taxFee === taxFee;
    });
  }

  onChangeValueAddress = (event) => {
    var self = this;
    var value = event.target.value;
    self.setState({
      addressId: value,
    });
  };

  changeTimeFromDateHandler = (value) => {
    this.setState({ timeFrom: value });
  };

  changeTimeToDateHandler = (value) => {
    this.setState({ timeTo: value });
  };

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      onlyArticles: !prevState.onlyArticles,
      onlyAll: !prevState.onlyArticles === true ? false : this.state.onlyAll,
      onlyBill: !prevState.onlyArticles === true ? false : this.state.onlyBill,
    }));
  };

  handleCheckboxChange2 = () => {
    this.setState((prevState) => ({
      onlyBill: !prevState.onlyBill,
      onlyAll: !prevState.onlyBill === true ? false : this.state.onlyAll,
      onlyArticles:
        !prevState.onlyBill === true ? false : this.state.onlyArticles,
    }));
  };

  handleCheckboxChange3 = () => {
    this.setState((prevState) => ({
      onlyAll: !prevState.onlyAll,
      onlyBill: !prevState.onlyAll === true ? false : this.state.onlyBill,
      onlyArticles:
        !prevState.onlyAll === true ? false : this.state.onlyArticles,
    }));
  };

  saveOrUpdate = (e) => {
    e.preventDefault();
    var idNew = this.state.id;
    this.setState({ id: idNew + 1 });
    let s = {
      name: this.state.name,
    };
    let a = {
      shoppingArticle: s,
      id: idNew + 1,
      amount: this.state.amount,
      priceWithTax: this.state.priceWithTaxArticle,
      priceWithoutTax: this.state.priceWithoutTaxArticle,
      taxFee: this.state.taxFeeArticle,
      total: Number(
        this.state.amount * this.state.priceWithoutTaxArticle
      ).toFixed(2),
    };
    console.log("ShoppingArticleItem => " + JSON.stringify(a));
    var articles = this.state.articles;
    articles.push(a);
    this.setState({ articles: articles });
  };

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <div className="row">
          <div style={{ paddingLeft: "30px" }} className="col">
            <Icon
              name="undo"
              tooltip="Zurück zu den Rechnungen"
              theme="light"
              size="medium"
              onClick={this.cancel.bind(this)}
            />
            <div style={{ width: "300px" }}>
              <label>
                <input
                  type="checkbox"
                  checked={this.state.isDivOpen}
                  onChange={() => this.toggleDivVisibility()}
                />
                Angebot verknüpfen
              </label>
              {this.state.isDivOpen && (
                <div>
                  <p
                    style={{
                      textDecoration: "underline",
                      textTransform: "uppercase",
                      marginRight: "10px",
                      marginTop: "40px",
                    }}
                  >
                    {" "}
                    Angebot verknüpfen{" "}
                  </p>
                  <div>
                    <select
                      className={"custom-select"}
                      style={{ width: "400px" }}
                      name="offer"
                      defaultValue={this.state.offerId}
                      onChange={(evt) => this.onDropdownSelectedOffer(evt)}
                    >
                      <option key="0" value="0">
                        Angebot auswählen
                      </option>
                      {this.state.offers.map((e, key) => {
                        return (
                          <option key={key} value={e.id}>
                            {e.number}{" "}
                            {Moment(e.created).format("D/M/YYYY HH:mm")}{" "}
                            {e.client && e.client.name}{" "}
                            {e.client && e.client.surname}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              <p
                style={{
                  textDecoration: "underline",
                  textTransform: "uppercase",
                  marginRight: "10px",
                  marginTop: "40px",
                }}
              >
                {" "}
                Kunde{" "}
              </p>
              <div>
                <input
                  type="text"
                  style={{ width: "300px" }}
                  className="form-control"
                  placeholder="Name, Straße, Telefonnummer"
                  onChange={(e) => this.setSearch(e.target.value)}
                ></input>
              </div>
              {this.state.searchClient.length > 1 &&
                this.state.clients.length >= 1 && (
                  <div style={{ marginTop: "15px" }}>
                    <select
                      className={"custom-select"}
                      style={{ width: "300px" }}
                      name="kunde"
                      defaultValue={this.state.cId}
                      onChange={(evt) => this.onDropdownSelectedClient(evt)}
                    >
                      {this.state.clients.map((e, key) => {
                        return (
                          <option key={key} value={e.id}>
                            {e.name} {e.surname}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
              <div style={{ marginTop: "15px" }}>
                {this.state.client && this.state.client != null && (
                  <div style={{ width: "300px" }}>
                    {this.state.client.name}
                    <br></br>
                    {this.state.client.street} {this.state.client.number}
                    <br></br>
                    {this.state.client.postalCode} {this.state.client.city}
                  </div>
                )}
              </div>
              <br></br>
              <div onChange={(evt) => this.onChangeValueAddress(evt)}>
                <input type="radio" value="0" name="address" /> Adresse
                übernehmen
                <br></br>
                <br></br>
                {this.state.client &&
                  this.state.client.addresses &&
                  this.state.client &&
                  this.state.client.addresses.length > 0 && (
                    <div>
                      {this.state.client.addresses.map((one) => (
                        <div>
                          <input type="radio" name="address" value={one.id} />
                          {one.street} {one.number}
                          <br></br>
                          {one.postalCode} {one.city}
                          <br></br>
                          <br></br>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div
            style={{ paddingLeft: "0px", paddingTop: "80px" }}
            className="col"
          >
            <div>
              <p
                style={{
                  textDecoration: "underline",
                  textTransform: "uppercase",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              >
                {" "}
                Leistungszeitraum{" "}
              </p>
              Von <br></br>
              <DatePicker
                className="form-control"
                dateFormat="dd/MM/yyyy"
                locale="de"
                value={Moment(this.state.timeFrom).format("D/M/YYYY")}
                onChange={(date) => this.changeTimeFromDateHandler(date)}
              />
              <br></br>
              <br></br>
              Bis <br></br>
              <DatePicker
                className="form-control"
                dateFormat="dd/MM/yyyy"
                locale="de"
                value={Moment(this.state.timeTo).format("D/M/YYYY")}
                onChange={(date) => this.changeTimeToDateHandler(date)}
              />
              <br></br>
              <br></br>
              <br></br>
              <div>
                <p
                  style={{
                    textDecoration: "underline",
                    textTransform: "uppercase",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                >
                  {" "}
                  Zahlungsfrist{" "}
                </p>
                <select
                  className={"custom-select"}
                  style={{ width: "300px" }}
                  onChange={(evt) => this.changeLimitHandler(evt)}
                >
                  {this.state.limits.map((e, key) => {
                    if (e.limit === this.state.limit) {
                      return (
                        <option key={key} value={e.limit} selected>
                          {e.value} Tage
                        </option>
                      );
                    }
                    return (
                      <option key={key} value={e.limit}>
                        {e.value} Tage
                      </option>
                    );
                  })}
                </select>
              </div>
              <p
                style={{
                  textDecoration: "underline",
                  textTransform: "uppercase",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              >
                {" "}
                Beschreibung{" "}
              </p>
              <textarea
                style={{ width: "300px" }}
                className="form-control"
                value={this.state.description}
                placeholder="Beschreibung"
                onChange={(e) => this.changeDescriptionHandler(e)}
              ></textarea>
            </div>
          </div>
          <div
            style={{ paddingLeft: "0px", paddingTop: "80px" }}
            className="col"
          >
            <div>
              <p
                style={{
                  textDecoration: "underline",
                  textTransform: "uppercase",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              >
                {" "}
                Steuersatz{" "}
              </p>
              <select
                className={"custom-select"}
                style={{ width: "300px" }}
                onChange={(evt) => this.changeTaxFeeHandler(evt)}
              >
                {this.state.taxFees.map((e, key) => {
                  if (e.taxFee === this.state.taxFee) {
                    return (
                      <option key={key} value={e.taxFee} selected>
                        {e.value} %
                      </option>
                    );
                  }
                  return (
                    <option key={key} value={e.taxFee}>
                      {e.value} %
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <p
                style={{
                  textDecoration: "underline",
                  textTransform: "uppercase",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              >
                {" "}
                Nettobetrag{" "}
              </p>
              <input
                type="text"
                style={{ width: "300px" }}
                className="form-control"
                value={this.state.priceWithTax}
                placeholder="Betrag"
                onChange={(e) => this.changePriceHandler(e.target.value)}
              ></input>
              <div>
                {this.state.priceWithoutTax > 0 && (
                  <p
                    style={{
                      textDecoration: "underline",
                      textTransform: "uppercase",
                      marginRight: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    Bruttobetrag{" "}
                  </p>
                )}
                {this.state.priceWithoutTax > 0 && (
                  <span>
                    {this.state.priceWithoutTax} {" Euro"}
                  </span>
                )}
                <br></br>
                <br></br>
                <br></br>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="onlyArticles"
                      checked={this.state.onlyArticles}
                      onChange={this.handleCheckboxChange}
                    />
                    Nur Artikel abrechnen
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="onlyBill"
                      checked={this.state.onlyBill}
                      onChange={this.handleCheckboxChange2}
                    />
                    Nur Leistung abrechnen
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="onlyAll"
                      checked={this.state.onlyAll}
                      onChange={this.handleCheckboxChange3}
                    />
                    Alles abrechnen
                  </label>
                </div>
                {this.state.onlyBill !== true && (
                  <div>
                    <p
                      style={{
                        textDecoration: "underline",
                        textTransform: "uppercase",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                    >
                      {" "}
                      Verkaufsartikel hinzufügen{" "}
                    </p>
                    <div>
                      <label> Verkaufsartikel: (*) </label>
                      <br></br>
                      <select
                        className={"custom-select"}
                        onChange={(evt) =>
                          this.changeShoppingArticleIdHandler(evt)
                        }
                        style={{ width: "300px" }}
                      >
                        {this.state.shoppingArticles.map((e, key) => {
                          return (
                            <option key={key} value={e.id}>
                              {e.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <label> Menge: (*) </label>
                      <input
                        placeholder="Menge"
                        name="Menge"
                        className="form-control"
                        style={{ width: "300px" }}
                        value={this.state.amount}
                        onChange={(evt) =>
                          this.changeAmountHandler(evt.target.value)
                        }
                      />
                    </div>
                    <div>
                      <label> Netto Einkaufspreis: (*) </label>
                      <input
                        placeholder="Preis"
                        name="Preis2"
                        className="form-control"
                        style={{ width: "300px" }}
                        value={this.state.priceWithTaxArticle}
                        onChange={(evt) =>
                          this.changeAmountArticleHandler(evt.target.value)
                        }
                      />
                    </div>
                    <div>
                      <label> Steuersatz: (*) </label>
                      <br></br>
                      <select
                        className={"custom-select"}
                        style={{ width: "300px" }}
                        onChange={(evt) =>
                          this.changeTaxFeeArticleHandler(evt.target.value)
                        }
                      >
                        {this.state.taxFees.map((e, key) => {
                          return (
                            <option key={key} value={e.taxFee}>
                              {e.value} %
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <label> Brutto Verkaufspreis: (*) </label>
                      <input
                        placeholder="Preis"
                        disabled
                        name="Bruttopreis2"
                        style={{ width: "300px" }}
                        className="form-control"
                        value={this.state.priceWithoutTaxArticle}
                      />
                    </div>
                    <Icon
                      name="save"
                      tooltip="Einkaufartikel hinzufügen"
                      theme="light"
                      size="medium"
                      onClick={this.saveOrUpdate}
                    />
                    <span
                      style={{ fontSize: "17px", textDecoration: "underline" }}
                    >
                      Einkaufsartikel:
                    </span>
                    <br></br>
                    <br></br>
                    {this.state.articles &&
                      this.state.articles.map((value) => (
                        <div>
                          {value.shoppingArticle.name}, <b>Anzahl:</b>{" "}
                          {value.amount}, <b>Einzelpreis:</b>{" "}
                          {value.priceWithoutTax}, <b>Gesamtpreis:</b>{" "}
                          {value.total}{" "}
                          <button onClick={() => this.deleteOne(value.id)}>
                            -
                          </button>
                        </div>
                      ))}
                  </div>
                )}
                {((this.state.timeFrom &&
                  this.state.timeTo &&
                  this.state.priceWithoutTax &&
                  this.state.priceWithTax &&
                  this.state.addressId) ||
                  (this.state.client != null &&
                    this.state.addressId &&
                    this.state.onlyArticles)) && (
                  <div>
                    <button
                      onClick={() => this.save()}
                      className="btn btn-success btn-small"
                    >
                      Rechnung korrigieren
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditOrderComponent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(withRouter)(translate(EditOrderComponent));
