import React, { Component } from "react";
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import OrderService from "../services/OrderService";
import { Redirect } from "react-router-dom";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import update from "immutability-helper";
import PlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-places-autocomplete";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { translate } from "react-switch-lang";
import Cookies from "universal-cookie";
import UserService from "../services/UserService";
const cookies = new Cookies();
const marks = [
  {
    value: 0,
    label: "Ungenügend",
  },
  {
    value: 1,
    label: "OK",
  },
  {
    value: 2,
    label: "Gut",
  },
  {
    value: 3,
    label: "Sehr gut",
  },
];
class CheckinDriverComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      userId: 0,
      carId: 0,
      users: [],
      cars: [],
      defects: [],
      car: null,
      info: "",
      km: 0,
      cleanStatus: 3,
      address: "",
      lat: 0.0,
      lng: 0.0,
    };
    this.saveOrUpdate = this.saveOrUpdate.bind(this);
    this.changeDriverHandler = this.changeDriverHandler.bind(this);
    this.changeCarHandler = this.changeCarHandler.bind(this);
    this.changeInfoHandler = this.changeInfoHandler.bind(this);
    this.autocomplete = null;
  }

  changeDriverHandler = (event) => {
    var id = event.target.value;
    this.setState({ userId:  id, type: this.state.users.find(one => one.id ==id).type});
  };

  changeCarHandler = (event) => {
    var carId = event.target.value;
    var car = this.find(carId)[0];
    this.setState({ carId: carId, car: car, defects: car.defects });
  };

  changeRepairedHandler = (event, id) => {
    var defect = this.findDefect(id)[0];
    var checked = event.target.checked;
    var updated = update(defect, { repaired: { $set: checked } });
    this.setState({
      defects: update(this.state.defects, {
        $splice: [[this.state.defects.indexOf(defect), 1, updated]],
      }),
    });
  };

  find(idToSearch) {
    return this.state.cars.filter((item) => {
      return item.id == idToSearch;
    });
  }

  findDefect(idToSearch) {
    return this.state.defects.filter((item) => {
      return item.id == idToSearch;
    });
  }

  changeInfoHandler = (event) => {
    var info = event.target.value;
    this.setState({ info: info });
  };

  changeCleanStatusHandler = (event) => {
    var cleanStatus = event.target.value;
    this.setState({ cleanStatus: cleanStatus });
  };

  changeKmHandler = (event) => {
    var km = event.target.value;
    this.setState({ km: km });
  };

  cancel() {
    this.props.history.push("/driver-list/");
  }

  componentDidMount() {
    UserService.getAllWorkers().then((res) => {
      OrderService.getCars().then((res2) => {
        this.setState({
          cars: res2.data,
          car: res2.data[0],
          defects: res2.data[0].defects,
          carId: res2.data[0],
          users: res.data,
          userId: res.data[0] != null ? res.data[0].id : 0,
          type: res.data[0] != null ? res.data[0].type : 0,
        });
      });
    });
  }

  saveOrUpdate = (e) => {
    e.preventDefault();
    let s = {
      km: this.state.km,
      info: this.state.info,
      lat: this.state.lat,
      lng: this.state.lng,
      cleanStatus: this.state.cleanStatus,
    };
    let c = {
      id: this.state.car.id,
      statuses: [s],
      defects: this.state.defects,
    };
    console.log("Car => " + JSON.stringify(c));
    OrderService.updateCar(c, this.state.userId).then((res) => {
      this.props.history.push("/driver-list/");
    });
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) =>
        this.setState({ address: address, lat: latLng.lat, lng: latLng.lng })
      )
      .catch((error) => console.error("Error", error));
  };

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="card col-md-6 offset-md-3 offset-md-3">
              <div className="card-body">
                <div className="form-group">
                  Mitarbeiter auswählen:
                  <select
                    className={"custom-select"}
                    onChange={(evt) => this.changeDriverHandler(evt)}
                  >
                    {this.state.users.map((e, key) => {
                      return (
                        <option key={key} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {this.state.type == "DRIVER" && (
                  <div>
                    <div className="form-group">
                      <label> Auto auswählen: </label>
                      <select
                        className={"custom-select"}
                        value={this.state.carId}
                        onChange={(evt) => this.changeCarHandler(evt)}
                      >
                        {this.state.cars.map((e, key) => {
                          return (
                            <option key={key} value={e.id}>
                              {e.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label> KM Stand: </label>
                      <input
                        placeholder="KM Stand"
                        className="form-control"
                        value={this.state.km}
                        onChange={this.changeKmHandler}
                      />
                    </div>
                    <div style={{ marginLeft: "40px" }} className="form-group">
                      <label> Auto Sauberkeitszustand: </label>
                      <Box sx={{ width: 200 }}>
                        <Slider
                          defaultValue={this.state.cleanStatus}
                          step={1}
                          min={0}
                          max={3}
                          marks={marks}
                          valueLabelDisplay="on"
                          onChange={this.changeCleanStatusHandler}
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <label> Bemerkung: </label>
                      <input
                        placeholder="Bemerkung"
                        className="form-control"
                        value={this.state.info}
                        onChange={this.changeInfoHandler}
                      />
                    </div>
                    <div className="form-group">
                      <label> Mängel: </label>
                      {this.state.defects &&
                        this.state.defects.map((e, key) => {
                          return (
                            <div>
                              {e.name}
                              <div>
                                <input
                                  style={{ width: "20px", height: "20px" }}
                                  type="checkbox"
                                  checked={e.repaired}
                                  onChange={(event) =>
                                    this.changeRepairedHandler(event, e.id)
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="form-group" style={{ width: "200px" }}>
                      <label> Parkplatz Adresse: </label>
                      <PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: "Adresse",
                                className: "location-search-input form-control",
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {})}
                                  >
                                    <span style={{ fontSize: "12px" }}>
                                      {suggestion.description}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                )}
                <Icon
                  name="save"
                  tooltip="Einchecken"
                  theme="light"
                  size="medium"
                  onClick={this.saveOrUpdate}
                />
                <Icon
                  name="undo"
                  tooltip="Zurück zu den Fahrern"
                  theme="light"
                  size="medium"
                  onClick={this.cancel.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withRouter)(translate(CheckinDriverComponent));
