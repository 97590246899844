import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL + "/api/v1/producents";
const API_BASE_URL_CANCELLATION =
  process.env.REACT_APP_API_URL + "/api/v1/cancellation";
const API_BASE_URL_STORES = process.env.REACT_APP_API_URL + "/api/v1/stores";
const API_BASE_URL_SUPPLIERS =
  process.env.REACT_APP_API_URL + "/api/v1/suppliers";
const API_BASE_URL_HOLDINGS =
  process.env.REACT_APP_API_URL + "/api/v1/holdings";
const API_BASE_URL_COMPANIES =
  process.env.REACT_APP_API_URL + "/api/v1/companies";

class ProducentService {
  getProducents() {
    return axios.get(API_BASE_URL, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getStores(companyId) {
    return axios.get(API_BASE_URL_STORES + "/companies/" + companyId, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  saveStoreData(name, telefon, street, postalCode, number) {
    return axios.post(
      API_BASE_URL_STORES +
        "/contact/" +
        name +
        "/" +
        telefon +
        "/" +
        street +
        "/" +
        postalCode +
        "/" +
        number,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  saveShoppingArticleData(one) {
    return axios.post(API_BASE_URL + "/shoppings", one, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getStoresAll() {
    return axios.get(API_BASE_URL_STORES, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getCompanies() {
    return axios.get(API_BASE_URL_COMPANIES, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getHoldings() {
    return axios.get(API_BASE_URL_HOLDINGS, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getStoreById(id) {
    return axios.get(API_BASE_URL_STORES + "/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getProducentsAvailable() {
    return axios.get(API_BASE_URL + "/available", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  create(p) {
    return axios.post(API_BASE_URL, p, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  createSupplier(p) {
    return axios.post(API_BASE_URL_SUPPLIERS, p, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  saveAllSuppliers(p) {
    return axios.post(API_BASE_URL_SUPPLIERS + "/all", p, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getAllSuppliers() {
    return axios.get(API_BASE_URL_SUPPLIERS, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getById(id) {
    return axios.get(API_BASE_URL + "/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  update(p, id) {
    return axios.put(API_BASE_URL + "/" + id, p, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  assignItem(id, iId) {
    return axios.put(
      API_BASE_URL + "/" + id + "/" + iId + "/",
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  dissAssignItem(id, iId) {
    return axios.put(
      API_BASE_URL + "/back/" + id + "/" + iId + "/",
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  delete(id) {
    return axios.delete(
      API_BASE_URL + "/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  deleteSupplier(id) {
    return axios.post(
      API_BASE_URL_SUPPLIERS + "/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }
}

export default new ProducentService();
