import React, { Component } from "react";
import Sidenavigation from "../layouts/Sidenavigation";
import Topnavigation from "../layouts/Topnavigation";
import Quickbar from "../layouts/Quickbar";
import EditClientComponent from "../EditClientComponent";

class EditCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
    };
  }

  render() {
    return (
      <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
        <Sidenavigation />
        <main className="body-content">
          <Topnavigation />
          <EditClientComponent id={this.state.id} />
        </main>
        <Quickbar />
      </div>
    );
  }
}

export default EditCustomer;
