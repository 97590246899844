import React, { Component } from 'react'
import ArticleService from '../services/ArticleService';
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import { Redirect } from "react-router-dom";
import ProductsService from '../services/ProductsService';
import ProducentService from '../services/ProducentService';
import Paper from '@mui/material/Paper';
import { experimentalStyled as styled } from '@mui/material/styles';
import OrderService from '../services/OrderService';
import randomColor from "randomcolor";
import Grid from '@mui/material/Unstable_Grid2';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

class CreateProductionArticleComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            components: [],
            selectedComponents: [],
            name: '',
            nr: '',
            taxFees: [],
            units: []
        }
    }

    getPositionData(a) {
        var element = this.state.components.find((one) => {
            return one.id === a.id;
        })
        return element;
    }

    handleIncrement(a) {
        var data = this.state.components;
        var element = data.find((element) => {
            return element.id === a.id;
        })
        var updated = update(element, { amount: { $set: element.amount + 10 } });
        var newData = update(data, {
            $splice: [[this.state.components.indexOf(element), 1, updated]]
        });
        this.setState({ components: newData });
    }

    handleSetValue(a, newValue) {
        var data = this.state.components;
        var element = data.find((element) => {
            return element.id === a.id;
        })
        var updated = update(element, { amount: { $set: newValue } });
        var newData = update(data, {
            $splice: [[this.state.components.indexOf(element), 1, updated]]
        });
        this.setState({ components: newData });
    }

    handleDecrement(a) {
        var data = this.state.components;
        var element = data.find((element) => {
            return element.id === a.id;
        })
        var updated = update(element, { amount: { $set: element.amount - 10 } });
        var newData = update(data, {
            $splice: [[this.state.components.indexOf(element), 1, updated]]
        });
        this.setState({ components: newData });
    }

    changeActiveHandler(a, checked) {
        var data = this.state.components;
        var element = data.find((element) => {
            return element.id === a.id;
        })
        var updated = update(element, { checked: { $set: checked } });
        var newData = update(data, {
            $splice: [[this.state.components.indexOf(element), 1, updated]]
        });
        this.setState({ components: newData });
    }

    changeNoticeHandler(a, value) {
        var data = this.state.components;
        var element = data.find((element) => {
            return element.id === a.id;
        })
        var updated = update(element, { notice: { $set: value } });
        var newData = update(data, {
            $splice: [[this.state.components.indexOf(element), 1, updated]]
        });
        this.setState({ components: newData });
    }

    saveOrUpdate = (e) => {
        e.preventDefault();
        ArticleService.createProductionArticle(this.state.name, this.state.nr, this.state.components).then(res => {
            this.props.history.push('/production-articles/');
        });
    }

    componentDidMount() {
        ProducentService.getStores(1).then((res0) => {
            ProductsService.getProducts(res0.data[0].id).then((res) => {
                ArticleService.getAllTaxFees().then((res2) => {
                    OrderService.getPaymentModes().then((res3) => {
                        ArticleService.getAllUnits().then((res5) => {
                            this.setState({
                                stores: res0.data,
                                productGroups: res.data[0].products,
                                productGroup: res.data[0].products[0].id,
                                productCategories: res.data[0].products[0].groups,
                                productTypes: res.data[0].products[0].groups[0].subs,
                                productType: res.data[0].products[0].groups[0].subs[0].id,
                                taxFees: res2.data,
                                payments: res3.data,
                                units: res5.data
                            });
                        });
                    });
                });
            });
        });
    }

    findOne(list, id) {
        return list.filter(item => {
            return item.id == id
        })
    };

    changeSearchHandler(event) {
        var serachName = event.target.value;
        ArticleService.getAllArticleComponents().then((res4) => {
            var newData = [];
            this.state.components.forEach((element) => {
                if (element.checked) {
                    newData.push(element);
                }
            });
            res4.data.forEach((element) => {
                var pos = { 'id': element.id, 'name': element.name, 'checked': false, 'color': randomColor(), 'amount': 0, 'shoppingArticleList': element.shoppingArticleList }
                if (this.findOne(newData, element.id).length==0 && element.name.toLowerCase().includes(serachName.toLowerCase()) && serachName.length > 0) {
                    newData.push(pos);
                }
            });
            this.setState({
                components: newData
            });
        });
    }

    changeNameHandler = (event) => {
        this.setState({ name: event.target.value });
    }

    changeNrHandler = (event) => {
        this.setState({ nr: event.target.value });
    }

    find(idToSearch) {
        return this.state.productGroups.filter(item => {
            return item.id == idToSearch
        })
    };

    findTaxFee(taxFee) {
        return this.state.taxFees.filter(item => {
            return item.taxFee == taxFee
        })
    };

    render() {
        if (cookies.get('token') !== 'b5c9667644da67241316db50cc788bfd078849de') {
            return <Redirect to="/login" />;
        }
        return (
            <div>
                <br></br>
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="form-group">
                                <label> Name: (*) </label>
                                <input style={{ width: '250px' }} placeholder="Name" name="name" className="form-control"
                                    value={this.state.name} onChange={this.changeNameHandler} />
                            </div>
                            <div className="form-group">
                                <label> Nummer: (*) </label>
                                <input style={{ width: '250px' }} placeholder="Nummer" name="nr" className="form-control"
                                    value={this.state.nr} onChange={this.changeNrHandler} />
                            </div>
                            <Icon name="save" tooltip="Speichern" theme="light" size="medium" onClick={this.saveOrUpdate} />
                        </div>
                        <div style={{ marginLeft: '-250px' }} className="col-xl-6 col-md-12">
                            <div style={{ marginBottom: '8px' }}>Einkaufsartikel hinzufügen</div>
                            <div style={{ marginBottom: '8px' }}>
                                <input style={{ width: '250px' }} placeholder="Suchen" className="form-control"
                                    onChange={(evt) => this.changeSearchHandler(evt)} />
                            </div>
                            <Grid container spacing={{ xs: 4, md: 1 }} columns={{ xs: 4, sm: 10, md: 12 }}>
                                {this.state.components.map((one, index) => (
                                    <Grid xs={2} sm={2} md={3.5} key={index}>
                                        <Item style={{ backgroundColor: one.checked ? this.getPositionData(one).color : 'lightGrey' }}>{one.name}
                                            <br></br>
                                            <input style={{ width: '20px', height: '20px' }}
                                                type="checkbox"
                                                checked={one.checked}
                                                onChange={event => this.changeActiveHandler(one, event.target.checked)}
                                            />
                                            <br></br>
                                            {one.checked && <div>
                                                {this.getPositionData(one) && <input style={{ width: '50px' }} value={this.getPositionData(one).amount} onChange={(evt) => this.handleSetValue(one, evt.target.value)}></input>}
                                                <br></br>{one.shoppingArticleUnit}
                                                <br></br>
                                                Notiz
                                                <input style={{ width: '120px' }}
                                                    onChange={event => this.changeNoticeHandler(one, event.target.value)}
                                                /></div>
                                            }
                                        </Item>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default compose(
    withRouter,
)(CreateProductionArticleComponent)