import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";
import $ from "jquery";
import * as XLSX from "xlsx";
import ProducentService from "../../../services/ProducentService";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Restaurantcontent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurentlisttable: [],
    };
  }

  addstars = (e) => {
    var elem = e.target,
      parentTask = elem.closest(".ms-rating-item");
    $(parentTask).prevAll().removeClass("rated");
    $(parentTask).addClass("rated");
    $(parentTask).nextAll().addClass("rated");
  };

  componentDidMount() {
    ProducentService.getStoresAll().then((res1) => {
      this.setState({
        restaurentlisttable: res1.data,
      });
    });
  }

  onChangeData = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let sheetData = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
        blankrows: false,
      });
      for (var i = 1; i < sheetData.length; i++) {
        ProducentService.saveStoreData(
          sheetData[i][0],
          sheetData[i][1],
          sheetData[i][2],
          sheetData[i][3],
          sheetData[i][4]
        ).then((res2) => {
          ProducentService.getStoresAll().then((res1) => {
            this.setState({
              restaurentlisttable: res1.data,
            });
          });
        });
      }
    };
    reader.readAsBinaryString(file);
  };

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div className="ms-content-wrapper">
       <div className="row">
          <div style={{ marginLeft: "300px", marginBottom: "20px" }}>
            <p>Store Kontaktdaten importieren</p>
            <input type="file" onChange={this.onChangeData} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb />
            <div className="row">
              <div className="col-xl-12">
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6>Stores</h6>
                  </div>
                  <div className="ms-panel-body">
                    <div className="table-responsive">
                      <table className="table table-hover thead-primary">
                        <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Adresse</th>
                            <th scope="col">Typ</th>
                            <th scope="col">Währung</th>
                            <th scope="col">Verfügbar</th>
                            <th scope="col">Provisionsprozent (Extern)</th>
                            <th scope="col">Postleitzahlen</th>
                            <th scope="col">Mindestbestellwert</th>
                            <th scope="col">Firma/GmbH</th>
                            <th scope="col">Holding</th>
                            <th scope="col"> Bearbeiten / Löschen</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.restaurentlisttable.map((item, i) => (
                            <tr key={i}>
                              <th scope="row">{item.id}</th>
                              <td>{item.name}</td>
                              <td>
                                {item.street} {item.number} {item.postalCode}{" "}
                                {item.city} {item.country}
                              </td>
                              <td>
                                {item.dtype == "S"
                                  ? "Store"
                                  : item.dtype == "P"
                                  ? "Produzent"
                                  : "none"}
                              </td>
                              <td>
                                {item.currencies &&
                                  item.currencies[
                                    item.currencies.length - 1
                                  ].newValue
                                }
                              </td>
                              <td>{item.available == 1 ? "JA" : "NEIN"}</td>
                              <td>{item.provisionFeePercent}%</td>
                              <td>
                                {item.availablePostalCodes.map((d) => (
                                  <div>
                                    {d.postalCode}
                                    <br></br>
                                  </div>
                                ))}
                              </td>
                              <td>{item.minimumOrderAmount}</td>
                              <td>{item.company && item.company.name}</td>
                              <td>
                                {item.company.holding &&
                                  item.company.holding.name}
                              </td>
                              <td>
                                <Link to="#">
                                  <i className="fas fa-pencil-alt text-secondary" />
                                </Link>
                                <Link to="#">
                                  <i className="far fa-trash-alt ms-text-danger" />
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Restaurantcontent;
