import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import OrderPositionService from "../services/OrderPositionService";
import OrderService from "../services/OrderService";
import update from "immutability-helper";
import Moment from "moment";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Base64Downloader from "react-base64-downloader";
import "moment/locale/de";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import $, { event } from "jquery";
import ReactDOM from "react-dom";
import FileBase64 from "react-file-base64";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import DatePicker from "react-datepicker";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import Multiselect from "multiselect-react-dropdown";
import { translate } from "react-switch-lang";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { display } from "@mui/system";
import R from "raphael";
const cookies = new Cookies();

class ListSheetsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      expandedTicket: true,
      loading: true,
      success: false,
      error: false,
      searchInput: "",
      filteredData: [],
      open: false,
      id: 0,
      cancelReason: "",
      play: false,
      audio: new Audio(
        "https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"
      ),
      start: new Date(),
      end: new Date(),
    };
    Moment.locale("de");
    this.changeDateStartHandler = this.changeDateStartHandler.bind(this);
    this.changeDateEndHandler = this.changeDateEndHandler.bind(this);
    this.add = this.add.bind(this);
    this.delete = this.delete.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.addPaymentModeHandler = this.addPaymentModeHandler.bind(this);
    this.removePaymentModeHandler = this.removePaymentModeHandler.bind(this);
  }

  changeDateStartHandler = (event) => {
    this.setState({
      start: event,
    });
  };

  changeDateEndHandler = (event) => {
    this.setState({
      end: event,
    });
  };

  delete(id) {
    OrderService.delete(id).then((res) => {
      this.setState({
        orders: this.state.orders.filter((p) => p.id !== id),
        filteredData: this.state.filteredData.filter((p) => p.id !== id),
      });
    });
  }

  view(id) {
    OrderPositionService.getPdf(id);
  }

  view2(id) {
    OrderPositionService.getCSV(id);
  }

  generate(id) {
    OrderPositionService.getBill(id);
    this.props.history.push("/");
  }

  addNote(id) {
    this.props.history.push("/order-add-note/" + id);
  }

  handleOpen(id) {
    this.setState({
      open: true,
      id: id,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  matchIt(event, id) {
    if (event.target.checked === true) {
      OrderService.matchArticle(id).then((res2) => {
        window.location.reload();
      });
    }
  }

  componentDidMount() {
    this.state.audio.addEventListener("ended", () =>
      this.setState({ play: false })
    );
    OrderService.getSheets().then((res) => {
      OrderService.getOfferStatus().then((res3) => {
        this.setState({
          paymentModes: res3.data,
          orders: res.data,
          loading: false,
          filteredData: res.data,
        });
        this.setDataTable(res.data);
      });
    });
  }

  toggleExpandedTicket(rowData) {
    var existing = this.state.filteredData.filter(
      (one) => one.id === rowData.id
    )[0];
    var updated = update(existing, {
      checked: {
        $set: existing === undefined ? true : existing.checked ? false : true,
      },
    });
    var NewData = update(this.state.filteredData, {
      $splice: [[this.state.filteredData.indexOf(existing), 1, updated]],
    });
    this.setState({
      orders: update(this.state.filteredData, {
        $splice: [[this.state.filteredData.indexOf(existing), 1, updated]],
      }),
      filteredData: NewData,
    });
    this.setDataTable(NewData);
  }

  cancelOrder() {
    OrderService.cancelItem(this.state.id, this.state.cancelReason).then(
      (res1) => {
        OrderService.getOrders().then((res) => {
          this.setState({ orders: res.data, filteredData: res.data });
          window.location.reload();
        });
      }
    );
  }

  changeCancelReasonHandler(e) {
    this.setState({
      cancelReason: e.target.value,
    });
  }

  add() {
    this.props.history.push("/add-order/");
  }

  addSelf() {
    this.props.history.push("/add-self-order/");
  }

  cancel() {
    this.props.history.push("/");
  }

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput, orders } = this.state;
    let filteredData = orders.filter((value) => {
      return (
        (value.client &&
          value.client.name
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        value.id.toString().toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  sendMail(id) {
    OrderService.sendMail(id).then((res) => {
      if (res.data) {
        this.setState({ success: true });
        setTimeout(() => {
          this.setState({
            success: false,
          });
        }, 5000);
      } else {
        this.setState({ error: true });
        setTimeout(() => {
          this.setState({
            error: false,
          });
        }, 5000);
      }
    });
  }

  openFile(blobFile, file) {
    var blob = new Blob([this.base64ToArrayBuffer(blobFile)], {
      type: "application/pdf",
    });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = file;
    link.download = fileName;
    link.click();
  }

  base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  assignOfferStatus(cancellationId, itemId) {
    this.setState({ loading: true });
    OrderService.assignOfferStatus(cancellationId, itemId).then((resA) => {
      OrderService.getOffers().then((res) => {
        this.setState({
          orders: res.data,
          filteredData: res.data,
          loading: false,
        });
        this.setDataTable(res.data);
      });
    });
  }

  assignDone(itemId) {
    this.setState({ loading: true });
    OrderService.assignDone(itemId).then((resA) => {
      OrderService.getOffers().then((res) => {
        this.setState({
          orders: res.data,
          filteredData: res.data,
          loading: false,
        });
        this.setDataTable(res.data);
      });
    });
  }

  addPaymentModeHandler(selectedList) {
    var allFiltered = [];
    var data = this.state.filteredData;
    selectedList.forEach((element) => {
      var filtered = data.filter((item) => {
        return item.paymentData[0] && item.paymentData[0].mode == element.mode;
      });
      filtered.forEach((el) => {
        allFiltered.push(el);
      });
    });
    this.setState({ filteredData: allFiltered });
    this.setDataTable(allFiltered);
  }

  getFiles(files, id) {
    let d = {
      clientSignedFile: files[0].base64,
    };
    OrderService.updateWorkSheet(id, d).then((res0) => {
      OrderService.getSheets().then((res) => {
        OrderService.getOfferStatus().then((res3) => {
          this.setState({
            paymentModes: res3.data,
            orders: res.data,
            loading: false,
            filteredData: res.data,
          });
          this.setDataTable(res.data);
        });
      });
    });
  }

  getFiles2(files, id) {
    let d = {
      supervisorSignedFile: files[0].base64,
    };
    OrderService.updateWorkSheet2(id, d).then((res0) => {
      OrderService.getSheets().then((res) => {
        OrderService.getOfferStatus().then((res3) => {
          this.setState({
            paymentModes: res3.data,
            orders: res.data,
            loading: false,
            filteredData: res.data,
          });
          this.setDataTable(res.data);
        });
      });
    });
  }

  removePaymentModeHandler(selectedList) {
    if (selectedList.length > 0) {
      var allFiltered = [];
      var data = this.state.filteredData;
      selectedList.forEach((element) => {
        var filtered = data.filter((item) => {
          return (
            item.paymentData[0] && item.paymentData[0].mode == element.mode
          );
        });
        filtered.forEach((el) => {
          allFiltered.push(el);
        });
      });
      this.setState({ filteredData: allFiltered });
      this.setDataTable(allFiltered);
    } else {
      OrderService.getOffers().then((res) => {
        this.setState({ orders: res.data, filteredData: res.data });
        this.setDataTable(res.data);
      });
    }
  }

  search() {
    OrderService.getOrdersBetween(this.state.start, this.state.end).then(
      (res) => {
        this.setState({ orders: res.data, filteredData: res.data });
        this.setDataTable(res.data);
      }
    );
  }

  searchIt(event) {
    if (event.target.value.length > 0) {
      OrderService.search(event.target.value).then((res) => {
        this.setState({ orders: res.data, filteredData: res.data });
        this.setDataTable(res.data);
      });
    }
  }

  checkItem(event, id) {
    var existing = this.state.filteredData.find((one) => one.id === id);
    var updated = update(existing, {
      checkedProducent: {
        $set: event.target.checked,
      },
    });
    this.setState({
      orders: update(this.state.orders, {
        $splice: [[this.state.orders.indexOf(existing), 1, updated]],
      }),
      filteredData: update(this.state.filteredData, {
        $splice: [[this.state.filteredData.indexOf(existing), 1, updated]],
      }),
    });
  }

  setDataTable(dataP) {
    var data = [];
    dataP.forEach((element) => {
      var updated = update(element, {
        checkedProducent: {
          $set: false,
        },
      });
      data.push(updated);
    });
    dataP = data;
    const { t } = this.props;
    const columns = [
      {
        title: t("number"),
        data: "id",
      },
      {
        title: t("client"),
        data: "client",
      },
      {
        title: "Adresse",
        data: "client",
      },
      {
        title: t("status"),
        data: "colorStatus",
      },
      {
        title: "Unterschrift",
        data: "id",
      },
    ];
    $("#data-table-5").DataTable({
      order: [[0, "desc"]],
      data: dataP,
      columns: columns,
      bFilter: false,
      bDestroy: true,
      bStateSave: true,
      fnStateSave: function (oSettings, oData) {
        localStorage.setItem("offersDataTables", JSON.stringify(oData));
      },
      fnStateLoad: function (oSettings) {
        return JSON.parse(localStorage.getItem("offersDataTables"));
      },
      language: {
        processing: "DataTables is currently busy",
        url: "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json",
      },
      columnDefs: [
        {
          targets: [0],
          width: 100,
          createdCell: (td, cellData, rowData, row, col) => {
            ReactDOM.render(
              <div
                style={{
                  backgroundColor: rowData.colorStatus,
                  height: "100px",
                  borderRadius: "2px",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    paddingTop: "40px",
                  }}
                >
                  {rowData && rowData.number} <br></br>{" "}
                  <button
                    onClick={() =>
                      this.openFile(rowData.data, rowData.number + ".pdf")
                    }
                  >
                    PDF ansehen
                  </button>
                </div>
              </div>,
              td
            );
          },
        },
        {
          targets: [1],
          width: 100,
          createdCell: (td, cellData, rowData, row, col) => {
            ReactDOM.render(
              <div>
                {" "}
                Nummer {rowData.client != null ? rowData.client.nr : "---"}{" "}
                <br></br> {rowData.client != null && rowData.client.name}{" "}
                <br></br> {rowData.client != null && rowData.client.surname}{" "}
                Tel.:
                <br></br> {rowData.client && rowData.client.telefonNumber}{" "}
              </div>,
              td
            );
          },
        },
        {
          targets: [2],
          width: 100,
          createdCell: (td, cellData, rowData, row, col) => {
            ReactDOM.render(
              <div>
                {rowData.address && rowData.address.street}{" "}
                {rowData.address && rowData.address.number} <br></br>{" "}
                {rowData.address &&
                  rowData.address.floor &&
                  rowData.client &&
                  rowData.client.floor}
                {rowData.address && rowData.address.floor && <br></br>}{" "}
                {rowData.address && rowData.address.postalCode}{" "}
                {rowData.address && rowData.address.city} <br></br>{" "}
                {rowData.address && rowData.address.company} <br></br>
                {rowData.address && rowData.address.email} <br></br>
                {!rowData.address &&
                  rowData.client &&
                  rowData.client.street}{" "}
                {!rowData.address && rowData.client && rowData.client.number}{" "}
                <br></br>{" "}
                {!rowData.address &&
                  rowData.client &&
                  rowData.client.floor &&
                  rowData.client.floor}
                {!rowData.address && rowData.client && rowData.client.floor && (
                  <br></br>
                )}{" "}
                {!rowData.address &&
                  rowData.client &&
                  rowData.client.postalCode}{" "}
                {!rowData.address && rowData.client && rowData.client.city}{" "}
                <br></br>{" "}
                {!rowData.address && rowData.client && rowData.client.company}{" "}
                <br></br>
                {!rowData.address &&
                  rowData.client &&
                  rowData.client.email}{" "}
                <br></br>
              </div>,
              td
            );
          },
        },
        {
          targets: [3],
          width: 120,
          createdCell: (td, cellData, rowData, row, col) => {
            ReactDOM.render(
              <div
                style={{
                  width: "200px",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                }}
              >
                <br></br>Erstellt:{" "}
                {Moment(rowData.created).format("D/M/YYYY HH:mm")}
                <br></br>Geändert:{" "}
                {Moment(rowData.updated).format("D/M/YYYY HH:mm")}
                <br></br>
              </div>,
              td
            );
          },
        },
        {
          targets: [4],
          width: 50,
          createdCell: (td, cellData, rowData, row, col) => {
            ReactDOM.render(
              <div
                style={{
                  width: "250px",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                }}
              >
                <br></br>Kunde unterschrieben:
                {rowData.clientSigned === false ? (
                  <div>
                    <img
                      src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@15.0.1/img/apple/64/2757.png"
                      alt="white check mark"
                      className="__EmojiPicker__ epr-emoji-img"
                      loading="eager"
                      style={{
                        fontSize: "22px",
                        height: "25px",
                        width: "25px",
                      }}
                    ></img>
                    <label>
                      {" "}
                      Vom Kunden unterschriebenen Arbeitsschein hochladen (Bild){" "}
                    </label>
                    <div className="form-group">
                      <FileBase64
                        multiple={true}
                        onDone={(evt) => this.getFiles(evt, rowData.id)}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <img
                      src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2705.png"
                      alt="white check mark"
                      className="__EmojiPicker__ epr-emoji-img"
                      loading="eager"
                      style={{
                        fontSize: "22px",
                        height: "25px",
                        width: "25px",
                      }}
                    ></img>
                    {rowData.clientSignedFile && (
                      <div className="text-center">
                      <TransformWrapper>
                      <TransformComponent>
                        <img
                          className="img"
                          alt={rowData.id + "C"}
                          src={rowData.clientSignedFile}
                        />
                        </TransformComponent>
                        </TransformWrapper>
                        <Base64Downloader
                          base64={rowData.clientSignedFile}
                          downloadName={rowData.id + "_clientSignedFile"}
                        >
                          Herunterladen
                        </Base64Downloader>
                      </div>
                    )}
                  </div>
                )}
                <br></br>Schichtleiter unterschrieben:{" "}
                {rowData.supervisorSigned === false ? (
                  <div>
                    <img
                      src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@15.0.1/img/apple/64/2757.png"
                      alt="white check mark"
                      className="__EmojiPicker__ epr-emoji-img"
                      loading="eager"
                      style={{
                        fontSize: "22px",
                        height: "25px",
                        width: "25px",
                      }}
                    ></img>
                    <label>
                      {" "}
                      Vom Schichteiler unterschriebenen Arbeitsschein hochladen
                      (Bild){" "}
                    </label>
                    <div className="form-group">
                      <FileBase64
                        multiple={true}
                        onDone={(evt) => this.getFiles2(evt, rowData.id)}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <img
                      src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2705.png"
                      alt="white check mark"
                      className="__EmojiPicker__ epr-emoji-img"
                      loading="eager"
                      style={{
                        fontSize: "22px",
                        height: "25px",
                        width: "25px",
                      }}
                    ></img>
                    {rowData.supervisorSignedFile && (
                      <div className="text-center">
                      <TransformWrapper>
                      <TransformComponent>
                        <img
                          className="img"
                          alt={rowData.id + "S"}
                          src={rowData.supervisorSignedFile}
                        />
                        </TransformComponent>
                        </TransformWrapper>
                        <Base64Downloader
                          base64={rowData.supervisorSignedFile}
                          downloadName={rowData.id + "_supervisorSignedFile"}
                        >
                          Herunterladen
                        </Base64Downloader>
                      </div>
                    )}
                  </div>
                )}
                <br></br>
              </div>,
              td
            );
          },
        },
      ],
    });
  }

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-body">
                <br />
                <div className="row">
                  <div
                    className="ms-panel ms-panel-fh"
                    style={{
                      margin: "0 auto",
                      paddingBottom: "20px",
                      backgroundColor: "inherit",
                    }}
                  >
                    <ul
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      <li style={{ marginRight: "20px" }}>
                        <label>Arbeitsschein suchen</label>
                        <input
                          onChange={(evt) => this.searchIt(evt)}
                          className="form-control"
                          type="text"
                        ></input>
                      </li>
                      <li>
                        <label>Status</label>
                        <Multiselect
                          style={{
                            chips: {
                              background: "#ff6877",
                            },
                            multiselectContainer: {
                              color: "#ff6877",
                            },
                            searchBox: {
                              border: "none",
                              borderRadius: "0px",
                            },
                          }}
                          options={this.state.paymentModes} // Options to display in the dropdown
                          selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                          onSelect={this.addPaymentModeHandler} // Function will trigger on select event
                          onRemove={this.removePaymentModeHandler} // Function will trigger on remove event
                          displayValue="status" // Property name to display in the dropdown options
                        />
                      </li>
                      <li>
                        <label>Zeitraum</label>
                        <ul style={{ display: "flex" }}>
                          <li>
                            <DatePicker
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              locale="de"
                              selected={this.state.start}
                              value={this.state.start}
                              onChange={(date) =>
                                this.changeDateStartHandler(date)
                              }
                            />
                          </li>
                          <li style={{ marginLeft: "5px", marginTop: "6px" }}>
                            <label> Bis: </label>
                          </li>
                          <li style={{ marginLeft: "5px" }}>
                            <DatePicker
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              locale="de"
                              selected={this.state.end}
                              value={this.state.end}
                              onChange={(date) =>
                                this.changeDateEndHandler(date)
                              }
                            />
                          </li>
                          <input
                            style={{ marginLeft: "5px" }}
                            type="button"
                            value="Filtern"
                            className="form-control"
                            onClick={() => this.search()}
                          />
                        </ul>
                      </li>
                    </ul>
                    <div></div>
                  </div>
                </div>
                {this.state.success && (
                  <Stack
                    sx={{ marginLeft: "550px", width: "338px" }}
                    spacing={2}
                  >
                    <Alert severity="success">
                      Rechnung als E-Mail erfolgreich geschickt!
                    </Alert>
                  </Stack>
                )}
                {this.state.error && (
                  <Stack
                    sx={{ marginLeft: "550px", width: "338px" }}
                    spacing={2}
                  >
                    <Alert severity="error">
                      Fehler aufgetreten, E-Mail nicht hinterlegt!
                    </Alert>
                  </Stack>
                )}
                {this.state.loading && (
                  <Box style={{ paddingLeft: "450px" }}>
                    <CircularProgress color="inherit" />
                  </Box>
                )}
                {!this.state.loading && (
                  <div className="table-responsive">
                    <table
                      id="data-table-5"
                      className="table w-100 thead-primary"
                    ></table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ListSheetsComponent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(withRouter)(translate(ListSheetsComponent));
