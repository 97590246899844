import React, { Component } from 'react'
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import Countries from 'react-select-country';
import { Redirect } from "react-router-dom";
import ProducentService from '../services/ProducentService';
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import Cookies from 'universal-cookie';
import {
    translate,
} from 'react-switch-lang';
const cookies = new Cookies();

class AddSupplierComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            postalCode: '',
            number: '',
            street: '',
            city: '',
            country: '',
            telefonNumber: '',
            email: '',
            nr: '',
            mobile: '',
            countries: []
        }
        this.changeNameHandler = this.changeNameHandler.bind(this);
        this.changePostalCodeHandler = this.changePostalCodeHandler.bind(this);
        this.changeNumberHandler = this.changeNumberHandler.bind(this);
        this.changeTelefonNumberHandler = this.changeTelefonNumberHandler.bind(this);
        this.changeStreetHandler = this.changeStreetHandler.bind(this);
        this.changeCityHandler = this.changeCityHandler.bind(this);
        this.changeCountryHandler = this.changeCountryHandler.bind(this);
        this.changeNrHandler = this.changeNrHandler.bind(this);
        this.changeMobileHandler = this.changeMobileHandler.bind(this);
        this.changeEmailHandler = this.changeEmailHandler.bind(this);
        this.saveOrUpdate = this.saveOrUpdate.bind(this);
    }

    saveOrUpdate = (e) => {
        e.preventDefault();
        let c = {
            name: this.state.name,
            nr: this.state.nr,
            number: this.state.number,
            street: this.state.street,
            postalCode: this.state.postalCode,
            city: this.state.city,
            telefonNumber: this.state.telefonNumber,
            mobile: this.state.mobile,
            email: this.state.email,
            country: this.state.country
        };
        console.log('Supplier => ' + JSON.stringify(c));
        ProducentService.createSupplier(c).then(res => {
            this.props.history.push('/suppliers/');
        });
    }

    changeNameHandler = (event) => {
        this.setState({ name: event.target.value });
    }

    changePostalCodeHandler = (event) => {
        this.setState({ postalCode: event.target.value });
    }

    changeEmailHandler = (event) => {
        this.setState({ email: event.target.value });
    }

    changeNrHandler = (event) => {
        this.setState({ nr: event.target.value });
    }

    changeMobileHandler = (event) => {
        this.setState({ mobile: event.target.value });
    }

    changeStreetHandler = (event) => {
        this.setState({ street: event.target.value });
    }

    changeCityHandler = (event) => {
        this.setState({ city: event.target.value });
    }

    changeCountryHandler = (event) => {
        this.setState({ country: event.target.options[event.target.selectedIndex].text });
    }

    changeNumberHandler = (event) => {
        this.setState({ number: event.target.value });
    }

    changeTelefonNumberHandler = (event) => {
        this.setState({ telefonNumber: event.target.value });
    }

    cancel() {
        this.props.history.push('/suppliers/');
    }

    render() {
        if (cookies.get('token') !== 'b5c9667644da67241316db50cc788bfd078849de') {
            return <Redirect to="/login" />;
        }
        return (
            <div>
                <br></br>
                <div className="container">
                    <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3">
                            <div className="card-body">
                                <div className="form-group  has-success">
                                    <label for="inputSuccess"> Name (*): </label>
                                    <input placeholder="Name" id="inputSuccess" className="form-control"
                                        value={this.state.name} onChange={this.changeNameHandler} />
                                </div>
                                <div className="form-group  has-success">
                                    <label for="inputSuccess"> Nummer (*): </label>
                                    <input placeholder="Name" id="inputSuccess" className="form-control"
                                        value={this.state.nr} onChange={this.changeNrHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Telefonnummer: </label>
                                    <input placeholder="Telefonnummer" name="Telefonnummer" className="form-control"
                                        value={this.state.telefonNumber} onChange={this.changeTelefonNumberHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Straße: </label>
                                    <input placeholder="Straße" name="Straße" className="form-control"
                                        value={this.state.street} onChange={this.changeStreetHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Hausnummer: </label>
                                    <input placeholder="Hausnummer" name="Hausnummer" className="form-control"
                                        value={this.state.number} onChange={this.changeNumberHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Postleitzahl: </label>
                                    <input placeholder="Postleitzahl" name="Postleitzahl" className="form-control"
                                        value={this.state.postalCode} onChange={this.changePostalCodeHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Handynummer: </label>
                                    <input placeholder="Handynummer" name="Handynummer" className="form-control"
                                        value={this.state.mobile} onChange={this.changeMobileHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Email: </label>
                                    <input placeholder="Email" name="Email" className="form-control"
                                        value={this.state.email} onChange={this.changeEmailHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Stadt: </label>
                                    <input placeholder="Stadt" name="Stadt" className="form-control"
                                        value={this.state.city} onChange={this.changeCityHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Land: </label>
                                    <Countries className="form-control" ref="cList" name="country" empty=" -- Land auswählen --" onChange={this.changeCountryHandler} />
                                </div>
                                <Icon name="save" tooltip="Speichern" theme="light" size="medium" onClick={this.saveOrUpdate} />
                                <Icon name="undo" tooltip="Zurück zum Hauptmenü" theme="light" size="medium" onClick={this.cancel.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default compose(
    withRouter,
  )(translate(AddSupplierComponent))