import React, { Component } from "react";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { translate } from "react-switch-lang";
import ProductsService from "../services/ProductsService";
import CurrencyService from "../services/CurrencyService";
import ProducentService from "../services/ProducentService";
import ArticleService from "../services/ArticleService";
import * as XLSX from "xlsx";
import "moment/locale/de";
import ReactCountryFlag from "react-country-flag";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class ToppingsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toppings: [],
      data: [],
      filteredData: [],
      stores: [],
      filterValue: "",
      currency: "",
    };
  }

  componentDidMount() {
    ProducentService.getStores(1).then((res3) => {
      CurrencyService.getActualCurrency(res3.data[0].id).then((resC) => {
        ProductsService.getToppings(res3.data[0].id).then((res) => {
          this.setState({
            stores: res3.data,
            storeId: res3.data[0].id,
            currency: resC.data.newValue,
            toppings: res.data,
            filteredData: res.data,
          });
        });
      });
    });
  }

  setFilteredValue(name) {
    this.setState({
      filterValue: name,
      filteredData: this.state.data.filter((gr) => gr.name.includes(name)),
    });
  }

  cancel() {
    this.props.history.push("/");
  }

  add() {
    this.props.history.push("/add-topping/");
  }

  edit(articleId) {
    this.props.history.push("/edit-topping/" + articleId);
  }

  changeStoreHandler = (evt) => {
    var storeId = evt.target.value;
    this.setState({
      storeId: storeId,
    });
  };

  getDatesPriceByArticleId(articleId) {
    ArticleService.allDatesPrice(articleId).then((res3) => {
      return res3.data[res3.data.length - 1];
    });
  }

  getTimesPriceByArticleId(articleId) {
    ArticleService.allTimesPricell(articleId).then((res3) => {
      return res3.data[res3.data.length - 1];
    });
  }

  findOne(a, TypeId) {
    return a.prices.filter((item) => {
      return item.actionType == TypeId;
    });
  }

  onChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let sheetData = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
        blankrows: false,
      });
      for (var i = 1; i < sheetData.length; i++) {
        var Groups = [];
        let c = {
          name: sheetData[i][0],
          price: sheetData[i][1],
          nr: sheetData[i][10],
        };
        for (var j = 3; j <= 8; j++) {
          var one = sheetData[i][j];
          if (one.length > 1) {
            Groups.push(sheetData[0][j]);
          } else {
            Groups.push("NONE");
          }
        }
        console.log("Topping => " + JSON.stringify(c));
        ArticleService.saveTopping(
          c,
          sheetData[i][2],
          Groups,
          sheetData[i][9].length <= 0 ? "NONE" : sheetData[i][9]
        ).then((res2) => {
          ProducentService.getStores(3).then((res3) => {
            CurrencyService.getActualCurrency(res3.data[0].id).then((resC) => {
              ProductsService.getToppings(res3.data[0].id).then((res) => {
                this.setState({
                  stores: res3.data,
                  storeId: res3.data[0].id,
                  currency: resC.data.newValue,
                  toppings: res.data,
                  filteredData: res.data,
                });
              });
            });
          });
        });
      }
    };
    reader.readAsBinaryString(file);
  };

  onChangePrice = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let sheetData = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
        blankrows: false,
      });
      for (var i = 1; i < sheetData.length; i++) {
        ArticleService.saveToppingPrice(
          sheetData[i][0],
          "Sami 2",
          sheetData[i][2],
          sheetData[i][3],
          sheetData[i][4]
        ).then((res2) => {
          ProducentService.getStores(3).then((res3) => {
            CurrencyService.getActualCurrency(res3.data[0].id).then((resC) => {
              ProductsService.getToppings(res3.data[0].id).then((res) => {
                this.setState({
                  stores: res3.data,
                  storeId: res3.data[0].id,
                  currency: resC.data.newValue,
                  toppings: res.data,
                  filteredData: res.data,
                });
              });
            });
          });
        });
      }
    };
    reader.readAsBinaryString(file);
  };

  onChangeLanguage = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let sheetData = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
        blankrows: false,
      });
      for (var i = 1; i < sheetData.length; i++) {
        ArticleService.saveToppingLanguage(
          sheetData[i][0],
          sheetData[i][1],
          sheetData[i][2]
        ).then((res2) => {
          ProducentService.getStores(3).then((res3) => {
            CurrencyService.getActualCurrency(res3.data[0].id).then((resC) => {
              ProductsService.getToppings(res3.data[0].id).then((res) => {
                this.setState({
                  stores: res3.data,
                  storeId: res3.data[0].id,
                  currency: resC.data.newValue,
                  toppings: res.data,
                  filteredData: res.data,
                });
              });
            });
          });
        });
      }
    };
    reader.readAsBinaryString(file);
  };

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div style={{ marginLeft: "300px", marginBottom: "20px" }}>
            <p>Toppings importieren</p>
            <input type="file" onChange={this.onChange} />
          </div>
        </div>
        <div className="row">
          <div style={{ marginLeft: "300px", marginBottom: "20px" }}>
            <p>Toppingspreise importieren</p>
            <input type="file" onChange={this.onChangePrice} />
          </div>
        </div>
        <div className="row">
          <div style={{ marginLeft: "300px", marginBottom: "20px" }}>
            <p>Übersetungen (FR&EN) importieren</p>
            <input type="file" onChange={this.onChangeLanguage} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-header">
                <h6>Toppings</h6>
              </div>
              <div className="ms-panel-body">
                <div className="table-responsive">
                  <table className="table table-hover thead-primary">
                    <thead>
                      <tr>
                        <th scope="col">Nummer</th>
                        <th scope="col">Name</th>
                        <th scope="col">Preis</th>
                        <th scope="col">Artikelgruppe</th>
                        <th scope="col">Allergene</th>
                        <th scope="col">Maximum pro Kunde</th>
                        <th scope="col">Verfügbar in</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.toppings &&
                        this.state.toppings.map((item, i) => (
                          <tr key={i}>
                            <th scope="row">{item.nr}</th>
                            <td>
                              <ReactCountryFlag style={{fontSize: '12px'}} countryCode="DE" svg />{" "}
                              {item.name}
                              <br></br>
                              <ReactCountryFlag style={{fontSize: '12px'}} countryCode="FR" svg />{" "}
                              {item.nameFr}
                              <br></br>
                              <ReactCountryFlag style={{fontSize: '12px'}} countryCode="GB" svg />{" "}
                              {item.nameEn}
                            </td>
                            <td>
                              {Number(item.priceWithoutTax).toFixed(2)}{" "}
                              {this.state.currency}
                              <br></br>
                              {item.prices &&
                                Object.values(item.prices).map((i, key) => {
                                  return (
                                    <div key={key}>
                                      {i} {this.state.currency}
                                    </div>
                                  );
                                })}
                            </td>
                            <td>{item.subName}</td>
                            <td>
                              {item.allergenic &&
                                Object.values(item.allergenic)
                                  .map((item) => item.name)
                                  .join(", ")}
                            </td>
                            <td>{item.maxPerClient}</td>
                            <td>{item.groupNames}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ToppingsComponent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(ToppingsComponent);
