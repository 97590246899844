import React, { Component } from "react";
import Breadcrumb from "./Breadcrumb";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import ReactDOM from "react-dom";
import $, { nodeName } from "jquery";
import Icon from "react-crud-icons";
import ArticleService from "../../../services/ArticleService";
import { translate } from "react-switch-lang";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  print(id) {
    var content = document.getElementById(id);
    var pri = document.getElementById("i" + id).contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }
  componentDidMount() {
    ArticleService.getAllShoppings().then((res) => {
      const columns = [
        {
          title: "ID",
          data: "id",
        },
        {
          title: "Name",
          data: "name",
        },
        {
          title: "Bestätigt",
          data: "checked",
        },
        {
          title: "Operationen",
          data: null,
        },
      ];
      $("#data-table-1").DataTable({
        data: res.data,
        columns: columns,
        order: [[0, "desc"]],
        language: {
          url: "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json",
        },
        columnDefs: [
          {
            targets: [2],
            createdCell: (td, cellData, rowData, row, col) => {
              ReactDOM.render(
                rowData.checked == false ? (
                  <Icon
                    name="save"
                    tooltip="Bestätigen"
                    theme="light"
                    size="medium"
                    onClick={() => this.check(rowData.id)}
                  />
                ) : (
                  <Icon
                    tooltip="Bestätigt"
                    name="check"
                    theme="light"
                    size="medium"
                  />
                ),
                td
              );
            },
          },
          {
            targets: [3],
            createdCell: (td, cellData, rowData, row, col) => {
              const { t } = this.props;
              ArticleService.getAllArticleComponentsById(rowData.id).then(
                (res) => {
                  var totalsNew = [];
                  Object.values(res.data).forEach((m) => {
                    totalsNew.push(m.reduce((a, v) => (a = a + v.total), 0));
                  });
                  ReactDOM.render(
                    <div>
                      {
                        <Icon
                          name="add"
                          tooltip="Einkaufsartikel hinzufügen"
                          theme="light"
                          size="medium"
                          onClick={() =>
                            this.props.history.push(
                              "/add-shopping-article-item/" + rowData.id
                            )
                          }
                        />
                      }
                      {Object.keys(res.data).length > 0 && (
                        <Icon
                          name="print"
                          tooltip="Einkaufszettel ausdrucken"
                          onClick={() => this.print(rowData.id)}
                          theme="light"
                          size="medium"
                        />
                      )}
                      {Object.keys(res.data).length > 0 && (
                        <iframe
                          id={"i" + rowData.id}
                          style={{
                            display: "none",
                            height: "0px",
                            width: "0px",
                            position: "absolute",
                          }}
                        ></iframe>
                      )}
                      <div id={rowData.id} style={{ display: "none" }}>
                        <div className="row">
                          {Object.keys(res.data).map((key) => (
                            <div>
                              <b>
                                {
                                  JSON.stringify(key)
                                    .split("name=")[1]
                                    .split(",")[0]
                                }
                              </b>
                              <div>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Artikelname</th>
                                      <th>Artikelnummer</th>
                                      <th>Menge</th>
                                      <th>Steuersatz</th>
                                      <th>Nettopreis</th>
                                      <th>Bruttopreis</th>
                                      <th>Inhalt (roh)</th>
                                      <th>Inhalt (rein)</th>
                                      <th>Einheit</th>
                                      <th>Verpackungsform</th>
                                      <th>Gesamt</th>
                                    </tr>
                                  </thead>
                                  {res.data[key].map((p) => (
                                    <tbody>
                                      <tr key={p.id}>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "200px",
                                          }}
                                        >
                                          {" "}
                                          {p.shoppingArticle.name}{" "}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "200px",
                                          }}
                                        >
                                          {" "}
                                          {p.shoppingArticle.nr}{" "}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "200px",
                                          }}
                                        >
                                          {" "}
                                          {p.amount}{" "}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "200px",
                                          }}
                                        >
                                          {" "}
                                          {t(p.taxFee.toLowerCase())}{" "}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "200px",
                                          }}
                                        >
                                          {" "}
                                          {p.priceWithoutTax}{" "}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "200px",
                                          }}
                                        >
                                          {" "}
                                          {p.priceWithTax}{" "}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "100px",
                                          }}
                                        >
                                          {" "}
                                          {p.shoppingArticle.quantity}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "100px",
                                          }}
                                        >
                                          {" "}
                                          {p.shoppingArticle.quantityPure}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "150px",
                                          }}
                                        >
                                          {" "}
                                          {
                                            p.shoppingArticle
                                              .shoppingArticleUnit
                                          }
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "150px",
                                          }}
                                        >
                                          {" "}
                                          {t(
                                            p.shoppingArticle.pack.toLowerCase()
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "150px",
                                          }}
                                        >
                                          {" "}
                                          {p.total}
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))}
                                </table>
                              </div>
                            </div>
                          ))}
                          <table
                            style={{ marginTop: "25px", marginLeft: "1375px" }}
                          >
                            <thead>
                              <tr>
                                <th>Insgesamt</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ paddingLeft: "15px" }}>
                                  {Number(
                                    totalsNew.reduce((a, v) => (a = a + v), 0)
                                  ).toFixed(2)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>,
                    td
                  );
                }
              );
            },
          },
        ],
      });
    });
  }

  check(id) {
    ArticleService.checkShopping(id).then((res2) => {
      window.location.reload();
    });
  }

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb />
            <div className="ms-panel">
              <div className="ms-panel-header">
                <h6>
                  Einkauf<br></br>
                  <Link
                    style={{ fontSize: "25px", color: "black" }}
                    title="Neuer Einkauf anlegen"
                    to={"/add-shopping"}
                  >
                    +
                  </Link>
                </h6>
              </div>
              <div className="ms-panel-body">
                <div className="table-responsive">
                  <table
                    id="data-table-1"
                    className="table w-100 thead-primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Content.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(withRouter)(translate(Content));
