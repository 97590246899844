import React, { Component } from "react";
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import OrderService from "../services/OrderService";
import "moment/locale/de";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { translate } from "react-switch-lang";
import Cookies from "universal-cookie";
import MapComponent from "./MapComponent";
import { Marker, InfoWindow } from "react-google-maps";
import Moment from "moment";
import GoogleMapReact from "google-map-react";

const cookies = new Cookies();

const columns: GridColDef[] = [
  {
    field: "bill",
    headerName: "Nummer",
    width: 90,
    valueGetter: (params) => {
      let result = [];
      if (params.row.bill) {
        if (params.row.bill.number) {
          result.push(params.row.bill.number);
        }
      }
      return result.join(", ");
    },
  },
  {
    field: "street",
    headerName: "Adresse",
    width: 280,
    valueGetter: (params) => {
      let result = [];
      if (params.row.client) {
        if (params.row.client.street) {
          result.push(
            params.row.client.street +
              " " +
              params.row.client.nr +
              " " +
              params.row.client.city
          );
        }
      } else {
        result = ["Unknown"];
      }
      return result.join(", ");
    },
  },
  {
    field: "amount",
    headerName: "Betrag",
    width: 80,
  },
];

const columnsD: GridColDef[] = [
  {
    field: "item",
    headerName: "Nummer",
    width: 100,
    valueGetter: (params) => {
      let result = [];
      if (params.row.item) {
        if (params.row.item.bill.number) {
          result.push(params.row.item.bill.number);
        }
      }
      return result.join(", ");
    },
  },
  {
    field: "street",
    headerName: "Adresse",
    width: 280,
    valueGetter: (params) => {
      let result = [];
      if (params.row.item.client) {
        if (params.row.item.client.street) {
          result.push(
            params.row.item.client.street +
              " " +
              params.row.item.client.nr +
              " " +
              params.row.item.client.city
          );
        }
      } else {
        result = ["Unknown"];
      }
      return result.join(", ");
    },
  },
];

const columnsF: GridColDef[] = [
  {
    field: "id",
    headerName: "Nummer",
    width: 30,
  },
  {
    field: "name",
    headerName: "Name",
    width: 100,
  },
  {
    field: "telefonNumber",
    headerName: "Handy",
    width: 100,
  },
  {
    field: "status",
    headerName: "Status",
    width: 90,
    valueGetter: (params) => {
      return params.row.status;
    },
  },
  {
    field: "checkedTime",
    headerName: "Eingecheckt um",
    width: 120,
    valueGetter: (params) => {
      return params.row.user.checkedTime != null
        ? Moment(params.row.user.checkedTime).format("D/M/YYYY HH:mm")
        : "---";
    },
  },
  {
    field: "backIn",
    headerName: "Zurück um",
    width: 120,
    valueGetter: (params) => {
      var d = new Date(); // get current date
      d.setHours(d.getHours(), d.getMinutes() + params.row.backIn, 0, 0);
      return params.row.backIn > 0 ? Moment(d).format("D/M/YYYY HH:mm") : "---";
    },
  },
];

class AssignDriverComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ways: [],
      markers: [],
      driver: null,
      items: [],
      drivers: [],
      driverId: 0,
      self: this.props,
      deliveries: [],
      selectionModel: [],
      selectionModel2: [],
      selectionModel3: [],
      routes: [],
      origin: null,
      destination: null,
      road: null,
    };
  }

  saveOrUpdate = (e) => {
    e.preventDefault();
    OrderService.assignDriver(
      this.state.driver.id,
      this.state.selectionModel
    ).then((res) => {
      OrderService.getDeliveries(this.state.driver.id).then((res2) => {
        OrderService.getPendingOrders().then((res3) => {
          OrderService.getAllDrivers().then((res) => {
            this.setState({
              drivers: res.data,
              driverId: res.data.id,
              items: res3.data,
              deliveries: res2.data,
            });
            this.setMapData(this.state.driver.id);
            this.updateMarkers(res3.data);
          });
        });
      });
    });
  };

  saveOrUpdateBack = (e) => {
    e.preventDefault();
    OrderService.disAssignDriver(
      this.state.driver.id,
      this.state.selectionModel2
    ).then((res) => {
      OrderService.getDeliveries(this.state.driver.id).then((res2) => {
        OrderService.getPendingOrders().then((res3) => {
          OrderService.getAllDrivers().then((res) => {
            this.setState({
              drivers: res.data,
              driverId: res.data.id,
              items: res3.data,
              deliveries: res2.data,
            });
            this.setMapData(this.state.driver.id);
            this.updateMarkers(res3.data);
          });
        });
      });
    });
  };

  cancel() {
    this.props.history.push("/");
  }

  componentDidMount() {
    OrderService.getPendingOrders().then((res) => {
      OrderService.getAllDrivers().then((res2) => {
        this.setState({
          items: res.data,
          drivers: res2.data,
        });
        this.updateMarkers(res.data);
      });
    });
  }

  updateMarkers(data) {
    const markersA = [];
    data.forEach((m) => {
      if (m.client != null && m.client.lat && m.client.lng) {
        markersA.push({
          id: m.id,
          name: m.bill.number,
          color:
            m.status == "PROGRESSING"
              ? "yellow"
              : m.status == "ASSIGNED"
              ? "blue"
              : m.status == "READY"
              ? "green"
              : "red",
          position: { lat: m.client.lat, lng: m.client.lng },
        });
      }
    });
    this.setState({ markers: markersA });
  }

  setSearch(search) {
    if (search.length > 0 && !this.isNumber(search)) {
      OrderService.getDriverBySearch(search).then((res) => {
        this.setState({
          drivers: res.data,
          driverId: res.data.id,
        });
      });
    } else {
      OrderService.getAllDrivers().then((res) => {
        this.setState({
          drivers: res.data,
          driverId: res.data.id,
        });
      });
    }
  }

  isNumber(str) {
    if (str.trim() === "") {
      return false;
    }
    return !isNaN(str);
  }

  setSelectionModel(model) {
    this.setState({
      selectionModel: model,
    });
  }

  setSelectionModel2(model) {
    this.setState({
      selectionModel2: model,
    });
  }

  setSelectionModel3(model) {
    this.setState({
      selectionModel3: model,
    });
    this.setMapData(model[0]);
  }

  startTour = (e) => {
    var Ids = [];
    var counter = 0;
    this.state.deliveries.forEach((m) => {
      Ids.push({
        id: m.id,
        duration: this.state.routes[counter++].location.duration_value,
      });
    });
    OrderService.startTour(
      this.state.driver.id,
      Number(
        this.state.routes
          .map((one) => one.location.duration)
          .reduce((acc, curr) => acc + parseInt(curr, 10) + 5, 0)
      ),
      Ids
    ).then((res) => {
      window.location.reload();
    });
  };

  setMapData(driverId) {
    OrderService.getDeliveries(driverId).then((res2) => {
      if (res2.data.length > 0) {
        var waysA = [];
        var p = res2.data[0].item.producent;
        res2.data.forEach((m) => {
          if (m.status !== "CANCELLED") {
            waysA.push({
              location: { lat: m.item.client.lat, lng: m.item.client.lng },
            });
          }
        });
        this.setState({
          origin: { lat: p.lat, lng: p.lng },
          destination: { lat: p.lat, lng: p.lng },
          ways: waysA,
        });
        this.apiIsLoaded(this.state.road, waysA);
      }
      this.setState({
        deliveries: res2.data,
        filteredData: res2.data,
        driver: this.state.drivers.filter((one) => one.id === driverId)[0],
      });
    });
  }

  apiIsLoaded(map, ways) {
    if (map != null) {
      this.setState({
        road: map,
      });
    }
    var directionsRenderer = new window.google.maps.DirectionsRenderer();
    var directionsService = new window.google.maps.DirectionsService();
    var distanceService = new window.google.maps.DistanceMatrixService();
    directionsService.route({
      origin: this.state.origin,
      destination: this.state.destination,
      optimizeWaypoints: true,
      travelMode: window.google.maps.TravelMode.DRIVING,
      waypoints: ways,
    });
    var routes = [];
    distanceService
      .getDistanceMatrix({
        origins: [this.state.origin],
        destinations: this.state.ways,
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
        avoidHighways: false,
        avoidTolls: false,
      })
      .then((response) => {
        for (var i = 0; i < response.rows[0].elements.length; i++) {
          var distance = response.rows[0].elements[i].distance;
          var duration = response.rows[0].elements[i].duration;
          var distance_in_kilo = distance.value / 1000; // the km
          routes.push({
            location: {
              duration_value: duration.value,
              duration: duration.text,
              km: distance_in_kilo,
            },
          });
        }
      });
    distanceService
      .getDistanceMatrix({
        origins: [this.state.ways[this.state.ways.length - 1]],
        destinations: [this.state.origin],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
        avoidHighways: false,
        avoidTolls: false,
      })
      .then((response) => {
        var distance = response.rows[0].elements[0].distance;
        var duration = response.rows[0].elements[0].duration;
        var distance_in_kilo = distance.value / 1000; // the km
        routes.push({
          location: { duration: duration.text, km: distance_in_kilo },
        });
        this.setState({
          routes: routes,
        });
      });
    directionsRenderer.setMap(map);
    directionsService.route(
      {
        origin: this.state.origin,
        destination: this.state.destination,
        optimizeWaypoints: true,
        travelMode: window.google.maps.TravelMode.DRIVING,
        waypoints: ways,
      },
      (directions) => {
        directionsRenderer.setDirections(directions);
      }
    );
  }

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <br></br>
        <div className="row">
          <div className="col" style={{ marginLeft: "5px" }}>
            <div>
              <label> Fahrer: </label>
              <div>
                <input
                  type="text"
                  style={{ width: "200px" }}
                  className="form-control"
                  placeholder="Name eingeben"
                  onChange={(e) => this.setSearch(e.target.value)}
                ></input>
              </div>
              <br></br>
              <div style={{ height: 412, width: "586px" }}>
                <DataGrid
                  style={{ fontSize: "11px" }}
                  rows={this.state.drivers}
                  columns={columnsF}
                  pageSize={5}
                  isRowSelectable={(params) => params.row.status !== "DRIVING"}
                  selectionModel={this.state.selectionModel3}
                  hideFooterSelectedRowCount={true}
                  onSelectionModelChange={(newSelectionModel) => {
                    this.setSelectionModel3(newSelectionModel);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col" style={{marginTop:'51px'}}>
            <div className="form-group">
              {this.state.items.length <= 0 && (
                <span>Keine offenen Bestellungen</span>
              )}
              {this.state.items.length > 0 && (
                <div>
                  <label> Bestellungen: </label>
                  <div style={{ height: 412, width: "586px" }}>
                    <DataGrid
                      style={{ fontSize: "11px" }}
                      rows={this.state.items}
                      columns={columns}
                      pageSize={5}
                      checkboxSelection={true}
                      selectionModel={this.state.selectionModel}
                      hideFooterSelectedRowCount={true}
                      isRowSelectable={(params) =>
                        params.row.status === "READY"
                      }
                      onSelectionModelChange={(newSelectionModel) => {
                        this.setSelectionModel(newSelectionModel);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            {this.state.selectionModel.length > 0 &&
              this.state.driver != null && (
                <Icon
                  name="save"
                  tooltip="In die Tour hinzufügen"
                  theme="light"
                  size="medium"
                  onClick={this.saveOrUpdate}
                />
              )}
            <Icon
              name="undo"
              tooltip="Zurück zu den Bestellungen"
              theme="light"
              size="medium"
              onClick={this.cancel.bind(this)}
            />
          </div>
          <div className="col" style={{marginTop:'73px'}}>
            <div style={{ height: "450px", width: "500px" }}>
              {this.state.items.length > 0 && (
                <MapComponent
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD15WCZGqAdq0JhfYXjIx4b6v1PrrYMP8Q&v=3.exp&libraries=geometry,drawing,places"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  defaultZoom={11.5}
                  defaultCenter={{ lat: 0.0, lng: 0.0 }}
                >
                  {this.state.markers.map((marker) => (
                    <Marker
                      icon={
                        "http://maps.google.com/mapfiles/ms/icons/" +
                        marker.color +
                        "-dot.png"
                      }
                      key={marker.id}
                      position={marker.position}
                      onClick={() => {}}
                    >
                      <InfoWindow onCloseClick={() => {}}>
                        <div
                          style={{ fontWeight: "bolder", color: marker.color }}
                        >
                          {marker.name}
                        </div>
                      </InfoWindow>
                    </Marker>
                  ))}
                </MapComponent>
              )}
            </div>
          </div>
          <div
            className="col"
            style={{ marginLeft: "10px", marginTop: "120px" }}
          >
            {this.state.routes &&
              this.state.routes.length > 0 &&
              this.state.deliveries.length > 0 &&
              this.state.routes.map((one, index) => (
                <p style={{ fontSize: "17px" }}>
                  Strecke {index + 1} : {one.location.km} km Dauer:{" "}
                  {one.location.duration}
                </p>
              ))}
            {this.state.routes && this.state.routes.length > 0 &&
                this.state.deliveries.length > 0 && (
              <div>
                <p style={{ fontSize: "17px" }}>
                  Tour dauert circa:{" "}
                  {Number(
                    this.state.routes
                      .map((one) => one.location.duration)
                      .reduce((acc, curr) => acc + parseInt(curr, 10) + 5, 0)
                  )}{" "}
                  Minuten
                </p>
                <Icon
                  name="save"
                  tooltip="Tour starten"
                  theme="light"
                  size="medium"
                  onClick={this.startTour}
                />
              </div>
            )}
          </div>
          <div className="col">
            {this.state.driver && this.state.deliveries && (
              <div>
                <br></br>
                <p style={{ fontSize: "17px" }}>
                  {this.state.driver.id} {this.state.driver.name} Bargeldbestand{" "}
                  {this.state.driver.bar}
                </p>
                <br></br>
                <div>
                  <div style={{ height: 212, width: "586px" }}>
                    <DataGrid
                      style={{ fontSize: "11px" }}
                      rows={this.state.deliveries}
                      columns={columnsD}
                      pageSize={5}
                      checkboxSelection={true}
                      selectionModel={this.state.selectionModel2}
                      hideFooterSelectedRowCount={true}
                      onSelectionModelChange={(newSelectionModel) => {
                        this.setSelectionModel2(newSelectionModel);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {this.state.selectionModel2.length > 0 &&
              this.state.driver != null && (
                <Icon
                  name="save"
                  tooltip="Lieferungen aus der Tour rausnehmen"
                  theme="light"
                  size="medium"
                  onClick={this.saveOrUpdateBack}
                />
              )}
          </div>
          <div className="col">
            <div
              style={{ paddingLeft: "18px", height: "450px", width: "518px" }}
            >
              {this.state.deliveries.length > 0 &&
                this.state.origin &&
                this.state.destination &&
                this.state.ways.length > 0 && (
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyD15WCZGqAdq0JhfYXjIx4b6v1PrrYMP8Q",
                    }}
                    defaultZoom={10}
                    center={this.state.origin}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map }) =>
                      this.apiIsLoaded(map, this.state.ways)
                    }
                  ></GoogleMapReact>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withRouter)(translate(AssignDriverComponent));
