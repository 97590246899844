import React, { Component } from 'react'
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import CurrencyList from 'currency-list'
import CurrencyService from '../services/CurrencyService';
import axios from 'axios';
import Moment from 'moment';
import 'moment/locale/de';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Redirect } from "react-router-dom";
import ProducentService from '../services/ProducentService';
import Breadcrumb from './sections/Currency/Breadcrumb';
import "datatables.net-bs4/js/dataTables.bootstrap4"
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css"
import $ from 'jquery';
import ReactDOM from 'react-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class CurrencyComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            cs: [],
            histories: [],
            stores: [],
            currency: '',
            rate: 0.0,
            actual: '',
            actualCode: '',
            open: false,
            storeId: 0
        }
        this.onDropdownSelectedCurrency = this.onDropdownSelectedCurrency.bind(this);
        this.saveOrUpdate = this.saveOrUpdate.bind(this);
        this.cancel = this.cancel.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        Moment.locale('de');
    }

    handleOpen = () => {
        this.setState({
            open: true
        });
    };

    handleClose = () => {
        this.setState({
            open: false
        });
    };

    saveOrUpdate = () => {
        if (this.state.actual !== this.state.currency.slice(1, -1)) {
            CurrencyService.create({ newValue: this.state.currency, oldValue: "(" + this.state.actual + ")", rate: this.state.rate }, this.state.storeId).then(res => {
                this.props.history.push('/params/');
            });
        }
    }

    onDropdownSelectedCurrency = (event) => {
        var v = event.target.value;
        axios.get("https://api.exchangerate.host/latest?base=" + this.state.actual + "&symbols=" + ("(" + v.split(' ')[v.split(' ').length - 1] + ")").slice(1, -1)).then(res => {
            this.setState({
                currency: "(" + v.split(' ')[v.split(' ').length - 1] + ")",
                rate: JSON.stringify(res.data.rates).slice(1, -1).split(":")[1]
            });
        });
    }

    cancel = () => {
        this.props.history.push('/params/');
    }

    changeStoreHandler = (evt) => {
        var storeId = evt.target.value;
        CurrencyService.getAll(storeId).then(res3 => {
            CurrencyService.getActualCurrency(storeId).then(res1 => {
                this.setState({
                    actual: res1.data.newValue.slice(1, -1),
                    actualCode: res1.data.newValue.slice(1, -1),
                    histories: res3.data,
                    storeId: storeId
                })
            });
        });
    }

    componentDidMount() {
        ProducentService.getStores(1).then(res3 => {
            CurrencyService.getActualCurrency(res3.data[0].id).then(res1 => {
                CurrencyService.getAll(res3.data[0].id).then(res2 => {
                    const columns = [
                        {
                            title: 'Geändert am',
                            data: 'created'
                        },
                        {
                            title: 'Von -> Auf',
                            data: 'created'
                        },{
                            title: 'Kurs',
                            data: 'rate'
                        }
                    ];
                    $('#data-table-5').DataTable({
                        data: res2.data,
                        columns: columns,
                        autoWidth: false,
                        language: {
                            "url": "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json"
                        }, 'columnDefs': [
                            {
                                'targets': [0],
                                width: 450,
                                createdCell: (td, cellData, rowData, row, col) => {
                                    ReactDOM.render(<div>{Moment(rowData.created).format('D/M/YYYY HH:mm')}</div>, td)
                                }
                            }, {
                                createdCell: (td, cellData, rowData, row, col) => {
                                    ReactDOM.render(<div>{rowData.oldValue && rowData.oldValue.slice(1, -1)} > {rowData.newValue && rowData.newValue.slice(1, -1)}</div>, td)
                                },

                                "targets": [1],
                            }
                        ]
                    });
                    this.setState({
                        cs: Object.values(CurrencyList.getAll('de_DE')),
                        currency: "(" + Object.values(CurrencyList.getAll('de_DE'))[0].symbol + ")",
                        rate: '',
                        actual: res1.data.newValue.slice(1, -1),
                        actualCode: res1.data.newValue.slice(1, -1),
                        histories: res2.data,
                        stores: res3.data,
                        storeId: res3.data[0].id
                    })
                });
            });
        });
    }

    render() {
        if (cookies.get('token') !== 'b5c9667644da67241316db50cc788bfd078849de') {
            return <Redirect to="/login" />;
        }
        return (
            <div>
                <br></br>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>Währungsmanager</h6>
                                </div>
                                <div className="ms-panel-body">
                                    <div className="table-responsive">
                                        <div className="card col-md-6 offset-md-3 offset-md-3" style={{ marginBottom: '30px' }}>
                                            <div className="card-body">
                                                Store auswählen:
                                                <div>
                                                    <select className={'custom-select'} onChange={(evt) => this.changeStoreHandler(evt)}>
                                                        {this.state.stores.map((e, key) => {
                                                            return <option key={key} value={e.id} >{e.name}</option>;
                                                        })}
                                                    </select>
                                                </div>
                                                <br></br>
                                                <div className="form-group">
                                                    <select className={'custom-select'} name="kunde" value={this.state.cId} onChange={(evt) => this.onDropdownSelectedCurrency(evt)}>
                                                        {this.state.cs.map((e, key) => {
                                                            if (e.code === this.state.actualCode) {
                                                                return <option key={key} value={e.id} selected>{e.name} {e.symbol} {e.code}</option>;
                                                            }
                                                            return <option key={key} value={e.id}>{e.name} {e.symbol} {e.code}</option>;
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    {this.state.rate !== '' && <div style={{ fontSize: '18px', }}><div style={{ marginLeft: '30px' }}>Aktueller Kurs: {this.state.rate} {this.state.actual}</div></div>}
                                                </div>
                                                <Icon name="save" tooltip="Speichern" theme="light" size="medium" onClick={this.handleOpen} />
                                                <Icon name="undo" tooltip="Zurück zum Stammdatenmenü" theme="light" size="medium" onClick={this.cancel.bind(this)} />
                                            </div>
                                        </div>

                                        <div className="ms-panel-body">
                                            <div className="table-responsive">
                                                <table id="data-table-5" className="table w-100 thead-primary" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText style={{ fontFamily: 'Dosis, sans-serif !important' }} id="alert-dialog-description">
                                Sind Sie sicher?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose}>Nein</Button>
                            <Button onClick={this.saveOrUpdate} autoFocus>
                                Ja
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }
}

export default CurrencyComponent