import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Base64Downloader from "react-base64-downloader";
import Icon from "react-crud-icons";
import PropTypes from "prop-types";

import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  translate,
} from "react-switch-lang";
import en from "../languages/en.json";
import de from "../languages/de.json";
import Cookies from "universal-cookie";
import ArticleService from "../services/ArticleService";
import FileBase64 from "react-file-base64";
import BillService from "../services/BillService";
import OrderService from "../services/OrderService";

const cookies = new Cookies();

setTranslations({ en, de });
var userLang = navigator.language || navigator.userLanguage;
setDefaultLanguage(userLang);
setLanguageCookie();

class EditCompanyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      surname: "",
      name: "",
      nr: "",
      telefonNumber: "",
      telefonNumber2: "",
      companyName: "",
      street: "",
      number: "",
      postalCode: "",
      taxNumber: "",
      city: "",
      country: "",
      email: "",
      logo: "",
      error: "",
    };
    this.saveOrUpdate = this.saveOrUpdate.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentDidMount() {
    this.fetchCompanyData();
  }

  fetchCompanyData() {
    OrderService.getCompany().then((res) => {
      let c = res.data;
      this.setState({
        name: c.name,
        surname: c.surname,
        companyName: c.companyName,
        telefonNumber: c.telefonNumber,
        telefonNumber2: c.telefonNumber2,
        email: c.email,
        street: c.street,
        taxNumber: c.taxNumber,
        city: c.city,
        postalCode: c.postalCode,
        number: c.number,
        logo: c.logo,
      });
    });
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  changeSurnameHandler = (event) => {
    this.setState({ surname: event.target.value });
  };

  changeNameHandler = (event) => {
    this.setState({ name: event.target.value });
  };

  changeTaxNumberHandler = (event) => {
    this.setState({ name: event.target.value });
  };

  changeTelephoneNumberHandler = (event) => {
    this.setState({ telefonNumber: event.target.value });
  };

  changeTelephoneNumber2Handler = (event) => {
    this.setState({ telefonNumber2: event.target.value });
  };

  changeStreetHandler = (event) => {
    this.setState({ street: event.target.value });
  };

  changeNumberHandler = (event) => {
    this.setState({ number: event.target.value });
  };

  changeCompanyNameHandler = (event) => {
    this.setState({ companyName: event.target.value });
  };

  changePostalCodeHandler = (event) => {
    this.setState({ postalCode: event.target.value });
  };

  changeCityHandler = (event) => {
    this.setState({ city: event.target.value });
  };

  changeEmailHandler = (event) => {
    this.setState({ email: event.target.value });
  };

  changeTaxNumberHandler = (event) => {
    this.setState({ taxNumber: event.target.value });
  };

  getFiles(files) {
    this.setState({ logo: files[0].base64 });
  }

  saveOrUpdate = (e) => {
    e.preventDefault();
    let company = {
      surname: this.state.surname,
      companyName: this.state.companyName,
      name: this.state.name,
      telefonNumber: this.state.telefonNumber,
      telefonNumber2: this.state.telefonNumber2,
      street: this.state.street,
      number: this.state.number,
      postalCode: this.state.postalCode,
      city: this.state.city,
      email: this.state.email,
      taxNumber: this.state.taxNumber,
      logo: this.state.logo,
    };

    console.log("Company => " + JSON.stringify(company));
    OrderService.updateCompany(company).then((res) => {
      window.location.reload();
    });
  };

  cancel = () => {
    this.props.history.push("/");
  };

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    const { t } = this.props;
    return (
      <div>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="card col-md-6 offset-md-3 offset-md-3">
              <div className="card-body">
                <div className="form-group">
                  <label> Firmenname: </label>
                  <input
                    placeholder="Firmenname"
                    name="surname"
                    className="form-control"
                    value={this.state.companyName}
                    onChange={this.changeCompanyNameHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Nachname: </label>
                  <input
                    placeholder="Nachname"
                    name="surname"
                    className="form-control"
                    value={this.state.surname}
                    onChange={this.changeSurnameHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Vorname: </label>
                  <input
                    placeholder="Vorname"
                    name="name"
                    className="form-control"
                    value={this.state.name}
                    onChange={this.changeNameHandler}
                  />
                </div>
                <div className="form-group">
                  <label> USt-IdNR: </label>
                  <input
                    placeholder="USt-IdNR"
                    name="name"
                    className="form-control"
                    value={this.state.taxNumber}
                    onChange={this.changeTaxNumberHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Telefonnummer: </label>
                  <input
                    placeholder="Telefonnummer"
                    name="telefonNumber"
                    className="form-control"
                    value={this.state.telefonNumber}
                    onChange={this.changeTelephoneNumberHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Telefonnummer 2: </label>
                  <input
                    placeholder="Telefonnummer 2"
                    name="telefonNumber2"
                    className="form-control"
                    value={this.state.telefonNumber2}
                    onChange={this.changeTelephoneNumber2Handler}
                  />
                </div>
                <div className="form-group">
                  <label> Strasse: </label>
                  <input
                    placeholder="Strasse"
                    name="street"
                    className="form-control"
                    value={this.state.street}
                    onChange={this.changeStreetHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Hausnummer: </label>
                  <input
                    placeholder="Hausnummer"
                    name="number"
                    className="form-control"
                    value={this.state.number}
                    onChange={this.changeNumberHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Postleitzahl: </label>
                  <input
                    placeholder="Postleitzahl"
                    name="postalCode"
                    className="form-control"
                    value={this.state.postalCode}
                    onChange={this.changePostalCodeHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Stadt: </label>
                  <input
                    placeholder="Stadt"
                    name="city"
                    className="form-control"
                    value={this.state.city}
                    onChange={this.changeCityHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Email: </label>
                  <input
                    placeholder="Email"
                    name="email"
                    className="form-control"
                    value={this.state.email}
                    onChange={this.changeEmailHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Logo hochladen </label>
                  <div className="form-group">
                    <FileBase64
                      multiple={true}
                      onDone={evt => this.getFiles(evt)}
                    />
                  </div>
                  <div className="text-center">
                  {this.state.logo && (
                      <div className="text-center">
                      <TransformWrapper>
                      <TransformComponent>
                        <img
                          className="img"
                          alt={this.state.name}
                          src={this.state.logo}
                        />
                        </TransformComponent>
                        </TransformWrapper>
                        <Base64Downloader
                          base64={this.state.logo}
                          downloadName={this.state.companyName}
                        >
                          Herunterladen
                        </Base64Downloader>
                      </div>
                    )}
                  </div>
                </div>
                <Icon
                  name="save"
                  tooltip="Speichern"
                  theme="light"
                  size="medium"
                  onClick={this.saveOrUpdate}
                />
                <Icon
                  name="undo"
                  tooltip="Zurück zu den Rechnungen"
                  theme="light"
                  size="medium"
                  onClick={this.cancel.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditCompanyComponent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(withRouter)(translate(EditCompanyComponent));
