import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL + "/api/v1/clients";

class ClientService {

    getClients() {
        return axios.get(API_BASE_URL, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    getClientsCount() {
        return axios.get(API_BASE_URL + '/all', {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    activate(id) {
        return axios.post(API_BASE_URL + '/activate/' + id, {}, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    deactivate(id) {
        return axios.post(API_BASE_URL + '/deactivate/' + id, {}, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    create(client) {
        return axios.post(API_BASE_URL, client, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    addAddress(a, id) {
        return axios.post(API_BASE_URL + "/addresses/add/"+id, a, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    createAll(clients) {
        return axios.post(API_BASE_URL + '/all', clients, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    getById(id) {
        return axios.get(API_BASE_URL + '/' + id, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    getBySearch(search) {
        return axios.get(API_BASE_URL + '/search/' + search, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    update(client, id) {
        return axios.put(API_BASE_URL + '/' + id, client, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    delete(id) {
        return axios.delete(API_BASE_URL + '/' + id, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }
}

export default new ClientService()