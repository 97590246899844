import React, { Component } from 'react';
import Sidenavigation from '../layouts/Sidenavigation';
import Topnavigation from '../layouts/Topnavigation';
import Quickbar from '../layouts/Quickbar';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import EditOrderComponent from '../EditOrderComponent';
const cookies = new Cookies();

class EditOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
          id: this.props.match.params.id,
        };
    }
    render() {
        if (cookies.get("token") !== 'b5c9667644da67241316db50cc788bfd078849de') {
            return <Redirect to="/login" />;
        }
        return (
            <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                <Sidenavigation />
                <main className="body-content">
                    <Topnavigation />
                    <EditOrderComponent id={this.state.id}/>
                </main>
                <Quickbar />
            </div>
        );
    }
}

export default EditOrder;