import React, { Component } from "react";
import Breadcrumb from "./Breadcrumb";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import $ from "jquery";
import ClientService from "../../../services/ClientService";
import ReactDOM from "react-dom";
import Icon from "react-crud-icons";
import { Redirect } from "react-router-dom";
import * as XLSX from "xlsx";
import Cookies from "universal-cookie";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { translate } from "react-switch-lang";

const cookies = new Cookies();

class Content extends Component {
  componentDidMount() {
    this.setClients();
  }

  setClients() {
    ClientService.getClients().then((res) => {
      const columns = [
        {
          title: "ID",
          data: "id",
        },
        {
          title: "Ansprechpartner",
          data: "contactPerson",
        },
        {
          title: "Vermietungsadressen",
          data: "id",
        },
        {
          title: "Bearbeiten",
          data: "id",
        },
      ];
      $("#data-table-4").DataTable({
        data: res.data,
        "bDestroy": true,
        columns: columns,
        language: {
          url: "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json",
        },
        columnDefs: [
          {
            targets: [3],
            createdCell: (td, cellData, rowData, row, col) => {
              ReactDOM.render(
                <div>
                  <Icon
                    name="edit"
                    tooltip="Kunde bearbeiten"
                    theme="light"
                    size="medium"
                    onClick={() => this.edit(rowData.id)}
                  />
                  <br></br>
                  <Icon
                    name="delete"
                    tooltip="Kunde löschen"
                    theme="light"
                    size="medium"
                    onClick={() => this.delete(rowData.id)}
                  />
                </div>,
                td
              );
            },
          },
          {
            targets: [2],
            createdCell: (td, cellData, rowData, row, col) => {
              ReactDOM.render(
                <div>
                  <div>
                    {rowData.addresses.map((d) => (
                      <div key={d.id}>
                        <span> {d.number} </span>
                        <span> {d.street} </span>
                        <span> {d.postalCode} , </span>
                        <span> {d.city} </span>
                      </div>
                    ))}
                  </div>
                  <div>
                    <Icon
                      name="add"
                      tooltip="Vermietungsadresse hinzufügen"
                      theme="light"
                      size="medium"
                      onClick={() => this.addAddress(rowData.id)}
                    />
                  </div>
                </div>,
                td
              );
            },
          },
          {
            targets: [0],
            createdCell: (td, cellData, rowData, row, col) => {
              ReactDOM.render(
                <div>
                  <div>
                    <span> {rowData.name} </span>
                    <br></br>
                    <span> {rowData.surname} </span>
                    <br></br>
                    <span> {rowData.telefonNumber} </span>
                    <br></br>
                    <span> {rowData.telefonNumber2} </span>
                    <br></br>
                    <span> {rowData.email} </span>
                    {rowData.active == false && (
                      <div>
                        Inaktiv
                        <Icon
                          name="check"
                          tooltip="Aktivieren"
                          theme="light"
                          size="medium"
                          onClick={() => this.activate(rowData.id)}
                        />
                      </div>
                    )}
                    {rowData.active == true && (
                      <div>
                        Aktiv
                        <Icon
                          name="hide"
                          tooltip="Deaktivieren"
                          theme="light"
                          size="medium"
                          onClick={() => this.deactivate(rowData.id)}
                        />
                      </div>
                    )}
                  </div>
                </div>,
                td
              );
            },
          },
          {
            targets: [1],
            createdCell: (td, cellData, rowData, row, col) => {
              ReactDOM.render(
                <div>
                  <div>
                    <span> {rowData.contactPerson} </span>
                    <br></br>
                    <span> {rowData.contactPerson2} </span>
                  </div>
                </div>,
                td
              );
            },
          },
        ],
      });
    });
  }

  activate(id) {
    ClientService.activate(id).then((res) => {
      window.location.reload();
    });
  }

  addAddress(id) {
    this.props.history.push("/add-address/" + id);
  }

  deactivate(id) {
    ClientService.deactivate(id).then((res) => {
      window.location.reload();
    });
  }

  edit(id) {
    this.props.history.push("/edit-customer/" + id);
  }

  delete(id) {
    ClientService.delete(id).then((res) => {
      this.setClients();
    });
  }

  prepareEditableOrder(data) {
    return <span>{data == true ? "JA" : "NEIN"}</span>;
  }

  onChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      var clients = [];
      let sheetData = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
        blankrows: false,
      });
      console.log(sheetData);
      for (var i = 1; i < sheetData.length; i++) {
        let c = {
          name: sheetData[i][0],
          surname: sheetData[i][1],
          telefonNumber: sheetData[i][2],
          telefonNumber2: sheetData[i][3],
          street: sheetData[i][4],
          number: sheetData[i][5],
          postalCode: sheetData[i][6],
          city: sheetData[i][7],
          country: sheetData[i][8],
          email: sheetData[i][9],
          company: sheetData[i][10],
          floor: sheetData[i][11],
        };
        console.log("Client => " + JSON.stringify(c));
        clients.push(c);
      }
      ClientService.createAll(clients).then((res1) => {
        ClientService.getClients().then((res) => {
          this.setState({ clients: res.data, filteredData: res.data });
        });
      });
    };
    reader.readAsBinaryString(file);
  };

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div style={{ marginLeft: "300px", marginBottom: "20px" }}>
            <p>Kunden importieren</p>
            <input type="file" onChange={this.onChange} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb />
            <div className="ms-panel">
              <div className="ms-panel-header">
                <h6>Kunden</h6>
              </div>
              <div className="ms-panel-body">
                <div className="table-responsive">
                  <table
                    id="data-table-4"
                    className="table w-100 thead-primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default compose(withRouter)(translate(Content));
