import React, { Component } from "react";
import "moment/locale/de";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import ReactDOM from "react-dom";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import SockJsClient from "react-stomp";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import $ from "jquery";
import OrderService from "../services/OrderService";
import ProducentService from "../services/ProducentService";
const cookies = new Cookies();

class TourComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      warning: false,
      error: false,
      deliveries: [],
      filteredData: [],
      searchInput: "",
      errorMessage: "",
      drivers: [],
      driverId: 0,
      origin: null,
      destination: null,
      ways: [],
      progress: [],
      audio: new Audio(
        "https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"
      ),
    };
  }

  getTelNumber(number) {
    return "tel:" + number;
  }

  checkDelivery(id) {
    OrderService.checkDelivery(id).then((res) => {
      OrderService.getDeliveriesByDriver().then((res2) => {
        this.setDataTable(res2.data);
      });
    });
  }

  assignCancellation(id) {
    ProducentService.assignCancellation(1, id).then((res) => {
      OrderService.getDeliveriesByDriver().then((res2) => {
        this.setState({ warning: true });
        setTimeout(() => {
          this.setState({
            warning: false,
          });
          this.setDataTable(res2.data);
        }, 5000);
      });
    });
  }

  sendMail(id) {
    OrderService.sendMail(id).then((res) => {
      OrderService.getDeliveriesByDriver().then((res2) => {
        if (res.data) {
          this.setState({ success: true });
          setTimeout(() => {
            this.setState({
              success: false,
            });
          }, 5000);
        } else {
          this.setState({ error: true });
          setTimeout(() => {
            this.setState({
              error: false,
            });
          }, 5000);
        }
        this.setDataTable(res2.data);
      });
    });
  }

  saveGeoData() {
    navigator.geolocation.getCurrentPosition(function (position) {
      OrderService.saveDriverGeoData(position.coords.latitude, position.coords.longitude).then((res) => {
        
      });
    });
  }

  componentDidMount() {
    this.state.audio.addEventListener("ended", () =>
      this.setState({ play: false })
    );
    this.updateInterval = setInterval(() => {
      this.saveGeoData();
    }, 1000);
    OrderService.getDeliveriesByDriver().then((res) => {
      this.setDataTable(res.data);
      this.setState({ deliveries: res.data });
    });
  }

  setDataTable(data) {
    const columns = [
      {
        title: "Adresse",
        data: "created",
      },
      {
        title: "Kunde",
        data: "created",
      },
      {
        title: "Betrag",
        data: "created",
      },
      {
        title: "Operationen",
        data: "created",
      },
    ];
    $("#data-table-5").DataTable({
      data: data,
      columns: columns,
      autoWidth: false,
      paging: false,
      searching: false,
      destroy: true,
      language: {
        url: "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json",
      },
      columnDefs: [
        {
          targets: [0],
          width: 450,
          createdCell: (td, cellData, rowData, row, col) => {
            ReactDOM.render(
              <div>
                {rowData.item.client.street} {rowData.item.client.number}
                <br></br>
                {rowData.item.client.postalCode} {rowData.item.client.city}
              </div>,
              td
            );
          },
        },
        {
          targets: [1],
          width: 450,
          createdCell: (td, cellData, rowData, row, col) => {
            ReactDOM.render(
              <div>
                {rowData.item.client.name} {rowData.item.client.surname}
                <br></br>
                {rowData.item.itemNotes &&
                  rowData.item.itemNotes.filter(
                    (one) => one.itemNoteType == "DRIVER"
                  )[0] !== undefined && (
                    <div>
                      Info Fahrer:
                      <br></br>{" "}
                      {
                        rowData.item.itemNotes.filter(
                          (one) => one.itemNoteType == "DRIVER"
                        )[0].name
                      }
                    </div>
                  )}
                {rowData.item.itemNotes &&
                  rowData.item.itemNotes.filter(
                    (one) => one.itemNoteType == "GENERAL"
                  )[0] !== undefined && (
                    <div>
                      Info Allgemein:
                      <br></br>{" "}
                      {
                        rowData.item.itemNotes.filter(
                          (one) => one.itemNoteType == "GENERAL"
                        )[0].name
                      }
                    </div>
                  )}
              </div>,
              td
            );
          },
        },
        {
          targets: [2],
          width: 450,
          createdCell: (td, cellData, rowData, row, col) => {
            ReactDOM.render(
              <div>
                {rowData.item.amount} {rowData.item.paymentData[0].name}
              </div>,
              td
            );
          },
        },
        {
          targets: [3],
          width: 450,
          createdCell: (td, cellData, rowData, row, col) => {
            ReactDOM.render(
              <div>
                <button
                  onClick={() => this.checkDelivery(rowData.id)}
                  type="button"
                  className="btn btn-sm btn-success"
                >
                  Zustellung bestätigen
                </button>
                <br></br>
                <button
                  type="button"
                  className="btn btn-sm btn-info"
                  onClick={() =>
                    window.open(
                      this.getTelNumber(rowData.item.client.telefonNumber),
                      "_blank"
                    )
                  }
                >
                  Kunde anrufen
                </button>
                <br></br>
                <button
                  type="button"
                  className="btn btn-sm btn-info"
                  onClick={() =>
                    window.open(
                      this.getTelNumber(rowData.item.producent.telefonNumber),
                      "_blank"
                    )
                  }
                >
                  Laden anrufen
                </button>
                <br></br>
                <button
                  onClick={() => this.assignCancellation(rowData.item.id)}
                  type="button"
                  className="btn btn-sm btn-info"
                >
                  Kunde nicht erreichbar
                </button>
                <br></br>
                <button
                  type="button"
                  onClick={() => this.sendMail(rowData.item.id)}
                  className="btn btn-sm btn-info"
                >
                  Rechnung an Kunde senden
                </button>
              </div>,
              td
            );
          },
        },
      ],
    });
  }

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <br />
        <SockJsClient
          url={process.env.REACT_APP_API_URL + "/bms-gastronomy/deliveries/"}
          topics={["/topic/deliveries"]}
          onConnect={() => {
            console.log("connected");
          }}
          onDisconnect={() => {
            console.log("Disconnected");
          }}
          onMessage={(msg) => {
            console.log("Delivery received");
            var itemsA = [];
            this.state.deliveries.forEach((m) => {
              itemsA.push(m);
            });
            const isFound = itemsA.some((element) => {
              if (element.id === msg.id) {
                return true;
              }
              return false;
            });
            if (isFound) {
              itemsA = itemsA.filter((item) => item.id !== msg.id);
            } else {
              this.state.audio.play();
            }
            itemsA.push(msg);
            itemsA.sort((a, b) => (a.id < b.id ? 1 : -1));
            this.setState({ deliveries: itemsA });
            this.setDataTable(itemsA);
          }}
          ref={(client) => {
            this.clientRef = client;
          }}
        />
        <br />
        <div className="row">
          <div className="col">
            {this.state.success && (
              <Stack sx={{ width: "338px" }} spacing={2}>
                <Alert severity="success">
                  Rechnung als E-Mail erfolgreich geschickt!
                </Alert>
              </Stack>
            )}
            {this.state.error && (
              <Stack sx={{ width: "338px" }} spacing={2}>
                <Alert severity="error">
                  Fehler aufgetreten, E-Mail nicht hinterlegt!
                </Alert>
              </Stack>
            )}
            {this.state.warning && (
              <Stack sx={{ width: "338px" }} spacing={2}>
                <Alert severity="warning">Lieferung storniert!</Alert>
              </Stack>
            )}
            <div className="table-responsive">
              <table
                id="data-table-5"
                className="table w-100 thead-primary"
              ></table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TourComponent;
