import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL+"/api/v1/products";

class ProductsService {

    getProducts(){
        return axios.get(API_BASE_URL, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de',
            }
        });
    }

    getToppings() {
        return axios.get(API_BASE_URL+'/toppings', {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    create(article){
        return axios.post(API_BASE_URL+ '/', article, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    getById(id){
        return axios.get(API_BASE_URL + '/' + id, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    update(article, id){
        return axios.put(API_BASE_URL + '/' + id, article, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    updateGroup(id, checked){
        return axios.put(API_BASE_URL + '/productgroups/' + id+'/'+checked, {}, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    updateProduct(id, checked){
        return axios.put(API_BASE_URL + '/products/' + id+'/'+checked, {}, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    updateArticleGroup(id, checked){
        return axios.put(API_BASE_URL + '/articlegroups/' + id+'/'+checked, {}, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    updateSubArticle(id, checked){
        return axios.put(API_BASE_URL + '/subarticles/' + id+'/'+checked, {}, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    delete(id){
        return axios.delete(API_BASE_URL + '/' + id, {}, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }
    
}

export default new ProductsService()