import React, { Component } from "react";
import Breadcrumb from "./Breadcrumb";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import $ from "jquery";
import { translate } from "react-switch-lang";
import PropTypes from "prop-types";
import ArticleService from "../../../services/ArticleService";
import { Link, Redirect, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import ReactDOM from "react-dom";
import Cookies from "universal-cookie";
import Icon from "react-crud-icons";
import * as XLSX from "xlsx";
import ProducentService from "../../../services/ProducentService";
const cookies = new Cookies();

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var self = this;
    Promise.all([ArticleService.getAllArticleAmounts()])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response;
          })
        );
      })
      .then(function (data) {
        const { t } = self.props;
        const columns = [
          {
            title: "Name",
            data: "name",
            render: function (data, type, row) {
              return `<div style="max-width: 200px; white-space: normal; overflow: hidden; text-overflow: ellipsis;">${data}</div>`;
            }
          },
          {
            title: "Lieferant",
            data: "supplier.name",
          },
          {
            title: "Nummer",
            data: "nr",
          },
          {
            title: "Rohmenge",
            data: "quantity",
          },
          {
            title: "Reinmenge",
            data: "quantityPure",
          },
          {
            title: "Lieferantennummer",
            data: "externNr",
          },
          {
            title: "Verpackungsform",
            data: "pack",
          },
          {
            title: "Verfügbar",
            data: "amount",
          },
          {
            title: "Einheit",
            data: "shoppingArticleUnit",
          },
          {
            title: "Operationen",
            data: null,
          },
        ];
        $("#data-table-1").DataTable({
          data: data[0].data,
          columns: columns,
          language: {
            url: "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json",
          },
          columnDefs: [
            {
              render: function (data, type, row) {
                return data ? t(data.toLowerCase()) : "";
              },
              targets: [6],
            },
            {
              targets: [9],
              createdCell: (td, cellData, rowData, row, col) => {
                ReactDOM.render(
                  <div>
                    <Icon
                      name="edit"
                      tooltip="Einkaufsartikel bearbeiten"
                      theme="light"
                      size="medium"
                      onClick={() =>
                        self.props.history.push(
                          "/edit-shopping-item/" + rowData.id
                        )
                      }
                    />
                  </div>,
                  td
                );
              },
            },
          ],
        });
      });
  }

  onChangeData = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let sheetData = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
        blankrows: false,
      });
      for (var i = 1; i < sheetData.length; i++) {
        let one = {
          sub: sheetData[i][1],
          name: sheetData[i][4],
          quantityPure: sheetData[i][7],
          unit: sheetData[i][6],
          amount: sheetData[i][5],
          packUnit: sheetData[i][8],
          taxFee: sheetData[i][9],
          price: sheetData[i][10],
          sup: sheetData[i][11],
        };
        ProducentService.saveShoppingArticleData(one).then((res2) => {});
      }
    };
    reader.readAsBinaryString(file);
  };

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb />
            <div className="ms-panel">
              <div className="ms-panel-header">
                <h6>
                  Einkaufsartikel <br></br>
                  <Link
                    style={{ fontSize: "25px", color: "black" }}
                    title="Neues Einkaufsartikel anlegen"
                    to={"/add-shopping-item"}
                  >
                    +
                  </Link>
                </h6>
              </div>
              <div className="ms-panel-body">
                <div className="table-responsive">
                  <table
                    id="data-table-1"
                    className="table w-100 thead-primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Content.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(withRouter)(translate(Content));
