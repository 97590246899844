import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL + "/api/v1/bills";

class BillService {

    getBills() {
        return axios.get(API_BASE_URL, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    getStats() {
        return axios.get(API_BASE_URL + "/stats", {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    create(bill, id) {
        return axios.post(API_BASE_URL + '/' + id, bill, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    export(company, start, end) {
        axios(API_BASE_URL + '/producents/' + company  + '/' + start + '/' + end, {
            method: 'GET',
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            },
            responseType: 'blob' //Force to receive data in a Blob Format
        })
            .then(response => {
                const filename =  'export_'+Date.now()+'.zip';
        let url = window.URL.createObjectURL(response.data);
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        window.location.reload();
            })
            .catch(error => {
                console.log(error);
            });
    }

    getById(id) {
        return axios.get(API_BASE_URL + '/' + id, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    update(bill, id, paymentMode) {
        return axios.put(API_BASE_URL + '/' + id + '/' + paymentMode, bill, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }

    delete(id) {
        return axios.delete(API_BASE_URL + '/' + id, {}, {
            headers: {
                'X-API-KEY': 'b5c9667644da67241316db50cc788bfd078849de'
            }
        });
    }
}

export default new BillService()