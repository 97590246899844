import React, { Component } from "react";
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import ClientService from "../services/ClientService";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { translate } from "react-switch-lang";
import update from "immutability-helper";
import Cookies from "universal-cookie";
import DatePicker from "react-datepicker";
import OrderService from "../services/OrderService";
import PropTypes from "prop-types";
import ArticleService from "../services/ArticleService";
import UserService from "../services/UserService";
const cookies = new Cookies();

class AddWorksheetComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      clientid: 0,
      addressid: 0,
      clients: [],
      workers: [],
      contractTerm: 0,
      addresses: [],
      start: new Date(),
      end: null,
      executionPeriods: [],
      footers: [],
      selectedFooters: [],
      templates: [],
      selectedTemplates: [],
      executions: [],
      title: "",
      workerid: 0,
      billing: "",
      includeEndDate: false,
    };
    this.changeNameHandler = this.changeNameHandler.bind(this);
    this.saveOrUpdate = this.saveOrUpdate.bind(this);
  }

  saveOrUpdate = (e) => {
    e.preventDefault();
    let c = {
      timeFrom: this.state.start,
      timeTo: this.state.end,
      title: this.state.title,
    };
    console.log("Workersheet => " + JSON.stringify(c));
    OrderService.createWorkSheet(
      this.state.clientid,
      this.state.addressid,
      this.state.workerid,
      this.state.selectedTemplates.length > 0
        ? this.state.selectedTemplates
        : 0,
      c
    ).then((res) => {
      this.props.history.push("/worker-sheets");
    });
  };

  changeNameHandler = (event) => {
    this.setState({ name: event.target.value });
  };

  changeClientHandler = (event) => {
    var cId = event.target.value;
    var addies = this.state.clients.filter((one) => one.id == cId)[0].addresses;
    this.setState({
      clientid: cId,
      addresses: addies,
    });
  };

  changeExecutionHandler = (event) => {
    this.setState({ executionPeriod: event.target.value });
  };

  changeDescriptionHandler = (event, id) => {
    var desc = event.target.value;
    var element = this.state.executions.filter((one) => one.id == id)[0];
    var updated = update(element, { description: { $set: desc } });
    var newData = update(this.state.executions, {
      $splice: [[this.state.executions.indexOf(element), 1, updated]],
    });
    this.setState({ executions: newData });
  };

  changeAddressHandler = (event) => {
    this.setState({ addressid: event.target.value });
  };

  cancel() {
    this.props.history.push("/worker-sheets");
  }

  changeDateStartHandler = (event) => {
    this.setState({ start: event });
  };

  changeDateEndHandler = (event) => {
    this.setState({ end: event });
  };

  componentDidMount() {
    UserService.getAllWorkers().then((res0) => {
      ClientService.getClients().then((res) => {
        OrderService.getTemplates().then((res4) => {
          this.setState({
            workers: res0.data,
            clients: res.data,
            templates: res4.data,
            addresses: res.data[0].addresses,
            clientid: res.data[0].id,
            addressid: res.data[0].addresses[0].id,
            workerid: res.data[0].id,
          });
        });
      });
    });
  }

  changeDescriptionNameHandler = (event) => {
    var n = event.target.value;
    this.setState({
      title: n,
    });
  };

  changeTemplateHandler = (event) => {
    const selectedOptions = event.target.selectedOptions;
    const selectedTemplates = Array.from(selectedOptions).map(
      (option) => option.value
    );
    this.setState({
      selectedTemplates: selectedTemplates,
    });
  };

  render() {
    const { t } = this.props;
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="card col-md-6 offset-md-3 offset-md-3">
              <div className="card-body">
                <div className="form-group  has-success">
                  <label> Leistung </label>
                  <input
                    placeholder="Leistung"
                    name="description"
                    className="form-control"
                    value={this.state.title}
                    onChange={this.changeDescriptionNameHandler}
                  />
                </div>
                <div className="form-group  has-success">
                  <label>Mitarbeiter auswählen:</label>
                  <select
                    className={"custom-select"}
                    onChange={(e) =>
                      this.setState({ workerid: e.target.value })
                    }
                  >
                    <option value="">Mitarbeiter auswählen</option>
                    {this.state.workers &&
                      this.state.workers.map((worker) => (
                        <option key={worker.id} value={worker.id}>
                          {worker.name} {worker.surname}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group  has-success">
                  <label> Kunde: (*) </label>
                  <select
                    className={"custom-select"}
                    onChange={(evt) => this.changeClientHandler(evt)}
                  >
                    {this.state.clients.map((e, key) => {
                      return (
                        <option key={key} value={e.id}>
                          {e.name} {e.surname}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group  has-success">
                  <label> Adresse: (*) </label>
                  <select
                    className={"custom-select"}
                    onChange={(evt) => this.changeAddressHandler(evt)}
                  >
                    {this.state.addresses &&
                      this.state.addresses.map((e, key) => {
                        return (
                          <option key={key} value={e.id}>
                            {e.street} {e.number} {e.postalCode} {e.city}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="form-group  has-success">
                  <label> Zeitraum (*): </label>
                  <ul style={{ display: "flex" }}>
                    <li>
                      <DatePicker
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        locale="de"
                        selected={this.state.start}
                        value={this.state.start}
                        onChange={(date) => this.changeDateStartHandler(date)}
                      />
                    </li>
                    <li style={{ marginLeft: "5px", marginTop: "6px" }}>
                      <input
                        type="checkbox"
                        checked={this.state.includeEndDate}
                        onChange={() =>
                          this.setState({
                            end: !this.state.includeEndDate?new Date():null,
                            includeEndDate: !this.state.includeEndDate,
                          })
                        }
                      />
                      <label>Enddatum</label>
                    </li>
                    {this.state.includeEndDate && (
                      <li style={{ marginLeft: "5px", marginTop: "6px" }}>
                        <label> Bis: </label>
                      </li>
                    )}
                    {this.state.includeEndDate && (
                      <li style={{ marginLeft: "5px" }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          locale="de"
                          selected={this.state.end}
                          value={this.state.end}
                          onChange={(date) => this.changeDateEndHandler(date)}
                        />
                      </li>
                    )}
                  </ul>
                </div>
                <div className="form-group has-success">
                  <label>Textvorlagen</label>
                  <select
                    multiple
                    placeholder="Textvorlagen"
                    name="billing"
                    className="form-control"
                    value={this.state.selectedTemplates}
                    onChange={this.changeTemplateHandler}
                  >
                    {this.state.templates.map((e, key) => {
                      return (
                        <option key={key} value={e.id} title={e.name}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <Icon
                  name="save"
                  tooltip="Speichern"
                  theme="light"
                  size="medium"
                  onClick={this.saveOrUpdate}
                />
                <Icon
                  name="undo"
                  tooltip="Zurück zu den Arbeitsscheinen"
                  theme="light"
                  size="medium"
                  onClick={this.cancel.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
AddWorksheetComponent.propTypes = {
  t: PropTypes.func.isRequired,
};
export default compose(withRouter)(translate(AddWorksheetComponent));
