import React, { Component } from "react";
import Breadcrumb from "./Breadcrumb";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import Icon from "react-crud-icons";
import $ from "jquery";
import ReactDOM from "react-dom";
import { translate } from "react-switch-lang";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import UserService from "../../../services/UserService";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
const cookies = new Cookies();

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
    };
  }
  componentDidMount() {
    var self = this;
    UserService.getAllWorkers().then((res) => {
      const { t } = this.props;
      const columns = [
        {
          title: "Name/Nachname & Adresse",
          data: null, // Combining multiple fields
          render: function (data, type, row) {
            return `
                            ${row.name} ${row.surname}<br>
                            ${row.street} ${row.number}, ${row.postalCode} ${
              row.city
            }<br>
                            ${row.country}<br>
                            📞 ${row.telefonNumber}<br>
                            📞 ${row.telefonNumber2 || "-"}
                        `;
          },
        },
        {
          title: "Steuernummer",
          data: "taxNumber",
        },
        {
          title: "RV-Nummer",
          data: "retirementNumber",
        },
        {
          title: "KV-Nummer",
          data: "insuranceNumber",
        },
        {
          title: "IBAN",
          data: "iban",
        },
        {
          title: "Ausweis",
          data: "identificationPicture",
          render: function (data, type, row) {
            return `<img src="${data}" style="width: 50px; height: auto;" />`;
          },
        },

        {
          title: "Bearbeiten/Löschen",
          data: null,
        },
      ];

      $("#data-table-1").DataTable({
        data: res.data,
        columns: columns,
        columnDefs: [
          {
            targets: [6],
            createdCell: (td, cellData, rowData, row, col) => {
              ReactDOM.render(
                <div>
                  <Icon
                    name="edit"
                    tooltip="Mitarbeiter bearbeiten"
                    theme="light"
                    size="medium"
                    onClick={() =>
                      self.props.history.push("/edit-worker/" + rowData.id)
                    }
                  />
                  <Icon
                    name="delete"
                    tooltip="Mitarbeiter löschen"
                    theme="light"
                    size="medium"
                    onClick={() => {
                      UserService.removeWorker(rowData.id)
                        .then((response) => {
                          if (response.data === false) {
                            // Display an error message on the page
                            this.setState({
                              errorMessage:
                                "Der Mitarbeiter hat bereits Daten und kann nicht gelöscht werden.",
                            });
                          } else {
                            window.location.reload();
                          }
                        })
                        .catch((error) => {
                          console.error(
                            "Fehler beim Löschen des Mitarbeiters:",
                            error
                          );
                          this.setState({
                            errorMessage:
                              "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.",
                          });
                        });
                    }}
                  />
                </div>,
                td
              );
            },
          },
        ],
        language: {
          url: "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json",
        },
      });
    });
  }

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb />
            <div className="ms-panel">
              <div className="ms-panel-header">
                <h6>Mitarbeiter</h6>
              </div>
              <div className="ms-panel-body">
                {this.state.errorMessage && (
                  <div className="alert alert-danger">
                    {this.state.errorMessage}
                  </div>
                )}
                <div className="table-responsive">
                  <table
                    id="data-table-1"
                    className="table w-100 thead-primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Content.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(withRouter)(Content);
