import React, { Component } from "react";
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { translate } from "react-switch-lang";
import Cookies from "universal-cookie";
import Moment from "moment";
import UserService from "../services/UserService";
const cookies = new Cookies();

class MailBoxComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mails: [],
    };
  }

  componentDidMount() {
    UserService.getMails().then((res) => {
      this.setState({ mails: res.data });
    });
  }

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <div className="row">
          {
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Absender</th>
                  <th>Betreff</th>
                  <th>Empfangen am</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {this.state.mails.map((d) => (
                  <tr key={d.id}>
                    <td style={{ width: "10%" }}> {d.from} </td>
                    <td style={{ width: "10%" }}> {d.subject} </td>
                    <td style={{ width: "10%" }}>
                      {" "}
                      {Moment(d.sentDate).format("D/M/YYYY HH:mm")}{" "}
                    </td>
                    <td style={{ width: "60%" }}><div className="content" dangerouslySetInnerHTML={{__html: d.content}}></div></td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </div>
      </div>
    );
  }
}
export default compose(withRouter)(translate(MailBoxComponent));
