import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const API_BASE_URL = process.env.REACT_APP_API_URL + "/api/v1/items/positions";

class OrderPositionService {
  getPositionNoteTypes() {
    return axios.get(API_BASE_URL + "/notetypes/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getReadyPositions() {
    return axios.get(API_BASE_URL + "/pendings/ready", {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      });
  }

  getPendingPositions(def) {
    var user;
    if (def ===0) {
        user = "NONE"
    } else {
        user = cookies.get('user')
    }
    return axios.get(API_BASE_URL + "/pendings/"+user, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  reArrange(ids) {
    return axios.post(API_BASE_URL + "/pendings/prios/", ids, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  setStatusFinish(id) {
    return axios.post(
      API_BASE_URL + "/pendings/statusFinish/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  setStatusReady(id) {
    return axios.post(
      API_BASE_URL + "/pendings/statusReady/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  setStatusDelayed(id) {
    return axios.post(
      API_BASE_URL + "/pendings/statusDelayed/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  setStatusProgressing(id) {
    return axios.post(
      API_BASE_URL + "/pendings/statusProgressing/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  setStatus(id) {
    return axios.post(
      API_BASE_URL + "/pendings/status/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  create(position, orderId, articleId, bId) {
    return axios.post(
      API_BASE_URL + "/" + orderId + "/" + articleId + "/" + bId,
      position,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  getById(id) {
    return axios.get(API_BASE_URL + "/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  update(position, orderId, articleId) {
    return axios.put(API_BASE_URL + "/" + orderId + "/" + articleId, position, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  delete(id) {
    return axios.delete(API_BASE_URL + "/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getPdf(id) {
    axios(API_BASE_URL + "/pdf/" + id, {
      method: "GET",
      responseType: "blob",
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getCSV(id) {
    axios(API_BASE_URL + "/csv/" + id, {
      method: "GET",
      responseType: "blob",
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "text/csv" }); //Build a URL from the file
        const fileURL = URL.createObjectURL(file); //Open the URL on new Window
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getBill(id) {
    axios(API_BASE_URL + "/bill/" + id, {
      method: "GET",
      responseType: "blob",
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" }); //Build a URL from the file
        const fileURL = URL.createObjectURL(file); //Open the URL on new Window
        window.location.reload();
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getBill2(id) {
    axios(API_BASE_URL + "/bill2/" + id, {
      method: "GET",
      responseType: "blob",
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" }); //Build a URL from the file
        const fileURL = URL.createObjectURL(file); //Open the URL on new Window
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export default new OrderPositionService();
