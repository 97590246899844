import React, { Component } from "react";
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import FileBase64 from "react-file-base64";
import Countries from "react-select-country";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { translate } from "react-switch-lang";
import Cookies from "universal-cookie";
import UserService from "../services/UserService";
import IBAN from "iban";
const cookies = new Cookies();

class AddDriverComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      isIbanValid: true,
      telefonNumber: "",
      surname: "",
      birthday: "",
      taxNumber: "",
      retirementNumber: "",
      insuranceNumber: "",
      iban: "",
      telefonNumber2: "",
      postalCode: "",
      number: "",
      street: "",
      city: "",
      country: "",
      countries: [],
      files: [],
    };
    this.changeNameHandler = this.changeNameHandler.bind(this);
    this.changePostalCodeHandler = this.changePostalCodeHandler.bind(this);
    this.changeNumberHandler = this.changeNumberHandler.bind(this);
    this.changeTelefonNumberHandler =
      this.changeTelefonNumberHandler.bind(this);
    this.changeStreetHandler = this.changeStreetHandler.bind(this);
    this.changeCityHandler = this.changeCityHandler.bind(this);
    this.changeCountryHandler = this.changeCountryHandler.bind(this);
    this.saveOrUpdate = this.saveOrUpdate.bind(this);
  }

  changePostalCodeHandler = (event) => {
    this.setState({ postalCode: event.target.value });
  };

  changeStreetHandler = (event) => {
    this.setState({ street: event.target.value });
  };

  changeCityHandler = (event) => {
    this.setState({ city: event.target.value });
  };

  changeCountryHandler = (event) => {
    this.setState({
      country: event.target.options[event.target.selectedIndex].text,
    });
  };

  changeNumberHandler = (event) => {
    this.setState({ number: event.target.value });
  };

  changeSurnameHandler = (event) => {
    this.setState({ surname: event.target.value });
  };

  changeBirthdayHandler = (event) => {
    this.setState({ birthday: event.target.value });
  };

  changeTaxNumberHandler = (event) => {
    this.setState({ taxNumber: event.target.value });
  };

  changeRetirementNumberHandler = (event) => {
    this.setState({ retirementNumber: event.target.value });
  };

  changeInsuranceNumberHandler = (event) => {
    this.setState({ insuranceNumber: event.target.value });
  };

  changeIbanHandler = (event) => {
    const newIban = event.target.value;
    this.setState({
      iban: newIban,
      isIbanValid: IBAN.isValid(newIban),
    });
  };

  changeTelefonNumber2Handler = (event) => {
    this.setState({ telefonNumber2: event.target.value });
  };

  saveOrUpdate = (e) => {
    e.preventDefault();
    let d = {
      name: this.state.name,
      number: this.state.number,
      street: this.state.street,
      postalCode: this.state.postalCode,
      city: this.state.city,
      country: this.state.country,
      telefonNumber: this.state.telefonNumber,
      identificationPicture: this.state.files[0].base64,
      surname: this.state.surname,
      birthday: this.state.birthday,
      taxNumber: this.state.taxNumber,
      retirementNumber: this.state.retirementNumber,
      insuranceNumber: this.state.insuranceNumber,
      iban: this.state.iban,
      telefonNumber2: this.state.telefonNumber2,
    };
    console.log("Worker => " + JSON.stringify(d));
    UserService.createWorker(d).then((res) => {
      this.props.history.push("/workers/");
    });
  };

  changeNameHandler = (event) => {
    this.setState({ name: event.target.value });
  };

  changeTelefonNumberHandler = (event) => {
    this.setState({ telefonNumber: event.target.value });
  };

  cancel() {
    this.props.history.push("/driver-list/");
  }

  getFiles(files) {
    this.setState({ files: files });
  }

  componentDidMount() {}

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="card col-md-6 offset-md-3 offset-md-3">
              <div className="card-body">
                <div className="form-group">
                  <label> Vorname: </label>
                  <input
                    placeholder="Name"
                    name="Name"
                    className="form-control"
                    value={this.state.name}
                    onChange={this.changeNameHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Nachname: </label>
                  <input
                    placeholder="Nachname"
                    name="Nachname"
                    className="form-control"
                    value={this.state.surname}
                    onChange={this.changeSurnameHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Geburtstag: </label>
                  <input
                    type="date"
                    placeholder="Geburtstag"
                    name="Geburtstag"
                    className="form-control"
                    value={this.state.birthday}
                    onChange={this.changeBirthdayHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Steuernummer: </label>
                  <input
                    placeholder="Steuernummer"
                    name="Steuernummer"
                    className="form-control"
                    value={this.state.taxNumber}
                    onChange={this.changeTaxNumberHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Rentenversicherungsnummer: </label>
                  <input
                    placeholder="Rentenversicherungsnummer"
                    name="Rentenversicherungsnummer"
                    className="form-control"
                    value={this.state.retirementNumber}
                    onChange={this.changeRetirementNumberHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Krankenversicherungsnummer: </label>
                  <input
                    placeholder="Krankenversicherungsnummer"
                    name="Krankenversicherungsnummer"
                    className="form-control"
                    value={this.state.insuranceNumber}
                    onChange={this.changeInsuranceNumberHandler}
                  />
                </div>
                <div className="form-group">
                  <label> IBAN: </label>
                  <input
                    placeholder="IBAN"
                    name="IBAN"
                    className="form-control"
                    value={this.state.iban}
                    onChange={this.changeIbanHandler}
                  />
                  {!this.state.isIbanValid && (
                    <div style={{color:'tomato'}}>
                      IBAN nicht valide. Bitte prüfen!
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label> Telefonnummer: </label>
                  <input
                    placeholder="Telefonnummer"
                    name="Telefonnummer"
                    className="form-control"
                    value={this.state.telefonNumber}
                    onChange={this.changeTelefonNumberHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Telefonnummer 2: </label>
                  <input
                    placeholder="Telefonnummer 2"
                    name="Telefonnummer2"
                    className="form-control"
                    value={this.state.telefonNumber2}
                    onChange={this.changeTelefonNumber2Handler}
                  />
                </div>
                <div className="form-group">
                  <label> Straße: </label>
                  <input
                    placeholder="Straße"
                    name="Straße"
                    className="form-control"
                    value={this.state.street}
                    onChange={this.changeStreetHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Hausnummer: </label>
                  <input
                    placeholder="Hausnummer"
                    name="Hausnummer"
                    className="form-control"
                    value={this.state.number}
                    onChange={this.changeNumberHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Postleitzahl: </label>
                  <input
                    placeholder="Postleitzahl"
                    name="Postleitzahl"
                    className="form-control"
                    value={this.state.postalCode}
                    onChange={this.changePostalCodeHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Stadt: </label>
                  <input
                    placeholder="Stadt"
                    name="Stadt"
                    className="form-control"
                    value={this.state.city}
                    onChange={this.changeCityHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Land: </label>
                  <Countries
                    className="form-control"
                    ref="cList"
                    name="country"
                    empty=" -- Land auswählen --"
                    onChange={this.changeCountryHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Ausweisfoto hochladen </label>
                  <div className="form-group">
                    <FileBase64
                      multiple={true}
                      onDone={this.getFiles.bind(this)}
                    />
                  </div>
                  <div className="text-center">
                    {this.state.files.length <= 2 &&
                      this.state.files.map((file, i) => {
                        return (
                          <div style={{ marginBottom: "10px" }}>
                            <img
                              width={"100px"}
                              height={"100px"}
                              alt={file.name}
                              key={i}
                              src={file.base64}
                            />
                          </div>
                        );
                      })}
                    <img src="" alt="" />
                  </div>
                  <Icon
                    name="save"
                    tooltip="Speichern"
                    theme="light"
                    size="medium"
                    onClick={this.saveOrUpdate}
                  />
                  <Icon
                    name="undo"
                    tooltip="Zurück zu den Fahrern"
                    theme="light"
                    size="medium"
                    onClick={this.cancel.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default compose(withRouter)(translate(AddDriverComponent));
