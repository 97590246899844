import React, { Component } from "react";
import Moment from "moment";
import "moment/locale/de";
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import OrderService from "../services/OrderService";
import StatusIndicator from "react-status-indicator";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import MapComponent from "./MapComponent";
import { Marker, InfoWindow } from "react-google-maps";
const cookies = new Cookies();

class DeliveryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveries: [],
      filteredData: [],
      searchInput: "",
      errorMessage: "",
      drivers: [],
      driverId: 0,
      origin: null,
      destination: null,
      ways: [],
      progress: [],
      markers: [],
    };
    this.edit = this.edit.bind(this);
    this.cancelDelivery = this.cancelDelivery.bind(this);
    this.export = this.export.bind(this);
    this.finish = this.finish.bind(this);
  }

  cancelDelivery(id) {
    OrderService.cancelDelivery(id).then((res1) => {
      OrderService.getDrivers(0).then((res) => {
        OrderService.getDeliveries(this.state.driverId).then((res2) => {
          this.setState({
            drivers: res.data,
            driverId: res.data[0].id,
            deliveries: res2.data,
            filteredData: res2.data,
          });
          this.updateMap();
        });
      });
    });
  }

  edit() {
    this.props.history.push(`/check-driver/`);
  }

  finish() {
    this.props.history.push(`/uncheck-driver/`);
  }

  export(id) {
    this.props.history.push(`/generate-bills/${id}`);
  }

  componentDidMount() {
    OrderService.getDrivers(0).then((res) => {
      OrderService.getDeliveries(res.data[0].id).then((res2) => {
        this.setState({
          drivers: res.data,
          driverId: res.data[0].id,
          deliveries: res2.data,
          filteredData: res2.data,
        });
        this.updateMap();
      });
    });
  }

  updateMap() {
    this.updateInterval = setInterval(() => {
      OrderService.getDriverGeoData(this.state.driverId).then((res2) => {
        var markersA = this.state.markers.slice(0, -1);
        var m = res2.data;
        markersA.push({
          id: m.id,
          name: m.name,
          color: "blue",
          position: { lat: m.lat, lng: m.lng },
        });
        this.setState({
          markers: markersA,
        });
      });
    }, 1000);
    const markersA = [];
    this.state.deliveries.forEach((m) => {
      if (m.item.client != null && m.item.client.lat && m.item.client.lng) {
        markersA.push({
          id: m.item.id,
          name: m.item.bill.number,
          color:
            m.status == "CANCELLED"
              ? "gray"
              : (m.status == "DELIVERED" || m.status == "PICKED_UP")
              ? "green"
              : m.status == "DRIVING"
              ? "orange"
              : m.status == "DELAYED"
              ? "red"
              : "blue",
          position: { lat: m.item.client.lat, lng: m.item.client.lng },
        });
      }
    });
    var driver = this.state.deliveries[0].driver;
    markersA.push({
      id: driver.id,
      name: driver.name,
      color: "blue",
      position: { lat: driver.lat, lng: driver.lng },
    });
    this.setState({ markers: markersA, driver: driver });
  }

  changeDriverHandler(event) {
    var selectedDriverId = event.target.value;
    OrderService.getDeliveries(selectedDriverId).then((res2) => {
      this.setState({
        driverId: selectedDriverId,
        deliveries: res2.data,
        filteredData: res2.data,
      });
      this.updateMap();
    });
  }

  arrivalTime(i, created, duration) {
    var previous = this.state.deliveries[i - 1];
    var durationToAdd = previous != null ? previous.duration / 60 + 5 : 0;
    created.setHours(
      created.getHours(),
      created.getMinutes() + duration / 60 + durationToAdd,
      0,
      0
    );
    return duration > 0 ? Moment(created).format("D/M/YYYY HH:mm") : "---";
  }

  cancel() {
    this.props.history.push("/");
  }

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <br />
        <div className="row">
          <div className="col">
            <div>
              <StatusIndicator Intermediary />
              Unterwegs<br></br>
              <StatusIndicator Negative />
              Verspätet<br></br>
              <StatusIndicator Pulse />
              Storniert<br></br>
              <StatusIndicator Positive Pulse />
              Geliefert<br></br>
            </div>
          </div>
          <div className="col">
            Fahrer auswählen:
            <select
              className={"custom-select"}
              onChange={(evt) => this.changeDriverHandler(evt)}
            >
              {this.state.drivers.map((e, key) => {
                return (
                  <option key={key} value={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {this.state.errorMessage && (
          <div style={{ marginTop: "10px", color: "red" }}>
            <span> {this.state.errorMessage} </span>
            <br></br>
            <br></br>
          </div>
        )}
        <div className="row">
          {
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Bestellung</th>
                  <th>Store</th>
                  <th>Produzent</th>
                  <th>Kunde</th>
                  <th>Adresse</th>
                  <th>Status</th>
                  <th>Zugewiesen am</th>
                  <th>Ankunftszeit</th>
                  <th>Operationen</th>
                </tr>
              </thead>
              <tbody>
                {this.state.filteredData.map((d, index) => (
                  <tr key={d.id}>
                    <td style={{ width: "10px" }}> {d.item.bill.number} </td>
                    <td style={{ width: "100px" }}>
                      {" "}
                      {d.item.store && d.item.store.name}{" "}
                    </td>
                    <td style={{ width: "100px" }}>
                      {" "}
                      {d.item.producent && d.item.producent.name}{" "}
                    </td>
                    <td style={{ width: "100px" }}>
                      {" "}
                      {d.item.client && d.item.client.name}{" "}
                      {d.item.client && d.item.client.surname} <br></br>
                      {d.item.client && d.item.client.telefonNumber}
                      {d.item.client && d.item.client.telefonNumber2}
                    </td>
                    <td style={{ width: "100px" }}>
                      {" "}
                      {d.item.client && d.item.client.street}{" "}
                      {d.item.client && d.item.client.number},{" "}
                      {d.item.client && d.item.client.city}{" "}
                      {d.item.client && d.item.client.country}
                    </td>
                    <td style={{ width: "15px" }}>
                      {d.status === "CANCELLED" ? (
                        <div style={{ marginLeft: "15px" }}>
                          <StatusIndicator Pulse />
                        </div>
                      ) : d.status === "DELAYED" ? (
                        <div style={{ marginLeft: "15px" }}>
                          <StatusIndicator Negative Pulse />
                        </div>
                      ) : d.status === "DRIVING" ? (
                        <div style={{ marginLeft: "15px" }}>
                          <StatusIndicator Intermediary Pulse />
                        </div>
                      ) : (
                        <div style={{ marginLeft: "15px" }}>
                          <StatusIndicator Positive Pulse />
                        </div>
                      )}
                    </td>
                    <td style={{ width: "100px" }}>
                      {" "}
                      {Moment(d.created).format("D/M/YYYY HH:mm")}{" "}
                    </td>
                    <td style={{ width: "100px" }}>
                      {d.created &&
                        this.arrivalTime(
                          index,
                          new Date(d.created),
                          d.duration
                        )}
                    </td>
                    <td style={{ width: "15px" }}>
                      {d.status !== "CANCELLED" && (
                        <Icon
                          name="close"
                          tooltip="Fahrt stornieren"
                          theme="light"
                          size="medium"
                          onClick={() => this.cancelDelivery(d.id)}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </div>
        <div>
          {this.state.deliveries && this.state.deliveries.length > 0 && (
            <MapComponent
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD15WCZGqAdq0JhfYXjIx4b6v1PrrYMP8Q&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              defaultZoom={11.5}
              defaultCenter={{ lat: 50.9633099, lng: 7.0282932 }}
            >
              {this.state.markers.map((marker) => (
                <Marker
                  icon={
                    marker.color == "blue"
                      ? "https://maps.gstatic.com/mapfiles/ms2/micons/cabs.png":
                      marker.color == "gray"
                      ? "https://maps.gstatic.com/mapfiles/ms2/micons/caution.png"
                      : "https://maps.google.com/mapfiles/ms/icons/" +
                        marker.color +
                        "-dot.png"
                  }
                  key={marker.id}
                  position={marker.position}
                  onClick={() => {}}
                >
                  <InfoWindow onCloseClick={() => {}}>
                    <div style={{ fontWeight: "bolder", color: marker.color }}>
                      {marker.name}
                    </div>
                  </InfoWindow>
                </Marker>
              ))}
            </MapComponent>
          )}
        </div>
      </div>
    );
  }
}

export default DeliveryComponent;
