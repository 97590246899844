import React, { Component } from 'react';
import Sidenavigation from '../layouts/Sidenavigation';
import Topnavigation from '../layouts/Topnavigation';
import Quickbar from '../layouts/Quickbar';
import { withRouter } from "react-router-dom";
import AddShoppingArticleItemComponent from '../AddShoppingArticleItemComponent';

class AddShoppingAricleItem extends Component {

    constructor(props) {
        super(props)
        this.state = {
           
        }
    }

    render() {
        return (
            <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                <Sidenavigation />
                <main className="body-content">
                    <Topnavigation />
                    <AddShoppingArticleItemComponent />
                </main>
                <Quickbar />
            </div>
        );
    }
}

export default  withRouter(AddShoppingAricleItem);