import React, { Component } from "react";
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import ClientService from "../services/ClientService";
import Countries from "react-select-country";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { translate } from "react-switch-lang";

import Cookies from "universal-cookie";
const cookies = new Cookies();

class AddAddressComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postalCode: "",
      number: "",
      street: "",
      city: "",
      country: "",
      countries: [],
      id: this.props.match.params.id,
    };
    this.changePostalCodeHandler = this.changePostalCodeHandler.bind(this);
    this.changeNumberHandler = this.changeNumberHandler.bind(this);
    this.changeStreetHandler = this.changeStreetHandler.bind(this);
    this.changeCityHandler = this.changeCityHandler.bind(this);
    this.changeCountryHandler = this.changeCountryHandler.bind(this);
    this.saveOrUpdate = this.saveOrUpdate.bind(this);
  }

  saveOrUpdate = (e) => {
    e.preventDefault();
    let c = {
      number: this.state.number,
      street: this.state.street,
      postalCode: this.state.postalCode,
      city: this.state.city,
      country: this.state.country,
    };
    console.log("Address => " + JSON.stringify(c));
    ClientService.addAddress(c, this.state.id).then((res) => {
      this.props.history.push("/customer-list/");
    });
  };

  changePostalCodeHandler = (event) => {
    this.setState({ postalCode: event.target.value });
  };

  changeStreetHandler = (event) => {
    this.setState({ street: event.target.value });
  };

  changeCityHandler = (event) => {
    this.setState({ city: event.target.value });
  };

  changeCountryHandler = (event) => {
    this.setState({
      country: event.target.options[event.target.selectedIndex].text,
    });
  };

  changeNumberHandler = (event) => {
    this.setState({ number: event.target.value });
  };


  cancel() {
    this.props.history.push("/customer-list/");
  }

  componentDidMount() {}

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="card col-md-6 offset-md-3 offset-md-3">
              <div className="card-body">
                <div className="form-group">
                  <label> Straße: </label>
                  <input
                    placeholder="Straße"
                    name="Straße"
                    className="form-control"
                    value={this.state.street}
                    onChange={this.changeStreetHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Hausnummer: </label>
                  <input
                    placeholder="Hausnummer"
                    name="Hausnummer"
                    className="form-control"
                    value={this.state.number}
                    onChange={this.changeNumberHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Postleitzahl: </label>
                  <input
                    placeholder="Postleitzahl"
                    name="Postleitzahl"
                    className="form-control"
                    value={this.state.postalCode}
                    onChange={this.changePostalCodeHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Stadt: </label>
                  <input
                    placeholder="Stadt"
                    name="Stadt"
                    className="form-control"
                    value={this.state.city}
                    onChange={this.changeCityHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Land: </label>
                  <Countries
                    className="form-control"
                    ref="cList"
                    name="country"
                    empty=" -- Land auswählen --"
                    onChange={this.changeCountryHandler}
                  />
                </div>
                <Icon
                  name="save"
                  tooltip="Speichern"
                  theme="light"
                  size="medium"
                  onClick={this.saveOrUpdate}
                />
                <Icon
                  name="undo"
                  tooltip="Zurück zur Kundendatenbank"
                  theme="light"
                  size="medium"
                  onClick={this.cancel.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default compose(withRouter)(translate(AddAddressComponent));
