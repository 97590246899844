import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const API_BASE_URL = process.env.REACT_APP_API_URL + "/api/v1/bills";
const API_BASE_URL_OFFERS = process.env.REACT_APP_API_URL + "/api/v1/offers";
const API_BASE_URL_COMPANY = process.env.REACT_APP_API_URL + "/api/v1/company";
const API_BASE_URL_DOCUMENTS =
  process.env.REACT_APP_API_URL + "/api/v1/documents";
const API_BASE_URL_SHEETS = process.env.REACT_APP_API_URL + "/api/v1/sheets";

class OrderService {
  getOrders() {
    return axios.get(API_BASE_URL, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getOffers() {
    return axios.get(API_BASE_URL_OFFERS, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getCompany() {
    return axios.get(API_BASE_URL_COMPANY, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getFooters() {
    return axios.get(API_BASE_URL_OFFERS + "/footers", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getTemplates() {
    return axios.get(API_BASE_URL_OFFERS + "/templates", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getDocuments() {
    return axios.get(API_BASE_URL_OFFERS + "/documents", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getSheets() {
    return axios.get(API_BASE_URL_SHEETS, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  updateWorkSheet(sheetId, file) {
    return axios.post(API_BASE_URL_SHEETS + "/clientSigned/" + sheetId, file, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  updateWorkSheet2(sheetId, file) {
    return axios.post(API_BASE_URL_SHEETS + "/supervisorSigned/" + sheetId, file, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  assignCancellation(id, iId) {
    return axios.put(
      API_BASE_URL + "/cancellation/" + id + "/" + iId + "/",
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  assignCorrection(priceWithTax, priceWithoutTax, taxFee, bIll) {
    return axios.put(
      `${API_BASE_URL}/correction/${priceWithTax}/${priceWithoutTax}/${taxFee}/${bIll}/`,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  assignAddress(addressId,bIll) {
    return axios.put(
      `${API_BASE_URL}/address/${addressId}/${bIll}/`,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  getExecutionPeriods() {
    return axios.get(API_BASE_URL_OFFERS + "/executionPeriods/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  assignOfferStatus(id, iId) {
    return axios.put(
      API_BASE_URL_OFFERS + "/status/" + id + "/" + iId + "/",
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  createOffer(clientId, addressId, footers, templates, offer) {
    return axios.post(
      API_BASE_URL_OFFERS +
        "/add/" +
        clientId +
        "/" +
        addressId +
        "/" +
        footers +
        "/" +
        templates,
      offer,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  createDocument(footers, templates, offer) {
    return axios.post(
      API_BASE_URL_DOCUMENTS + "/add/" + footers + "/" + templates,
      offer,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  createWorkSheet(clientId, addressId, workerId, templates, offer) {
    return axios.post(
      API_BASE_URL_SHEETS +
        "/add/" +
        clientId +
        "/" +
        addressId +
        "/" +
        workerId +
        "/" +
        templates,
      offer,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  assignDone(iId) {
    return axios.put(
      API_BASE_URL + "/paid/" + iId + "/",
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  createNote(itemId, itemNotetype, note) {
    return axios.post(
      API_BASE_URL + "/notes/" + itemId + "/" + itemNotetype + "/" + note,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  sendMail(itemId) {
    return axios.post(
      API_BASE_URL + "/send/" + itemId,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  getOrdersBetween(start, end) {
    return axios.get(API_BASE_URL + "/between/" + start + "/" + end, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  search(pattern) {
    return axios.get(API_BASE_URL + "/search/" + pattern, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getCarTypes() {
    return axios.get(API_BASE_URL + "/cars/types", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getPaymentModes() {
    return axios.get(API_BASE_URL + "/paymentModes/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getOfferStatus() {
    return axios.get(API_BASE_URL_OFFERS + "/offerStatus/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  matchArticle(id) {
    return axios.post(
      API_BASE_URL + "/match/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  getCancellationTypes() {
    return axios.get(API_BASE_URL + "/cancellationTypes/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getItemTypes() {
    return axios.get(API_BASE_URL + "/types/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getCars() {
    return axios.get(API_BASE_URL + "/cars/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getNoteTypes() {
    return axios.get(API_BASE_URL + "/notetypes/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getPendingOrders() {
    return axios.get(API_BASE_URL + "/pendings/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getDeliveries(driverId) {
    return axios.get(API_BASE_URL + "/deliveries/" + driverId, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  checkDelivery(delivery) {
    return axios.get(API_BASE_URL + "/deliveries/confirm/" + delivery, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getDrivers(status) {
    return axios.get(API_BASE_URL + "/drivers/" + status, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getAllDrivers() {
    return axios.get(API_BASE_URL + "/drivers/all", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getCurrentDriversDelivering() {
    return axios.get(API_BASE_URL + "/drivers/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  create(
    id,
    name,
    tel,
    storeId,
    position,
    amount,
    itemNoteDriver,
    itemNoteKitchen,
    itemNoteGeneral
  ) {
    return axios.post(
      API_BASE_URL + "/" + id + "/" + storeId + "/" + amount,
      position,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
        params: {
          name,
          tel,
          itemNoteDriver,
          itemNoteKitchen,
          itemNoteGeneral,
        },
      }
    );
  }

  createCar(position) {
    return axios.post(API_BASE_URL + "/cars", position, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  updateCar(position, driverId) {
    return axios.put(API_BASE_URL + "/users/" + driverId, position, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  updateCarOut(position, driverId) {
    return axios.put(API_BASE_URL + "/users/out/" + driverId, position, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  cancelDelivery(id) {
    return axios.post(
      API_BASE_URL + "/cancel/delivery/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  cancelItem(id, cancelReason) {
    return axios.post(API_BASE_URL + "/cancel/" + id, cancelReason, {
      headers: {
        "content-type": "text/plain",
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  assignDriver(driverId, orders) {
    return axios.post(
      API_BASE_URL + "/drivers/assign/" + driverId + "/" + orders,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  disAssignDriver(driverId, orders) {
    return axios.post(
      API_BASE_URL + "/drivers/disAssign/" + driverId + "/" + orders,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  startTour(driverId, backIn, orders) {
    return axios.post(
      API_BASE_URL + "/drivers/start/" + driverId + "/" + backIn,
      orders,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  getDriverBySearch(search) {
    return axios.get(API_BASE_URL + "/drivers/search/" + search, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getDeliveriesByDriver() {
    return axios.get(API_BASE_URL + "/drivers/tours/" + cookies.get("user"), {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  saveDriverGeoData(lat, lng) {
    return axios.post(
      API_BASE_URL +
        "/drivers/location/" +
        cookies.get("user") +
        "/" +
        lat +
        "/" +
        lng,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  getDriverGeoData(driverId) {
    return axios.get(API_BASE_URL + "/drivers/location/" + driverId, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getById(id) {
    return axios.get(API_BASE_URL + "/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  update(order, id) {
    return axios.put(API_BASE_URL + "/" + id, order, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  updateCompany(c) {
    return axios.put(API_BASE_URL_COMPANY + "/update", c, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  delete(id) {
    return axios.delete(
      API_BASE_URL + "/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  deleteFooter(id) {
    return axios.delete(API_BASE_URL + "/footers/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  deleteTemplate(id) {
    return axios.delete(API_BASE_URL + "/templates/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  deleteDocument(id) {
    return axios.delete(API_BASE_URL + "/documents/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }
}

export default new OrderService();
