import React, { Component } from "react";
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import ArticleService from "../services/ArticleService";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class AddTemplateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
    };
    this.changeNameHandler = this.changeNameHandler.bind(this);
    this.saveOrUpdate = this.saveOrUpdate.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  saveOrUpdate = (e) => {
    e.preventDefault();
    let c = { name: this.state.name.replace(/\n/g, '<br />') };
    console.log("Template => " + JSON.stringify(c));
    ArticleService.createTemplate(c).then((res) => {
      this.props.history.push("/templates/");
    });
  };

  changeNameHandler = (event) => {
    var name = event.target.value;
    this.setState({ name: name });
  };

  cancel = () => {
    this.props.history.push("/templates/");
  };

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="card col-md-6 offset-md-3 offset-md-3">
              <div className="card-body">
                <div className="form-group">
                  <label> Name: </label>
                  <textarea
                    placeholder="Name"
                    name="Name"
                    className="form-control"
                    style={{resize:'block'}}
                    value={this.state.name}
                    onChange={this.changeNameHandler}
                  />
                </div>
                <Icon
                  name="save"
                  tooltip="Speichern"
                  theme="light"
                  size="medium"
                  onClick={this.saveOrUpdate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default compose(withRouter)(AddTemplateComponent);
