import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { translate } from "react-switch-lang";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import UserService from "../services/UserService";
const cookies = new Cookies();

class ListTimesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workers: [],
      months: [],
      selectedWorker: "",
      workerData: [],
      objectList: [],
      selectedTimeTable: 0,
      selectedYear: new Date().getFullYear(),
    };
    const currentYear = new Date().getFullYear();
    this.lastTenYears = Array.from(
      { length: 10 },
      (_, index) => currentYear - index
    );
  }

  componentDidMount() {
    UserService.getAllWorkers().then((res) => {
      UserService.getObjectList().then((res2) => {
        this.setState({
          selectedWorker: cookies.get("roles").includes("WORKER")
            ? res.data && res.data.filter(
                (one) => one.user && one.user.id == cookies.get("user_id")
              )[0].id
            : 0,
          objectList: res2.data,
          workers: res.data,
        });
      });
    });
    const currentYear = new Date().getFullYear();
    const months = Array.from({ length: 12 }, (_, i) => {
      const monthIndex = i + 1;
      const monthLabel = new Date(
        currentYear,
        monthIndex - 1,
        1
      ).toLocaleString("default", {
        month: "long",
      });
      return {
        value: `${currentYear}-${monthIndex.toString().padStart(2, "0")}`,
        label: monthLabel,
      };
    });
    this.setState({ months });
  }

  fetchWorkerData = () => {
    const { selectedWorker, selectedMonth, selectedYear } = this.state;
    if (selectedWorker && selectedMonth) {
      UserService.getTimes(
        selectedWorker,
        parseInt(selectedYear, 10),
        selectedMonth.split("-")[1]
      ).then((res) => {
        this.setState({
          workerData: res.data,
          selectedTimeTable: res.data && res.data[0] ? res.data[0].id : 0,
        });
      });
    }
  };

  handleEdit = (field, value, currentDate) => {
    const { workerData } = this.state;
    let newData = [...workerData];

    // Find the entry for the currentDate
    const entryIndex = newData.findIndex((entry) =>
      entry.dates.some(
        (date) => date.date.substring(0, 10) === currentDate.substring(0, 10)
      )
    );

    // If the entry for currentDate doesn't exist, create a new one
    if (entryIndex === -1) {
      newData.push({
        dates: [
          {
            date: currentDate,
            startTime: "",
            endTime: "",
            breakTime: 0,
            address_reference_id: 0,
          },
        ],
      });
    }

    // Update startTime or endTime for the currentDate
    newData = newData.map((entry) => {
      if (
        entry.dates.some(
          (date) => date.date.substring(0, 10) === currentDate.substring(0, 10)
        )
      ) {
        return {
          ...entry,
          dates: entry.dates.map((date) => {
            if (date.date.substring(0, 10) === currentDate.substring(0, 10)) {
              return {
                ...date,
                [field]: value,
              };
            }
            return date;
          }),
        };
      }
      return entry;
    });

    this.setState({ workerData: newData });
  };

  handleSave = (existingEntry, currentDate) => {
    const { selectedWorker, selectedMonth, selectedYear } = this.state;

    const data = {
      date: currentDate,
      startTime: existingEntry.startTime,
      endTime: existingEntry.endTime,
      breakTime: existingEntry.breakTime,
      address_reference_id: existingEntry.address_reference_id,
    };

    const saveEndpoint = `/workers/${selectedWorker}/${selectedYear}/${
      selectedMonth.split("-")[1]
    }`;

    // Check if the entry has an ID to determine whether to use POST or PUT
    if (existingEntry.id) {
      // If ID is present, it's an existing entry, so use PUT
      UserService.updateTime(saveEndpoint, existingEntry.id, data)
        .then((response) => {
          console.log("Data updated:", response.data);
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });
    } else {
      // If ID is not present, it's a new entry, so use POST
      UserService.saveTimes(
        selectedWorker,
        selectedYear,
        selectedMonth.split("-")[1],
        data
      )
        .then((response) => {
          console.log("Data saved:", response.data);
          this.fetchWorkerData();
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
    }
  };

  renderTotalHours = () => {
    const { workerData } = this.state;
    const totalMilliseconds = workerData.reduce((total, entry) => {
      return (
        total +
        entry.dates.reduce((entryTotal, date) => {
          const start = new Date(`1970-01-01T${date.startTime}`);
          const end = new Date(`1970-01-01T${date.endTime}`);
          const breakTime = date.breakTime || 0; // Assuming breakTime is in minutes
          const adjustedEnd = new Date(end - breakTime * 60 * 1000); // Subtract breakTime

          return entryTotal + (adjustedEnd - start);
        }, 0)
      );
    }, 0);

    const totalHours = Math.floor(totalMilliseconds / (1000 * 60 * 60));
    const totalMinutes = Math.floor(
      (totalMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    if (totalMinutes === 0) {
      return `${totalHours} Stunden`;
    } else {
      return `${totalHours},${totalMinutes} Stunden`;
    }
  };

  handleYearChange = (event) => {
    const selectedValue = event.target.value;
    const selectedYear = parseInt(selectedValue, 10);
    this.setState({ selectedYear }, this.fetchWorkerData);
  };

  print = (id) => {
    UserService.printTimeTable(id).then((response) => {
      var blob = new Blob([response.data], {
        type: "application/pdf",
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      var foundObject = this.state.workers.find(
        (obj) => obj.id == this.state.selectedWorker
      );
      const date = new Date(
        2000,
        this.state.selectedMonth.split("-")[1] - 1,
        1
      );
      // Get the month name from the Date object
      const monthName = date.toLocaleString("default", { month: "short" });
      var fileName =
        "Zeiterfassung_ " +
        foundObject.name +
        "_" +
        foundObject.surname +
        "_" +
        monthName +
        "." +
        String(this.state.selectedYear).slice(-2) +
        ".pdf";
      link.download = fileName;
      link.click();
    });
  };

  calculateTimeDifference = (startTime, endTime, breakTime) => {
    if (!startTime || !endTime) {
      return "";
    }

    const start = new Date(`1970-01-01T${startTime}`);
    const end = new Date(`1970-01-01T${endTime}`);
    const breakInMilliseconds = breakTime * 60 * 1000; // Convert breakTime to milliseconds

    const adjustedEnd = new Date(end - breakInMilliseconds);
    const diffInMilliseconds = adjustedEnd - start;

    const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    if (hours === 0) {
      return `${minutes} Minute${minutes !== 1 ? "n" : ""}`;
    } else {
      return `${hours} Stunde${hours !== 1 ? "n" : ""} ${
        minutes !== 0 ? `und ${minutes} Minute${minutes !== 1 ? "n" : ""}` : ""
      }`;
    }
  };

  renderTimeTable = () => {
    const { workerData } = this.state;
    const daysInMonth = new Date(
      this.state.selectedYear,
      parseInt(this.state.selectedMonth.split("-")[1], 10),
      0
    ).getDate();

    const tableRows = Array.from({ length: daysInMonth }, (_, i) => {
      const day = i + 1;
      const currentDate = `${this.state.selectedYear}-${this.state.selectedMonth
        .split("-")[1]
        .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
      const existingEntry = workerData
        .map((entry) => {
          const currentDateFormatted = currentDate.substring(0, 10);
          const foundDate = entry.dates.find(
            (date) => date.date.substring(0, 10) === currentDateFormatted
          );
          return foundDate
            ? {
                startTime: foundDate.startTime,
                endTime: foundDate.endTime,
                date: foundDate.date,
                id: foundDate.id,
                breakTime: foundDate.breakTime,
                address_reference_id: foundDate.address_reference_id,
              }
            : null;
        })
        .find((foundEntry) => foundEntry !== null);
      const dayOfWeek = new Date(currentDate).getDay();
      const dayName = new Intl.DateTimeFormat("de-DE", {
        weekday: "long",
      }).format(new Date(currentDate));
      const timeDiff = this.calculateTimeDifference(
        existingEntry ? existingEntry.startTime : null,
        existingEntry ? existingEntry.endTime : null,
        existingEntry ? existingEntry.breakTime : null
      );

      const cellStyle = {
        backgroundColor:
          dayOfWeek === 6 || dayOfWeek === 0 ? "gray" : "inherit",
      };

      return (
        <tr key={i}>
          <td style={cellStyle}>{dayName}</td>
          <td>{currentDate}</td>
          <select
            style={{ marginTop: "15px" }}
            value={existingEntry ? existingEntry.address_reference_id : ""}
            onChange={(e) =>
              this.handleEdit(
                "address_reference_id",
                e.target.value,
                currentDate
              )
            }
          >
            <option value="">Objekt auswählen</option>
            {this.state.objectList.map((obj) => (
              <option key={obj.id} value={obj.id}>
                {obj.street} {obj.number} {obj.postalCode} {obj.city}
              </option>
            ))}
          </select>
          <td>
            <input
              type="time"
              step="1800" // Set step to 1800 seconds (30 minutes)
              value={
                existingEntry?.startTime
                  ? existingEntry.startTime.substring(0, 5)
                  : ""
              }
              onChange={(e) =>
                this.handleEdit("startTime", e.target.value, currentDate)
              }
            />
          </td>
          <td>
            <input
              type="time"
              step="1800" // Set step to 1800 seconds (30 minutes)
              value={
                existingEntry?.endTime
                  ? existingEntry.endTime.substring(0, 5)
                  : ""
              }
              onChange={(e) =>
                this.handleEdit("endTime", e.target.value, currentDate)
              }
            />
          </td>
          <td>
            <input
              type="number"
              value={existingEntry ? existingEntry.breakTime : ""}
              onChange={(e) =>
                this.handleEdit("breakTime", e.target.value, currentDate)
              }
            />
          </td>
          <td>{timeDiff}</td>
          <td>
            {existingEntry &&
              existingEntry.startTime &&
              existingEntry.endTime && (
                <button
                  onClick={() => this.handleSave(existingEntry, currentDate)}
                >
                  Speichern
                </button>
              )}
          </td>
        </tr>
      );
    });

    return (
      <div>
        <h6>
          Arbeitszeiten für {this.state.selectedMonth.split("-")[1]}{" "}
          {parseInt(this.state.selectedYear, 10)}
          <br></br>
          <br></br>Gesamtarbeitszeit für den Monat: {this.renderTotalHours()}
          <br></br>
          <br></br>
          <button onClick={() => this.print(this.state.selectedTimeTable)}>
            PDF export
          </button>
        </h6>
        <table>
          <thead>
            <tr>
              <th>Tag</th>
              <th>Datum</th>
              <th>Objekt</th>
              <th>Start</th>
              <th>Ende</th>
              <th>Pause in Minuten</th>
              <th>Arbeitsstunden</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </div>
    );
  };

  render() {
    const { workers, selectedYear, selectedWorker, selectedMonth, months } =
      this.state;

    return (
      <div className="list-times-container">
        <label>Mitarbeiter auswählen:</label>
        <select
          disabled={cookies.get("roles").includes("WORKER")}
          onChange={(e) =>
            this.setState(
              { selectedWorker: e.target.value },
              this.fetchWorkerData
            )
          }
        >
          <option value="">Mitarbeiter auswählen</option>
          {workers &&
            workers.map((worker) => (
              <option key={worker.id} value={worker.id}>
                {worker.name} {worker.surname}
              </option>
            ))}
        </select>
        <div>
          <label htmlFor="year">Jahr auswählen:</label>
          <select
            id="year"
            value={selectedYear}
            onChange={this.handleYearChange}
          >
            {this.lastTenYears.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <label>Monat auswählen:</label>
        <select
          onChange={(e) =>
            this.setState(
              { selectedMonth: e.target.value },
              this.fetchWorkerData
            )
          }
        >
          <option value="">Monat auswählen</option>
          {months &&
            months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
        </select>

        {selectedWorker && selectedMonth && (
          <div className="time-table-container">{this.renderTimeTable()}</div>
        )}
      </div>
    );
  }
}

ListTimesComponent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(withRouter)(translate(ListTimesComponent));
