import React, { Component } from 'react'
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import "react-datepicker/dist/react-datepicker.css";
import BillService from '../services/BillService';
import { Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import locale from 'dayjs/locale/de';
import OrderService from '../services/OrderService';
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import {
    translate,
} from 'react-switch-lang';
import ProducentService from '../services/ProducentService';

const cookies = new Cookies();

class GenerateBillComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            start: new Date(),
            end: new Date(),
            company:0,
            companies: []
        }
        this.changeDateStartHandler = this.changeDateStartHandler.bind(this);
        this.changeDateEndHandler = this.changeDateEndHandler.bind(this);
        this.onDropdownSelectedCompany = this.onDropdownSelectedCompany.bind(this);
        this.saveOrUpdate = this.saveOrUpdate.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    saveOrUpdate (){
        BillService.export(this.state.company, this.state.start, this.state.end).then(res => {
            this.props.history.push('/');
        });
    }

    changeDateStartHandler = (event) => {
        this.setState({ start: event });
    }

    changeDateEndHandler = (event) => {
        this.setState({ end: event });
    }

    cancel = () => {
        this.props.history.push('/');
    }

    onDropdownSelectedCompany = (event) => {
        let one = event.target.value;
        this.setState({
            company: one
        });
    }

    componentDidMount() {
           ProducentService.getCompanies().then((res3) => {
           this.setState({
                    companies: res3.data,
                    company: res3.data[0].id
                });
            });
    }

    render() {
        if (cookies.get("token") !== 'b5c9667644da67241316db50cc788bfd078849de') {
            return <Redirect to="/login" />;
        }
        return (
            <div>
                <br></br>
                <div className="container">
                    <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3">
                            <div className="card-body">
                            <LocalizationProvider adapterLocale={'de'} dateAdapter={AdapterDayjs}>
                                <div className="form-group">
                                    <label style={{paddingTop:'15px', width:'90px'}}> Von: </label>
                                    <DateTimePicker
                                    value={this.state.start}
                                    renderInput={(params) => <TextField {...params}/>}
                                    onChange={this.changeDateStartHandler}/>
                                </div>
                                <div className="form-group">
                                    <label style={{paddingTop:'15px', width:'90px'}}> Bis: </label>
                                    <DateTimePicker
                                    renderInput={(params) => <TextField {...params}/>}
                                    value={this.state.end}
                                    onChange={this.changeDateEndHandler}/>
                                </div>
                                <div className="form-group">
                                <label style={{paddingTop:'15px', width:'90px'}}>Firma:</label>
                                <select className={'custom-select'} style={{ width: '200px' }}
                                    defaultValue={this.state.storeId}
                                    onChange={(evt) => this.onDropdownSelectedCompany(evt)}>
                                    {this.state.companies.map((e, key) => {
                                        return <option key={key} value={e.id}>{e.name}</option>;
                                    })}
                                </select>
                                </div>
                                </LocalizationProvider>
                                <Icon name="save" tooltip="Generieren" theme="light" size="medium" onClick={this.saveOrUpdate} />
                                <Icon name="undo" tooltip="Zurück zu den Bestellungen" theme="light" size="medium" onClick={this.cancel.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default compose(
    withRouter,
  )(translate(GenerateBillComponent))