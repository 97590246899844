import React, { Component } from 'react'
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import ClientService from '../services/ClientService';
import Countries  from 'react-select-country';
import { Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import {
    translate,
} from 'react-switch-lang';

import Cookies from "universal-cookie";
const cookies = new Cookies();

class AddClientComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            surname: '',
            postalCode: '',
            number: '',
            street: '',
            city: '',
            country: '',
            telefonNumber:'',
            telefonNumber2:'',
            contactPerson:'',
            contactPerson2:'',
            email: '',
            countries: []
        }
        this.changeNameHandler = this.changeNameHandler.bind(this);
        this.changeSurNameHandler = this.changeSurNameHandler.bind(this);
        this.changePostalCodeHandler = this.changePostalCodeHandler.bind(this);
        this.changeNumberHandler = this.changeNumberHandler.bind(this);
        this.changeTelefonNumberHandler = this.changeTelefonNumberHandler.bind(this);
        this.changeTelefonNumber2Handler = this.changeTelefonNumber2Handler.bind(this);
        this.changeContactPersonHandler = this.changeContactPersonHandler.bind(this);
        this.changeContactPerson2Handler = this.changeContactPerson2Handler.bind(this);
        this.changeEmailHandler = this.changeEmailHandler.bind(this);
        this.changeStreetHandler = this.changeStreetHandler.bind(this);
        this.changeCityHandler = this.changeCityHandler.bind(this);
        this.changeCountryHandler = this.changeCountryHandler.bind(this);
        this.saveOrUpdate = this.saveOrUpdate.bind(this);
    }

    saveOrUpdate = (e) => {
        e.preventDefault();
        let c = {name: this.state.name,
            surname: this.state.surname,
            number: this.state.number, 
            street: this.state.street, 
            postalCode: this.state.postalCode, 
            city:this.state.city,
            telefonNumber: this.state.telefonNumber,
            telefonNumber2: this.state.telefonNumber2,
            contactPerson: this.state.contactPerson,
            contactPerson2: this.state.contactPerson2,
            email : this.state.email,
            country:this.state.country};
            console.log('Client => ' + JSON.stringify(c));
            ClientService.create(c).then(res =>{
                this.props.history.push('/customer-list/');
            });
    }
    
    changeNameHandler= (event) => {
        this.setState({name: event.target.value});
    }
    
    changeSurNameHandler= (event) => {
        this.setState({surname: event.target.value});
    }

    changePostalCodeHandler= (event) => {
        this.setState({postalCode: event.target.value});
    }

    changeStreetHandler= (event) => {
        this.setState({street: event.target.value});
    }

    changeCityHandler= (event) => {
        this.setState({city: event.target.value});
    }

    changeCountryHandler= (event) => {
        this.setState({country: event.target.options[event.target.selectedIndex].text});
    }

    changeNumberHandler= (event) => {
        this.setState({number: event.target.value});
    }

    changeTelefonNumberHandler= (event) => {
        this.setState({telefonNumber: event.target.value});
    }

    changeTelefonNumber2Handler= (event) => {
        this.setState({telefonNumber2: event.target.value});
    }

    changeContactPersonHandler= (event) => {
        this.setState({contactPerson: event.target.value});
    }

    changeContactPerson2Handler= (event) => {
        this.setState({contactPerson2: event.target.value});
    }

    changeEmailHandler= (event) => {
        this.setState({email: event.target.value});
    }

    cancel(){
        this.props.history.push('/clients/');
    }

    componentDidMount () {

    }

    render() {
        if (cookies.get('token') !== 'b5c9667644da67241316db50cc788bfd078849de') {
            return <Redirect to="/login" />;
        }
        return (
            <div>
                <br></br>
                   <div className = "container">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                                <div className = "card-body">
                                        <div className = "form-group  has-success">
                                            <label for="inputSuccess"> Vorname (*): </label>
                                            <input placeholder="Name" id="inputSuccess" className="form-control" 
                                                value={this.state.name} onChange={this.changeNameHandler}/>
                                        </div>
                                        <div className = "form-group  has-success">
                                            <label for="inputSuccess"> Nachname (*): </label>
                                            <input placeholder="Name" id="inputSuccess" className="form-control" 
                                                value={this.state.surname} onChange={this.changeSurNameHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Telefonnummer: </label>
                                            <input placeholder="Telefonnummer" name="Telefonnummer" className="form-control" 
                                                value={this.state.telefonNumber} onChange={this.changeTelefonNumberHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Telefonnummer 2: </label>
                                            <input placeholder="Telefonnummer" name="Telefonnummer" className="form-control" 
                                                value={this.state.telefonNumber2} onChange={this.changeTelefonNumber2Handler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Ansprechpartner: </label>
                                            <input placeholder="Ansprechpartner" name="Ansprechpartner" className="form-control" 
                                                value={this.state.contactPerson} onChange={this.changeContactPersonHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Ansprechpartner 2: </label>
                                            <input placeholder="Ansprechpartner 2" name="Ansprechpartner2" className="form-control" 
                                                value={this.state.contactPerson2} onChange={this.changeContactPerson2Handler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Email: </label>
                                            <input placeholder="Email" name="mail" className="form-control" 
                                                value={this.state.email} onChange={this.changeEmailHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Straße: </label>
                                            <input placeholder="Straße" name="Straße" className="form-control" 
                                                value={this.state.street} onChange={this.changeStreetHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Hausnummer: </label>
                                            <input placeholder="Hausnummer" name="Hausnummer" className="form-control" 
                                                value={this.state.number} onChange={this.changeNumberHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Postleitzahl: </label>
                                            <input placeholder="Postleitzahl" name="Postleitzahl" className="form-control" 
                                                value={this.state.postalCode} onChange={this.changePostalCodeHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Stadt: </label>
                                            <input placeholder="Stadt" name="Stadt" className="form-control" 
                                                value={this.state.city} onChange={this.changeCityHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Land: </label>
                                            <Countries className="form-control" ref="cList" name="country" empty=" -- Land auswählen --" onChange={this.changeCountryHandler} />
                                        </div>
                                        <Icon name = "save" tooltip = "Speichern" theme = "light" size = "medium" onClick={this.saveOrUpdate}/>
                                        <Icon name = "undo" tooltip = "Zurück zur Kundendatenbank" theme = "light" size = "medium" onClick={this.cancel.bind(this)}/>
                                </div>
                            </div>
                        </div>
                   </div>
            </div>
        )
    }
}
export default compose(
    withRouter,
  )(translate(AddClientComponent))