import React, { Component } from 'react';
import Sidenavigation from '../layouts/Sidenavigation';
import Topnavigation from '../layouts/Topnavigation';
import Homecontent from '../sections/Dashboard/Homecontent';
import Quickbar from '../layouts/Quickbar';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
} from 'react-switch-lang';
import en from '../../languages/en.json';
import de from '../../languages/de.json';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

setTranslations({ en, de });
var userLang = 'de';
setDefaultLanguage(userLang);
setLanguageCookie();
class Dashboard extends Component {

    render() {
        if (cookies.get("token") !== 'b5c9667644da67241316db50cc788bfd078849de') {
            return <Redirect to="/login" />;
        }
        return (
            <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                <Sidenavigation />
                <main className="body-content">
                    <Topnavigation />
                    <Homecontent/>
                </main>
                <Quickbar />
            </div>
        );
    }
}

export default Dashboard;