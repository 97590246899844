import React, { Component } from 'react';
import BillService from '../../../services/BillService';
import Breadcrumb from './Breadcrumb'
import "datatables.net-bs4/js/dataTables.bootstrap4"
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css"
import ReactDOM from 'react-dom';
import $ from 'jquery';
import OrderPositionService from '../../../services/OrderPositionService';
import Icon from "react-crud-icons";
import { Redirect, withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Detailcontent extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        BillService.getBills().then((res) => {
            const columns = [
                {
                    title: 'ID',
                    data: 'id'
                },
                {
                    title: 'Anzeigen',
                    data: 'data'
                },
                {
                    title: 'Export PDF',
                    data: 'data'
                }
            ];
            $('#data-table-5').DataTable({
                data: res.data,
                columns: columns,
                language: {
                    "url": "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json"
                }, columnDefs: [
                    {
                        'targets': [1],
                        createdCell: (td, cellData, rowData, row, col) => {
                            ReactDOM.render(<Icon name="show" tooltip="Rechnung anzeigen" theme="light" size="medium" onClick={() => this.props.history.push('/invoice-detail/'+rowData.id)} />, td)
                        }
                    },
                    {
                        'targets': [2],
                        createdCell: (td, cellData, rowData, row, col) => {
                            ReactDOM.render(<Icon name="export" tooltip="Rechnung exportieren" theme="light" size="medium" onClick={() => this.export(rowData.item.id)} />, td)
                        }
                    }
                ]
            });
        })
    }

    export(id) {
        OrderPositionService.getPdf(id);
    }

    render() {
        if (cookies.get("token") !== 'b5c9667644da67241316db50cc788bfd078849de') {
            return <Redirect to="/login" />;
        }
        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb />
                        <div className="ms-panel">
                            <div className="ms-panel-header">
                                <h6>Rechnungen</h6>
                            </div>
                            <div className="ms-panel-body">
                                <div className="table-responsive">
                                    <table id="data-table-5" className="table w-100 thead-primary" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default withRouter(Detailcontent);