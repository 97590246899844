import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { translate } from "react-switch-lang";
import { Modal } from "react-bootstrap";
import UserService from "../../../services/UserService";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Content extends Component {
  componentDidMount() {}
  constructor(props, context) {
    super(props, context);
    this.handleShow1 = this.handleShow1.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.login = this.login.bind(this);
    this.state = {
      show: false,
      loginParams: {
        user_id: "",
        user_password: "",
      },
    };
  }

  handleFormChange = (event) => {
    let loginParamsNew = { ...this.state.loginParams };
    let val = event.target.value;
    loginParamsNew[event.target.name] = val;
    this.setState({
      loginParams: loginParamsNew,
    });
  };

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  login = () => {
    let user_id = this.state.loginParams.user_id;
    let user_password = this.state.loginParams.user_password;
    UserService.exists(user_id.trim(), user_password.trim()).then((res) => {
      if (res.data !== "") {
        var date = new Date();
        date.setTime(date.getTime() + 12 * 60 * 60 * 1000);
        cookies.set("token","b5c9667644da67241316db50cc788bfd078849de", {
          path: "/app",
          expires: date,
        });
        cookies.set("languages", res.data.languages.toString(), {
          path: "/app",
          expires: date,
        });
        cookies.set("roles", res.data.roles[0], {
          path: "/app",
          expires: date,
        });
        cookies.set("user", res.data.name, { path: "/app", expires: date });
        cookies.set("user_id", res.data.id, { path: "/app", expires: date });
        this.props.history.push("/");
      }
    });
  };

  handleShow1() {
    this.setState({ show1: true });
  }

  handleClose() {
    this.setState({ show1: false });
  }

  render() {
    if (cookies.get("token") == "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/" />;
    }
    return (
      <div className="ms-content-wrapper ms-auth">
        <div className="ms-auth-container">
          <div className="ms-auth-col">
            <div className="ms-auth-bg" />
          </div>
          <div className="ms-auth-col">
            <div className="ms-auth-form">
              <div className="needs-validation">
                <h3>Login</h3>
                <div className="mb-3">
                  <label htmlFor="validationCustom08">User</label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      id="validationCustom08"
                      placeholder="Username"
                      name="user_id"
                      onChange={this.handleFormChange}
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide a valid email.
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <label htmlFor="validationCustom09">Passwort</label>
                  <div className="input-group">
                    <input
                      type="password"
                      className="form-control"
                      id="validationCustom09"
                      placeholder="Password"
                      name="user_password"
                      onChange={this.handleFormChange}
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide a password.
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-primary mt-4 d-block w-100"
                  onClick={() => this.login()}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="modal-min"
          show={this.state.show1}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="text-center">
            <button type="button" className="close" onClick={this.handleClose}>
              <span aria-hidden="true">×</span>
            </button>
            <i className="flaticon-secure-shield d-block" />
            <h1>Passwort vergessen?</h1>
            <p>User eingeben, um das Passwort zurückzusetzen</p>
            <form method="post">
              <div className="ms-form-group has-icon">
                <input
                  type="text"
                  placeholder="Email Address"
                  className="form-control"
                  name="forgot-password"
                />{" "}
                <i class="material-icons">email</i>
              </div>
              <button type="submit" className="btn btn-primary shadow-none">
                Passwort zurücksetzen
              </button>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default compose(withRouter)(translate(Content));
