import React, { Component } from "react";
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import ClientService from "../services/ClientService";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { translate } from "react-switch-lang";
import update from "immutability-helper";
import Cookies from "universal-cookie";
import DatePicker from "react-datepicker";
import OrderService from "../services/OrderService";
import PropTypes from "prop-types";
import ArticleService from "../services/ArticleService";
const cookies = new Cookies();

class AddOfferComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      id: 0,
      clientid: 0,
      addressid: 0,
      clients: [],
      contractTerm: 0,
      addresses: [],
      start: new Date(),
      end: new Date(),
      executionPeriods: [],
      footers: [],
      selectedFooters: [],
      templates: [],
      selectedTemplates: [],
      executions: [],
      description: "",
      billing: "",
      execution: "",
      executionPeriod: null,
      taxFees: [],
      taxFee: 0,
      priceWithTax: 0,
      priceWithoutTax: 0,
      amount: 0,
      taxFeeArticle: 0,
      priceWithTaxArticle: 0,
      priceWithoutTaxArticle: 0,
      showBillingTextarea: false,
      byCall: false,
      byContract: false,
      shoppingArticles: [],
      articles: [],
    };
    this.changeNameHandler = this.changeNameHandler.bind(this);
    this.saveOrUpdate = this.saveOrUpdate.bind(this);
  }

  saveOrUpdate = (e) => {
    e.preventDefault();
    let c = {
      contractTerm: this.state.contractTerm,
      name: this.state.name,
      timeFrom: this.state.start,
      timeTo: this.state.end,
      priceWithTax: this.state.priceWithTax,
      priceWithoutTax: this.state.priceWithoutTax,
      taxFee: this.state.taxFee,
      description: this.state.description,
      execution: this.state.execution,
      executionPeriod: this.state.executionPeriod,
      billing: this.state.billing,
      byCall: this.state.byCall,
      byContract: this.state.byContract,
      articles: this.state.articles,
    };
    console.log("Offer => " + JSON.stringify(c));
    OrderService.createOffer(
      this.state.clientid,
      this.state.addressid,
      this.state.selectedFooters.length > 0 ? this.state.selectedFooters : 0,
      this.state.selectedTemplates.length > 0
        ? this.state.selectedTemplates
        : 0,
      c
    ).then((res) => {
      this.props.history.push("/offers");
    });
  };

  changeNameHandler = (event) => {
    this.setState({ name: event.target.value });
  };

  changeClientHandler = (event) => {
    var cId = event.target.value;
    var addies = this.state.clients.filter((one) => one.id == cId)[0].addresses;
    this.setState({
      clientid: cId,
      addresses: addies,
    });
  };

  changeExecutionHandler = (event) => {
    this.setState({ executionPeriod: event.target.value });
  };

  changeDescriptionHandler = (event, id) => {
    var desc = event.target.value;
    var element = this.state.executions.filter((one) => one.id == id)[0];
    var updated = update(element, { description: { $set: desc } });
    var newData = update(this.state.executions, {
      $splice: [[this.state.executions.indexOf(element), 1, updated]],
    });
    this.setState({ executions: newData });
  };

  changeAddressHandler = (event) => {
    this.setState({ addressid: event.target.value });
  };

  cancel() {
    this.props.history.push("/offers");
  }

  changeDateStartHandler = (event) => {
    this.setState({ start: event });
  };

  changeDateEndHandler = (event) => {
    this.setState({ end: event });
  };

  componentDidMount() {
    ClientService.getClients().then((res) => {
      OrderService.getExecutionPeriods().then((res1) => {
        ArticleService.getAllTaxFees().then((res2) => {
          OrderService.getFooters().then((res3) => {
            OrderService.getTemplates().then((res4) => {
              ArticleService.getAllArticleAmounts().then((res5) => {
                this.setState({
                  clients: res.data,
                  executionPeriod: res1.data[0].id,
                  taxFees: res2.data,
                  footers: res3.data,
                  templates: res4.data,
                  executionPeriods: res1.data,
                  addresses: res.data[0].addresses,
                  clientid: res.data[0].id,
                  addressid: res.data[0].addresses[0].id,
                  shoppingArticles: res5.data.filter(
                    (one) => one.service === false
                  ),
                  name: res5.data.filter((one) => one.service === false)[0]
                    .name,
                });
              });
            });
          });
        });
      });
    });
    var all = [];
    Array.apply(null, { length: 10 }).forEach((element, key) => {
      all.push({ id: key + 1, description: "", executionPeriod: "0" });
    });
    this.setState({
      executions: all,
    });
  }

  findTaxFee(taxFee) {
    return this.state.taxFees.filter((item) => {
      return item.taxFee == taxFee;
    });
  }

  changeTaxFeeHandler = (event) => {
    var taxfeeNew = event.target.value;
    var priceWithTax = this.state.priceWithTax;
    if (this.findTaxFee(taxfeeNew)[0] != undefined) {
      this.setState({
        taxFee: taxfeeNew,
        priceWithoutTax: Number(
          Number(priceWithTax) +
            (priceWithTax * this.findTaxFee(taxfeeNew)[0].value) / 100
        ).toFixed(2),
      });
    }
  };

  changePurchasingPriceHandler = (event) => {
    var taxfeeNew = this.state.taxFee;
    var priceWithTaxNew = event.target.value;
    if (this.findTaxFee(taxfeeNew)[0] != undefined) {
      this.setState({
        priceWithTax: priceWithTaxNew,
        priceWithoutTax: Number(
          Number(priceWithTaxNew) +
            (priceWithTaxNew * this.findTaxFee(taxfeeNew)[0].value) / 100
        ).toFixed(2),
      });
    }
  };

  changeDescriptionNameHandler = (event) => {
    var n = event.target.value;
    this.setState({
      description: n,
    });
  };

  changeExecutionNameHandler = (event) => {
    var n = event.target.value;
    this.setState({
      execution: n,
    });
  };

  changeTermHandler = (event) => {
    var n = event.target.value;
    this.setState({
      contractTerm: n,
    });
  };

  changeBillingHandler = (event) => {
    var n = event.target.value;
    this.setState({
      billing: n,
    });
  };

  changeFooterHandler = (event) => {
    const selectedOptions = event.target.selectedOptions;
    const selectedFooters = Array.from(selectedOptions).map(
      (option) => option.value
    );
    this.setState({
      selectedFooters: selectedFooters,
    });
  };

  changeTemplateHandler = (event) => {
    const selectedOptions = event.target.selectedOptions;
    const selectedTemplates = Array.from(selectedOptions).map(
      (option) => option.value
    );
    this.setState({
      selectedTemplates: selectedTemplates,
    });
  };

  changeShoppingArticleIdHandler = (event) => {
    var id = event.target.value;
    this.setState({
      name: this.state.shoppingArticles.findOne(id)[0].name,
    });
  };

  changeAmountHandler = (event) => {
    this.setState({
      amount: event,
    });
  };

  changeAmountArticleHandler = (event) => {
    var taxfeeNew = this.state.taxFeeArticle;
    var priceWithTaxNew = event;
    if (this.findTaxFee(taxfeeNew)[0] !== undefined) {
      this.setState({
        priceWithTaxArticle: priceWithTaxNew,
        priceWithoutTaxArticle: Number(
          Number(priceWithTaxNew) +
            (priceWithTaxNew * this.findTaxFee(taxfeeNew)[0].value) / 100
        ).toFixed(2),
      });
    } else {
      this.setState({
        priceWithTaxArticle: priceWithTaxNew,
      });
    }
  };

  changeTaxFeeArticleHandler = (event) => {
    var taxfeeNew = event;
    var priceWithTax = this.state.priceWithTaxArticle;
    if (this.findTaxFee(taxfeeNew)[0] !== undefined) {
      this.setState({
        taxFeeArticle: taxfeeNew,
        priceWithoutTaxArticle: Number(
          Number(priceWithTax) +
            (priceWithTax * this.findTaxFee(taxfeeNew)[0].value) / 100
        ).toFixed(2),
      });
    }
  };

  deleteOne(id) {
    const newList = this.state.articles.filter((item) => item.id !== id);
    this.setState({
      articles: newList,
    });
  }

  saveArticle = (e) => {
    e.preventDefault();
    var idNew = this.state.id;
    this.setState({ id: idNew + 1 });
    let s = {
      name: this.state.name,
    };
    let a = {
      shoppingArticle: s,
      id: idNew + 1,
      amount: this.state.amount,
      priceWithTax: this.state.priceWithTaxArticle,
      priceWithoutTax: this.state.priceWithoutTaxArticle,
      taxFee: this.state.taxFeeArticle,
      total: Number(
        this.state.amount * this.state.priceWithoutTaxArticle
      ).toFixed(2),
    };
    console.log("ShoppingArticleItem => " + JSON.stringify(a));
    var articles = this.state.articles;
    articles.push(a);
    this.setState({ articles: articles });
  };

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      showBillingTextarea: !prevState.showBillingTextarea,
    }));
  };

  handleCheckboxChangeDuration = () => {
    this.setState((prevState) => ({
      byCall: !prevState.byCall,
    }));
  };

  handleCheckboxChangeContract = () => {
    this.setState((prevState) => ({
      byContract: !prevState.byContract,
    }));
  };

  render() {
    const { t } = this.props;
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="col">
              <p
                style={{
                  textDecoration: "underline",
                  textTransform: "uppercase",
                  marginLeft: "20px",
                  marginTop: "10px",
                }}
              >
                {" "}
                Angebotsdaten erfassen{" "}
              </p>
              <div className="card-body">
                <div className="form-group  has-success">
                  <label> Angebot für: (* kommt als Titelseite) </label>
                  <input
                    placeholder="Angebotsbeschreibung"
                    name="description"
                    className="form-control"
                    value={this.state.description}
                    onChange={this.changeDescriptionNameHandler}
                  />
                </div>
                <div className="form-group  has-success">
                  <label> Durchführung: (*) </label>
                  <select
                    className={"custom-select"}
                    onChange={(evt) => this.changeExecutionHandler(evt)}
                  >
                    {this.state.executionPeriods.map((e, key) => {
                      return (
                        <option key={key} value={e.id}>
                          {t(e.period.toLowerCase())}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group  has-success">
                  <label> Kunde: (*) </label>
                  <select
                    className={"custom-select"}
                    onChange={(evt) => this.changeClientHandler(evt)}
                  >
                    {this.state.clients.map((e, key) => {
                      return (
                        <option key={key} value={e.id}>
                          {e.name} {e.surname}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group  has-success">
                  <label> Adresse: (*) </label>
                  <select
                    className={"custom-select"}
                    onChange={(evt) => this.changeAddressHandler(evt)}
                  >
                    {this.state.addresses &&
                      this.state.addresses.map((e, key) => {
                        return (
                          <option key={key} value={e.id}>
                            {e.street} {e.number} {e.postalCode} {e.city}
                          </option>
                        );
                      })}
                  </select>
                </div>
                {!this.state.byCall && (
                  <div className="form-group  has-success">
                    <label> Zeitraum (*): </label>
                    <ul style={{ display: "flex" }}>
                      <li>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          locale="de"
                          selected={this.state.start}
                          value={this.state.start}
                          onChange={(date) => this.changeDateStartHandler(date)}
                        />
                      </li>
                      <li style={{ marginLeft: "5px", marginTop: "6px" }}>
                        <label> Bis: </label>
                      </li>
                      <li style={{ marginLeft: "5px" }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          locale="de"
                          selected={this.state.end}
                          value={this.state.end}
                          onChange={(date) => this.changeDateEndHandler(date)}
                        />
                      </li>
                    </ul>
                  </div>
                )}
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.byCall}
                      onChange={this.handleCheckboxChangeDuration}
                    />{" "}
                    Auf Abruf
                  </label>
                </div>
                <div>
                  <label> Nettopreis: (*) </label>
                  <input
                    placeholder="Preis"
                    name="Preis"
                    className="form-control"
                    value={this.state.priceWithTax}
                    onChange={this.changePurchasingPriceHandler}
                  />
                </div>
                <div>
                  <label> Steuersatz: (*) </label>
                  <select
                    className={"custom-select"}
                    onChange={(evt) => this.changeTaxFeeHandler(evt)}
                  >
                    {this.state.taxFees.map((e, key) => {
                      if (e.taxFee == this.state.taxFee) {
                        return (
                          <option key={key} value={e.taxFee} selected>
                            {e.value} %
                          </option>
                        );
                      }
                      return (
                        <option key={key} value={e.taxFee}>
                          {e.value} %
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <label> Bruttopreis: (*) </label>
                  <input
                    placeholder="Preis"
                    disabled
                    name="Bruttopreis"
                    className="form-control"
                    value={this.state.priceWithoutTax}
                  />
                </div>
                <div className="form-group  has-success">
                  <label> Leistung </label>
                  <textarea
                    placeholder="Leistung"
                    name="execution"
                    className="form-control"
                    value={this.state.execution}
                    onChange={this.changeExecutionNameHandler}
                  />
                </div>
                {this.state.byContract && (
                  <div className="form-group  has-success">
                    <label> Vertragslaufzeit (In Monaten)</label>
                    <input
                      placeholder="Vertragslaufzeit"
                      name="contractTerm"
                      className="form-control"
                      value={this.state.contractTerm}
                      onChange={this.changeTermHandler}
                    />
                  </div>
                )}
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.byContract}
                      onChange={this.handleCheckboxChangeContract}
                    />{" "}
                    Vertragslaufzeit Optional
                  </label>
                </div>
                <div>
                  <div className="form-group">
                    <label>
                      <input
                        type="checkbox"
                        checked={this.state.showBillingTextarea}
                        onChange={this.handleCheckboxChange}
                      />{" "}
                      Stundenverrechnungssatz anwenden
                    </label>
                  </div>
                  {this.state.showBillingTextarea && (
                    <div className="form-group has-success">
                      <label>Stundenverrechnungssatz</label>
                      <input
                        placeholder="Stundenverrechnungssatz"
                        name="billing"
                        className="form-control"
                        value={this.state.billing}
                        onChange={this.changeBillingHandler}
                      />
                    </div>
                  )}
                  <div className="form-group has-success">
                    <label>
                      Textvorlagen für Leistungen (* kommt ab Seite 3/3)
                    </label>
                    <select
                      multiple
                      placeholder="Textvorlagen"
                      name="billing"
                      className="form-control"
                      value={this.state.selectedTemplates}
                      onChange={this.changeTemplateHandler}
                    >
                      {this.state.templates.map((e, key) => {
                        return (
                          <option key={key} value={e.id} title={e.name}>
                            {e.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group has-success">
                    <label>Fussnoten</label>
                    <select
                      multiple
                      placeholder="Fussnoten"
                      name="billing"
                      className="form-control"
                      value={this.state.selectedFooters}
                      onChange={this.changeFooterHandler}
                    >
                      {this.state.footers.map((e, key) => {
                        return (
                          <option key={key} value={e.id} title={e.name}>
                            {e.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <Icon
                  name="save"
                  tooltip="Speichern"
                  theme="light"
                  size="medium"
                  onClick={this.saveOrUpdate}
                />
                <Icon
                  name="undo"
                  tooltip="Zurück zu den Angeboten"
                  theme="light"
                  size="medium"
                  onClick={this.cancel.bind(this)}
                />
              </div>
            </div>
            <div className="col">
              <p
                style={{
                  textDecoration: "underline",
                  textTransform: "uppercase",
                  marginRight: "10px",
                  marginTop: "10px",
                  marginBottom: "32px",
                }}
              >
                {" "}
                Verkaufsartikel hinzufügen{" "}
              </p>
              <div>
                <label> Verkaufsartikel: (*) </label>
                <br></br>
                <select
                  className={"custom-select"}
                  onChange={(evt) => this.changeShoppingArticleIdHandler(evt)}
                  style={{ width: "300px" }}
                >
                  {this.state.shoppingArticles.map((e, key) => {
                    return (
                      <option key={key} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <label> Menge: (*) </label>
                <input
                  placeholder="Menge"
                  name="Menge"
                  className="form-control"
                  style={{ width: "300px" }}
                  value={this.state.amount}
                  onChange={(evt) => this.changeAmountHandler(evt.target.value)}
                />
              </div>
              <div>
                <label> Netto Einkaufspreis: (*) </label>
                <input
                  placeholder="Preis"
                  name="Preis2"
                  className="form-control"
                  style={{ width: "300px" }}
                  value={this.state.priceWithTaxArticle}
                  onChange={(evt) =>
                    this.changeAmountArticleHandler(evt.target.value)
                  }
                />
              </div>
              <div>
                <label> Steuersatz: (*) </label>
                <br></br>
                <select
                  className={"custom-select"}
                  style={{ width: "300px" }}
                  onChange={(evt) =>
                    this.changeTaxFeeArticleHandler(evt.target.value)
                  }
                >
                  {this.state.taxFees.map((e, key) => {
                    return (
                      <option key={key} value={e.taxFee}>
                        {e.value} %
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <label> Brutto Verkaufspreis: (*) </label>
                <input
                  placeholder="Preis"
                  disabled
                  name="Bruttopreis2"
                  style={{ width: "300px" }}
                  className="form-control"
                  value={this.state.priceWithoutTaxArticle}
                />
              </div>
              <Icon
                name="save"
                tooltip="Einkaufartikel hinzufügen"
                theme="light"
                size="medium"
                onClick={this.saveArticle}
              />
              <span style={{ fontSize: "17px", textDecoration: "underline" }}>
                Einkaufsartikel:
              </span>
              <br></br>
              <br></br>
              {this.state.articles.map((value) => (
                <div>
                  {value.shoppingArticle.name}, <b>Anzahl:</b> {value.amount},{" "}
                  <b>Einzelpreis:</b> {value.priceWithoutTax},{" "}
                  <b>Gesamtpreis:</b> {value.total}{" "}
                  <button onClick={() => this.deleteOne(value.id)}>-</button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
AddOfferComponent.propTypes = {
  t: PropTypes.func.isRequired,
};
export default compose(withRouter)(translate(AddOfferComponent));
