import React from 'react';
import { BrowserRouter as BrowserRouter, Route, Switch } from 'react-router-dom';
import Dashboard from './components/pages/Dashboard';
import Accordions from './components/pages/Accordions';
import Addproduct from './components/pages/Addproduct';
import AddShoppingItem from './components/pages/AddShoppingItem';
import Alerts from './components/pages/Alerts';
import Animations from './components/pages/Animations';
import Badges from './components/pages/Badges';
import Basictables from './components/pages/Basictables';
import Breadcrumbs from './components/pages/Breadcrumbs';
import Buttons from './components/pages/Buttons';
import Cards from './components/pages/Cards';
import Chartjs from './components/pages/Chartjs';
import Chat from './components/pages/Chat';
import Cropper from './components/pages/Cropper';
import Customerlist from './components/pages/Customerlist';
import Customerreview from './components/pages/Customerreview';
import Datatables from './components/pages/Datatables';
import Draggables from './components/pages/Draggables';
import Email from './components/pages/Email';
import Flaticons from './components/pages/Flaticons';
import Fontawesome from './components/pages/Fontawesome';
import Formelements from './components/pages/Formelements';
import Formlayouts from './components/pages/Formlayouts';
import Formvalidation from './components/pages/Formvalidation';
import Formwizard from './components/pages/Formwizard';
import Googlemaps from './components/pages/Googlemaps';
import Invoicedetail from './components/pages/Invoicedetail';
import Invoicelist from './components/pages/Invoicelist';
import Materialize from './components/pages/Materialize';
import Menucatalogue from './components/pages/Menucatalogue';
import Menugrid from './components/pages/Menugrid';
import Menulist from './components/pages/Menulist';
import Modals from './components/pages/Modals';
import Googlechart from './components/pages/Googlechart';
import Orders from './components/pages/Orders';
import Pagination from './components/pages/Pagination';
import Preloaders from './components/pages/Preloaders';
import Productdetail from './components/pages/Productdetail';
import Progressbars from './components/pages/Progressbars';
import Rangeslider from './components/pages/Rangeslider';
import Rating from './components/pages/Rating';
import Restaurantlist from './components/pages/Restaurantlist';
import Sales from './components/pages/Sales';
import Currency from './components/pages/Currency';
import Sliders from './components/pages/Sliders';
import Socialactivity from './components/pages/Socialactivity';
import Sweetalerts from './components/pages/Sweetalerts';
import Tabs from './components/pages/Tabs';
import Toast from './components/pages/Toast';
import Todolist from './components/pages/Todolist';
import Tour from './components/pages/Tour';
import Typography from './components/pages/Typography';
import Vectormaps from './components/pages/Vectormaps';
import Widgets from './components/pages/Widgets';
import Clientmanagement from './components/pages/Clientmanagement';
import Comingsoon from './components/pages/Comingsoon';
import Defaultlogin from './components/pages/Defaultlogin';
import Defaultregister from './components/pages/Defaultregister';
import Error from './components/pages/Error';
import Faq from './components/pages/Faq';
import Invoice from './components/pages/Invoice';
import Lockscreen from './components/pages/Lockscreen';
import Modallogin from './components/pages/Modallogin';
import Modalregister from './components/pages/Modalregister';
import Portfolio from './components/pages/Portfolio';
import Stockmanagement from './components/pages/Stockmanagement';
import Userprofile from './components/pages/Userprofile';
import Webanalytics from './components/pages/Webanalytics';
import Suppliers from './components/pages/Suppliers';
import Drivers from './components/pages/Drivers';
import Toppings from './components/pages/Toppings';
import ShoppingArticles from './components/pages/ShoppingArticles';
import ProductionArticles from './components/pages/ProductionArticles';
import AddproductArticle from './components/pages/AddProductionArticle';
import AddShopping from './components/pages/AddShopping';
import AddShoppingArticleItem from './components/pages/AddShoppingArticleItem';
import AddDriver from './components/pages/AddDriver';
import Delivery from './components/pages/Delivery';
import AssignDriver from './components/pages/AssignDriver';
import CheckinDriver from './components/pages/CheckinDriver';
import CheckoutDriver from './components/pages/CheckoutDriver';
import AddCar from './components/pages/AddCar';
import AddOrder from './components/pages/AddOrder';
import Bill from './components/pages/Bill';
import AddSupplier from './components/pages/AddSupplier';
import DriverPlaning from './components/pages/DriverPlaning';
import UserList from './components/pages/Userlist';
import EditShoppingItem from './components/pages/EditShoppingItem';
import AddSelfOrder from './components/pages/AddSelfOrder';
import AddNote from './components/pages/AddNote';
import DriverTours from './components/pages/DriverTours';
import KitchenManager from './components/pages/KitchenManager';
import EditArticle from './components/pages/EditArticle';
import EditUser from './components/pages/EditUser';
import KitchenMaterial from './components/pages/KitchenMaterial';
import Mail from './components/pages/Mail';
import Offers from './components/pages/Offers';
import CustomerAdd from './components/pages/CustomerAdd';
import AddressAdd from './components/pages/AddressAdd';
import OfferAdd from './components/pages/OfferAdd';
import Sheets from './components/pages/Sheets';
import Times from './components/pages/Times';
import EditCustomer from './components/pages/EditCustomer';
import Footers from './components/pages/Footers';
import AddFooter from './components/pages/AddFooter';
import Templates from './components/pages/Templates';
import AddTemplate from './components/pages/AddTemplate';
import Documents from './components/pages/Documents';
import AddDocument from './components/pages/AddDocument';
import WorksheetAdd from './components/pages/WorksheetAdd';
import EditCompany from './components/pages/EditCompany';
import EditOrder from './components/pages/EditOrder';
import EditWorker from './components/pages/EditWorker';


function App() {
  return (
    <BrowserRouter basename='/app'>
      <Switch>
        <Route exact path="/" component={Orders} />
        <Route exact path="/offers" component={Offers} />
        <Route exact path="/footers" component={Footers} />
        <Route exact path="/templates" component={Templates} />
        <Route exact path="/documents" component={Documents} />
        <Route exact path="/worker-sheets" component={Sheets} />
        <Route exact path ="/worker-times" component={Times} />
        <Route exact path="/statistics" component={Dashboard} />
        <Route path="/accordions" component={Accordions} />
        <Route path="/add-product" component={Addproduct} />
        <Route path="/add-footer" component={AddFooter} />
        <Route path="/add-template" component={AddTemplate} />
        <Route path="/add-document" component={AddDocument} />
        <Route path="/add-production-article" component={AddproductArticle} />
        <Route path="/add-shopping" component={AddShopping} />
        <Route path="/order-add-note/:id" component={AddNote} />
        <Route path="/add-shopping-item/" render={AddShoppingItem}/> 
        <Route path="/edit-shopping-item/:id" render={EditShoppingItem}/>
        <Route path="/edit-worker/:id" render={EditWorker}/>
        <Route path="/company" render={EditCompany}/>
        <Route path="/add-shopping-article-item/:id" render={AddShoppingArticleItem}/> 
        <Route path="/alerts" component={Alerts} />
        <Route path="/animations" component={Animations} />
        <Route path="/badges" component={Badges} />
        <Route path="/basic-tables" component={Basictables} />
        <Route path="/breadcrumbs" component={Breadcrumbs} />
        <Route path="/buttons" component={Buttons} />
        <Route path="/cards" component={Cards} />
        <Route path="/chartjs" component={Chartjs} />
        <Route path="/chat" component={Chat} />
        <Route path="/cropper" component={Cropper} />
        <Route path="/customer-list" component={Customerlist} />
        <Route path="/add-customer" component={CustomerAdd} />
        <Route path="/add-offer" component={OfferAdd} />
        <Route path="/add-worker-sheet" component={WorksheetAdd} />
        <Route path="/add-address/:id" component={AddressAdd} />
        <Route path="/customer-review" component={Customerreview} />
        <Route path="/data-tables" component={Datatables} />
        <Route path="/draggables" component={Draggables} />
        <Route path="/email" component={Email} />
        <Route path="/toppings" component={Toppings} />
        <Route path="/kitchen-manager" component={KitchenManager} />
        <Route path="/kitchen-material" component={KitchenMaterial} />
        <Route path="/edit-article/:id" component={EditArticle} />
        <Route path="/edit-user/:id" component={EditUser} />
        <Route path="/edit-customer/:id" component={EditCustomer} />
        <Route path="/mails" component={Mail} />
        <Route path="/flaticons" component={Flaticons} />
        <Route path="/fontawesome" component={Fontawesome} />
        <Route path="/form-elements" component={Formelements} />
        <Route path="/form-layouts" component={Formlayouts} />
        <Route path="/form-validation" component={Formvalidation} />
        <Route path="/form-wizard" component={Formwizard} />
        <Route path="/google-maps" component={Googlemaps} />
        <Route path="/invoice-detail/:id" render={Invoicedetail} />
        <Route path="/invoice-list" component={Invoicelist} />
        <Route path="/materialize" component={Materialize} />
        <Route path="/menu-catalogue" component={Menucatalogue} />
        <Route path="/menu-grid" component={Menugrid} />
        <Route path="/menu-list" component={Menulist} />
        <Route path="/modals" component={Modals} />
        <Route path="/google-chart" component={Googlechart} />
        <Route path="/add-order" component={AddOrder} />
        <Route path="/edit-order/:id" component={EditOrder} />
        <Route path="/add-self-order" component={AddSelfOrder} />
        <Route path="/generate-invoice/" component={Bill} />
        <Route path="/driver-planing/" component={DriverPlaning} />
        <Route path="/driver-tour/" component={DriverTours} />
        <Route path="/add-supplier/" component={AddSupplier} />
        <Route path="/pagination" component={Pagination} />
        <Route path="/preloaders" component={Preloaders} />
        <Route path="/product-detail" component={Productdetail} />
        <Route path="/progress-bars" component={Progressbars} />
        <Route path="/range-slider" component={Rangeslider} />
        <Route path="/rating" component={Rating} />
        <Route path="/restaurant-list/" component={Restaurantlist} />
        <Route path="/user-list/" component={UserList} />
        <Route path="/currency/" component={Currency} />
        <Route path="/shopping" component={Sales} />
        <Route path="/login" component={Defaultlogin} />
        <Route path="/delivery" component={Delivery} />
        <Route path="/add-worker" component={AddDriver} />
        <Route path="/add-car" component={AddCar} />
        <Route path="/checkin-user" component={CheckinDriver} />
        <Route path="/checkout-user" component={CheckoutDriver} />
        <Route path="/assign-driver" component={AssignDriver} />
        <Route path="/shopping-articles" component={ShoppingArticles} />
        <Route path="/production-articles" component={ProductionArticles} />
        <Route path="/suppliers" component={Suppliers} />
        <Route path="/workers" component={Drivers} />
        <Route path="/sliders" component={Sliders} />
        <Route path="/social-activity" component={Socialactivity} />
        <Route path="/sweet-alerts" component={Sweetalerts} />
        <Route path="/tabs" component={Tabs} />
        <Route path="/toast" component={Toast} />
        <Route path="/todo-list" component={Todolist} />
        <Route path="/tour" component={Tour} />
        <Route path="/typography" component={Typography} />
        <Route path="/vector-maps" component={Vectormaps} />
        <Route path="/widgets" component={Widgets} />
        <Route path="/client-management" component={Clientmanagement} />
        <Route path="/coming-soon" component={Comingsoon} />
        <Route path="/default-login" component={Defaultlogin} />
        <Route path="/default-register" component={Defaultregister} />
        <Route path="/error" component={Error} />
        <Route path="/faq" component={Faq} />
        <Route path="/invoice" component={Invoice} />
        <Route path="/lock-screen" component={Lockscreen} />
        <Route path="/modal-login" component={Modallogin} />
        <Route path="/modal-register" component={Modalregister} />
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/stock-management" component={Stockmanagement} />
        <Route path="/user-profile" component={Userprofile} />
        <Route path="/web-analytics" component={Webanalytics} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
