import React, { Component } from "react";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import $ from "jquery";
import ArticleService from "../../../services/ArticleService";
import ReactDOM from "react-dom";
import Icon from "react-crud-icons";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Redirect } from "react-router-dom";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import Cookies from "universal-cookie";
import ReactCountryFlag from "react-country-flag";
import CurrencyService from "../../../services/CurrencyService";
import { ListContent } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";
const cookies = new Cookies();

class Listcontent extends Component {
  componentDidMount() {
    CurrencyService.getActualCurrency(3)
      .then((res2) => {
        ArticleService.getArticles().then((res) => {
          const columns = [
            {
              title: "Nummer",
              data: "nr",
            },
            {
              title: "Name",
              data: "name",
            },
            {
              title: "Preis",
              data: "priceWithoutTax",
            },
            {
              title: "Artikelgruppe",
              data: "subName",
            },
            {
              title: "Beschreibung",
              data: "description",
            },
            {
              title: "Status",
              data: "active",
            },
            {
              title: "Verfügbarkeit",
              data: "availability",
            },
            {
              title: "Allergene",
              data: "allergenic",
            },
            {
              title: "Max pro Kunde",
              data: "maxPerClient",
            },
            {
              title: "Zubereitungszeit (Min)",
              data: "makingDuration",
            },
            {
              title: "Operationen",
              data: "id",
            },
          ];
          $("#data-table-5").DataTable({
            data: res.data,
            columns: columns,
            autoWidth: false,
            language: {
              url: "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json",
            },
            columnDefs: [
              {
                targets: [5],
                width: 50,
                createdCell: (td, cellData, rowData, row, col) => {
                  ReactDOM.render(
                    rowData.active == false ? (
                      <div>
                        Inaktiv
                        <Icon
                          name="check"
                          tooltip="Aktivieren"
                          theme="light"
                          size="medium"
                          onClick={() => this.activate(rowData.id)}
                        />
                      </div>
                    ) : (
                      <div>
                        Aktiv
                        <Icon
                          name="hide"
                          tooltip="Deaktivieren"
                          theme="light"
                          size="medium"
                          onClick={() => this.deactivate(rowData.id)}
                        />
                      </div>
                    ),
                    td
                  );
                },
              },
              {
                render: function (data) {
                  return Array.prototype.map
                    .call(data, function (item) {
                      return item.name;
                    })
                    .join(", ");
                },
                targets: [7],
              },
              {
                createdCell: (td, cellData, rowData, row, col) => {
                  ReactDOM.render(
                    <div>
                      <ReactCountryFlag
                        style={{ fontSize: "12px" }}
                        countryCode="DE"
                        svg
                      />
                      {rowData.name}
                      <br></br>
                      <ReactCountryFlag
                        style={{ fontSize: "12px" }}
                        countryCode="FR"
                        svg
                      />
                      {rowData.nameFr}
                      <br></br>
                      <ReactCountryFlag
                        style={{ fontSize: "12px" }}
                        countryCode="GB"
                        svg
                      />
                      {rowData.nameEn}
                    </div>,
                    td
                  );
                },
                targets: [1],
              },
              {
                createdCell: (td, cellData, rowData, row, col) => {
                  ReactDOM.render(
                    <div>
                      <ReactCountryFlag
                        style={{ fontSize: "12px" }}
                        countryCode="DE"
                        svg
                      />
                      {rowData.description}
                      <br></br>
                      <ReactCountryFlag
                        style={{ fontSize: "12px" }}
                        countryCode="FR"
                        svg
                      />
                      {rowData.descriptionFr}
                      <br></br>
                      <ReactCountryFlag
                        style={{ fontSize: "12px" }}
                        countryCode="GB"
                        svg
                      />
                      {rowData.descriptionEn}
                    </div>,
                    td
                  );
                },
                targets: [4],
              },
              {
                createdCell: (td, cellData, rowData, row, col) => {
                  ReactDOM.render(
                    <div>
                      {rowData.priceWithoutTax} {res2.data.newValue}
                      <br></br>
                      {rowData.prices &&
                        Object.values(rowData.prices).map((i, key) => {
                          return (
                            <div key={key}>
                              {i} {res2.data.newValue}
                            </div>
                          );
                        })}
                    </div>,
                    td
                  );
                },
                targets: [2],
              },
              {
                targets: [4],
                width: 50,
                createdCell: (td, cellData, rowData, row, col) => {
                  ReactDOM.render(
                    <div>
                      <FontAwesomeIcon title="Standard" icon={faFile} />{" "}
                      {rowData.description}
                      <br></br>
                      <FontAwesomeIcon
                        title="Für Küche"
                        icon={faUtensils}
                      />{" "}
                      {rowData.infoKitchen}
                      <br></br>
                      <FontAwesomeIcon
                        title="Für Portal"
                        icon={faBagShopping}
                      />{" "}
                      {rowData.infoPortal}
                    </div>,
                    td
                  );
                },
              },
              {
                targets: [9],
                width: 50,
                createdCell: (td, cellData, rowData, row, col) => {
                  ReactDOM.render(
                    <div>
                      {rowData.makingDuration}
                      <span> </span>
                      <FontAwesomeIcon size="2x" icon={faStopwatch} />
                    </div>,
                    td
                  );
                },
              },
              {
                targets: [10],
                width: 50,
                createdCell: (td, cellData, rowData, row, col) => {
                  ReactDOM.render(
                    <div>
                      <Icon
                        name="edit"
                        tooltip="Artikel bearbeiten"
                        theme="light"
                        size="medium"
                        onClick={() => this.editArticle(rowData.id)}
                      />
                    </div>,
                    td
                  );
                },
              },
            ],
          });
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  activate(id) {
    ArticleService.activateArticle(id).then((res) => {
      window.location.reload();
    });
  }

  deactivate(id) {
    ArticleService.deactivateArticle(id).then((res) => {
      window.location.reload();
    });
  }

  onChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let sheetData = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
        blankrows: false,
      });
      console.log(sheetData);
      for (var i = 1; i < sheetData.length; i++) {
        let c = {
          name: sheetData[i][0],
          description: sheetData[i][1],
          priceWithoutTax: sheetData[i][2],
          nr: sheetData[i][6],
        };
        console.log("Artikel => " + JSON.stringify(c));
        ArticleService.saveArticle(
          c,
          sheetData[i][4],
          sheetData[i][5].length <= 0 ? "NONE" : sheetData[i][5]
        ).then((res2) => {});
      }
    };
    reader.readAsBinaryString(file);
  };

  onChangePrice = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let sheetData = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
        blankrows: false,
      });
      for (var i = 1; i < sheetData.length; i++) {
        ArticleService.saveArticlePrice(
          sheetData[i][0],
          "Sami 2",
          sheetData[i][2],
          sheetData[i][3],
          sheetData[i][4]
        ).then((res2) => {});
      }
    };
    reader.readAsBinaryString(file);
  };

  onChangeLanguage = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let sheetData = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
        blankrows: false,
      });
      for (var i = 1; i < sheetData.length; i++) {
        let p = {
          name: sheetData[i][0],
          nameEn: sheetData[i][1],
          descriptionEn: sheetData[i][2],
          nameFr: sheetData[i][3],
          descriptionFr: sheetData[i][4],
        };
        ArticleService.saveArticleLanguage(p).then((res2) => {});
      }
    };
    reader.readAsBinaryString(file);
  };

  onChangeDuration = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let sheetData = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
        blankrows: false,
      });
      for (var i = 1; i < sheetData.length; i++) {
        ArticleService.saveArticleDuration(
          sheetData[i][6],
          sheetData[i][7]
        ).then((res2) => {});
      }
    };
    reader.readAsBinaryString(file);
  };

  onchangeStation = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let sheetData = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
        blankrows: false,
      });
      for (var i = 1; i < sheetData.length; i++) {
        if (sheetData[i][1].length > 0) {
          ArticleService.saveArticleStation(
            sheetData[i][1],
            sheetData[i][2]
          ).then((res2) => {});
        }
      }
    };
    reader.readAsBinaryString(file);
  };

  onchangeMaterial = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let sheetData = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
        blankrows: false,
      });
      for (var i = 1; i < sheetData.length; i++) {
        if (sheetData[i][1].length > 0 && sheetData[i][3].length > 0) {
          ArticleService.saveArticleMaterial(
            sheetData[i][1],
            sheetData[i][3]
          ).then((res2) => {});
        }
      }
    };
    reader.readAsBinaryString(file);
  };

  onchangeMaterialCapacity = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let sheetData = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
        blankrows: false,
      });
      for (var i = 1; i < sheetData.length; i++) {
        console.log("HEY");
        if (sheetData[i][1].length > 0) {
          ArticleService.saveArticleMaterialCapacity(
            sheetData[i][1],
            sheetData[i][4]
          ).then((res2) => {});
        }
      }
    };
    reader.readAsBinaryString(file);
  };

  editArticle(id) {
    this.props.history.push("/edit-article/" + id);
  }

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div className="ms-content-wrapper">
        <div style={{ marginLeft: "300px", marginBottom: "20px" }}>
          <p>Artikel importieren</p>
          <input type="file" onChange={this.onChange} />
        </div>
        <div style={{ marginLeft: "300px", marginBottom: "20px" }}>
          <p>Artikelpreise importieren</p>
          <input type="file" onChange={this.onChangePrice} />
        </div>
        <div style={{ marginLeft: "300px", marginBottom: "20px" }}>
          <p>Artikelübersetzungen importieren</p>
          <input type="file" onChange={this.onChangeLanguage} />
        </div>
        <div style={{ marginLeft: "300px", marginBottom: "20px" }}>
          <p>Artikelzubereitungszeit importieren</p>
          <input type="file" onChange={this.onChangeDuration} />
        </div>
        <div style={{ marginLeft: "300px", marginBottom: "20px" }}>
          <p>Artikelstation Zuweisungen importieren</p>
          <input type="file" onChange={this.onchangeStation} />
        </div>
        <div style={{ marginLeft: "300px", marginBottom: "20px" }}>
          <p>Arbeitsmittel für Artikel Zuweisungen importieren</p>
          <input type="file" onChange={this.onchangeMaterial} />
        </div>
        <div style={{ marginLeft: "300px", marginBottom: "20px" }}>
          <p>Arbeitsmittel Kapazitäten importieren</p>
          <input type="file" onChange={this.onchangeMaterialCapacity} />
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-header">
                <h6>
                  Verkaufsartikel<br></br>
                  <Link
                    style={{ fontSize: "25px", color: "black" }}
                    title="Neues Verkaufsartikel anlegen"
                    to={"/add-product"}
                  >
                    +
                  </Link>
                </h6>
              </div>
              <div className="ms-panel-body">
                <div className="table-responsive">
                  <table
                    id="data-table-5"
                    className="table w-100 thead-primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ListContent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(withRouter)(translate(Listcontent));
