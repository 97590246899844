import React, { Component } from "react";
import ArticleService from "../services/ArticleService";
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import Countries from "react-select-country";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import ProductsService from "../services/ProductsService";
import ProducentService from "../services/ProducentService";
import FileBase64 from "react-file-base64";
import Multiselect from "multiselect-react-dropdown";
import OrderService from "../services/OrderService";
import Button from "@material-ui/core/Button";
import Grid from "@mui/material/Unstable_Grid2";
import randomColor from "randomcolor";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import update from "immutability-helper";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

class CreateArticleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedTicket: true,
      files: [],
      components: [],
      selectedComponents: [],
      name: "",
      nr: "",
      infoPortal: "",
      infoKitchen: "",
      maxPerClient: 0,
      taxFee: 0,
      priceWithTax: 0,
      priceWithoutTax: 0,
      transportVolume: 0,
      makingDuration: 0,
      validityDate: null,
      transportWeight: 0.0,
      deposit: 0.0,
      producerName: "",
      producerCountry: "",
      productGroups: [],
      productCategories: [],
      productTypes: [],
      productGroup: 0,
      productCategory: 0,
      productType: 0,
      taxFees: [],
      stores: [],
      selectedStores: [],
      payments: [],
      selectedPayments: [],
    };
  }

  saveOrUpdate = (e) => {
    e.preventDefault();
    var newData = [];
    this.state.selectedPayments.forEach((element) => {
      var pos = element.name;
      newData.push(pos);
    });
    let a = {
      name: this.state.name,
      nr: this.state.nr,
      maxPerClient: this.state.maxPerClient,
      priceWithTax: this.state.priceWithTax,
      priceWithoutTax: this.state.priceWithoutTax,
      taxFee: this.state.taxFee,
      transportVolume: this.state.transportVolume,
      makingDuration: this.state.makingDuration,
      validityDate: this.state.validityDate,
      transportWeight: this.state.transportWeight,
      deposit: this.state.deposit,
      producerName: this.state.producerName,
      infoPortal: this.state.infoPortal,
      infoKitchen: this.state.infoKitchen,
      producerCountry: this.state.producerCountry,
      picture: this.state.files[0].base64,
      stores: this.state.selectedStores,
      payments: newData,
    };
    var newIds = [];
    this.state.components
      .filter((one) => one.checked == true)
      .forEach((element) => {
        var pos = element.id;
        newIds.push(pos);
      });
    ArticleService.create(a, this.state.productType, newIds).then((res) => {
      this.props.history.push("/menu-list/");
    });
  };

  findOne(list, id) {
    return list.filter((item) => {
      return item.id == id;
    });
  }

  changeSearchHandler(event) {
    var serachName = event.target.value;
    ArticleService.getAllProductionArticles().then((res4) => {
      var newData = [];
      this.state.components.forEach((element) => {
        if (element.checked) {
          newData.push(element);
        }
      });
      res4.data.forEach((element) => {
        var pos = {
          id: element.id,
          name: element.name,
          checked: false,
          color: randomColor(),
          amount: 0,
          shoppingArticleList: element.shoppingArticleList,
        };
        if (
          this.findOne(newData, element.id).length == 0 &&
          element.name.toLowerCase().includes(serachName.toLowerCase()) &&
          serachName.length > 0
        ) {
          newData.push(pos);
        }
      });
      this.setState({
        components: newData,
      });
    });
  }

  componentDidMount() {
    ProducentService.getStores(1).then((res0) => {
      ProductsService.getProducts(res0.data[0].id).then((res) => {
        ArticleService.getAllTaxFees().then((res2) => {
          OrderService.getPaymentModes().then((res3) => {
            this.setState({
              stores: res0.data,
              productGroups: res.data[0].products,
              productGroup: res.data[0].products[0].id,
              productCategories: res.data[0].products[0].groups,
              productTypes: res.data[0].products[0].groups[0].subs,
              productType: res.data[0].products[0].groups[0].subs[0].id,
              taxFees: res2.data,
              payments: res3.data,
            });
          });
        });
      });
    });
  }

  changeNameHandler = (event) => {
    this.setState({ name: event.target.value });
  };

  changeInfoPortalHandler = (event) => {
    this.setState({ infoPortal: event.target.value });
  };

  changeInfoKitchenHandler = (event) => {
    this.setState({ infoKitchen: event.target.value });
  };

  changeNrHandler = (event) => {
    this.setState({ nr: event.target.value });
  };

  changeMaxPerClientHandler = (event) => {
    this.setState({ maxPerClient: event.target.value });
  };

  changeTaxFeeHandler = (event) => {
    var taxfeeNew = event.target.value;
    var priceWithTax = this.state.priceWithTax;
    if (this.findTaxFee(taxfeeNew)[0] != undefined) {
      this.setState({
        taxFee: taxfeeNew,
        priceWithoutTax: Number(
          Number(priceWithTax) +
            (priceWithTax * this.findTaxFee(taxfeeNew)[0].value) / 100
        ).toFixed(2),
      });
    }
  };

  changePurchasingPriceHandler = (event) => {
    var taxfeeNew = this.state.taxFee;
    var priceWithTaxNew = event.target.value;
    if (this.findTaxFee(taxfeeNew)[0] != undefined) {
      this.setState({
        priceWithTax: priceWithTaxNew,
        priceWithoutTax: Number(
          Number(priceWithTaxNew) +
            (priceWithTaxNew * this.findTaxFee(taxfeeNew)[0].value) / 100
        ).toFixed(2),
      });
    }
  };

  changeTransportVolumeHandler = (event) => {
    this.setState({ transportVolume: event.target.value });
  };

  changeMakingDurationHandler = (event) => {
    this.setState({ makingDuration: event.target.value });
  };

  changeValidityDateHandler = (value) => {
    this.setState({ validityDate: value });
  };

  changeTransportWeightHandler = (event) => {
    this.setState({ transportWeight: event.target.value });
  };

  changeDepositHandler = (event) => {
    this.setState({ deposit: event.target.value });
  };

  changeProducerNameHandler = (event) => {
    this.setState({ producerName: event.target.value });
  };

  changeProducerCountryHandler = (event) => {
    this.setState({ producerCountry: event.target.value });
  };

  onDropdownSelectedProductGroup = (event) => {
    var productGroupEv = event.target.value;
    this.setState({
      productGroup: productGroupEv,
      productCategories: this.find(productGroupEv)[0].groups,
      productTypes: this.find(productGroupEv)[0].groups[0].subs,
      productType: this.find(productGroupEv)[0].groups[0].subs[0].id,
    });
  };

  find(idToSearch) {
    return this.state.productGroups.filter((item) => {
      return item.id == idToSearch;
    });
  }

  getPositionData(a) {
    var element = this.state.components.find((one) => {
      return one.id === a.id;
    });
    return element;
  }

  findTaxFee(taxFee) {
    return this.state.taxFees.filter((item) => {
      return item.taxFee == taxFee;
    });
  }

  onDropdownSelectedProductCategory = (event) => {
    var productCategoryEv = event.target.value;
    this.setState({
      productCategory: productCategoryEv,
      productTypes: this.find(productCategoryEv)[0].subs,
      productType: this.find(productCategoryEv)[0].subs[0].id,
    });
  };

  getFiles(files) {
    this.setState({ files: files });
  }

  onDropdownSelectedProductType = (event) => {
    var productTypeEv = event.target.value;
    this.setState({
      productType: productTypeEv,
    });
  };

  cancel() {
    this.props.history.push("/menu-list/");
  }

  addStoreHandler = (selectedList) => {
    var self = this;
    self.setState({
      selectedStores: selectedList,
    });
  };

  removeStoreHandler = (selectedList) => {
    var self = this;
    self.setState({
      selectedStores: selectedList,
    });
  };

  addPaymentHandler = (selectedList) => {
    var self = this;
    console.log(selectedList);
    self.setState({
      selectedPayments: selectedList,
    });
  };

  removePaymentHandler = (selectedList) => {
    var self = this;
    self.setState({
      selectedPayments: selectedList,
    });
  };

  changeActiveHandler(a, checked) {
    var data = this.state.components;
    var element = data.find((element) => {
      return element.id === a.id;
    });
    var updated = update(element, { checked: { $set: checked } });
    var newData = update(data, {
      $splice: [[this.state.components.indexOf(element), 1, updated]],
    });
    this.setState({ components: newData });
  }

  handleIncrement(a) {
    var data = this.state.components;
    var element = data.find((element) => {
      return element.id === a.id;
    });
    var updated = update(element, { amount: { $set: element.amount + 100 } });
    var newData = update(data, {
      $splice: [[this.state.components.indexOf(element), 1, updated]],
    });
    this.setState({ components: newData });
  }

  handleDecrement(a) {
    var data = this.state.components;
    var element = data.find((element) => {
      return element.id === a.id;
    });
    var updated = update(element, { amount: { $set: element.amount - 100 } });
    var newData = update(data, {
      $splice: [[this.state.components.indexOf(element), 1, updated]],
    });
    this.setState({ components: newData });
  }

  toggleExpandedTicket() {
    this.setState({ expandedTicket: !this.state.expandedTicket });
  }

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <br></br>
        <div className="ms-content-wrapper">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <label style={{ fontSize: "17px", textDecoration: "underline" }}>
                Artikeldaten:
              </label>
              <div className="form-group">
                <label> Name: (*) </label>
                <input
                  placeholder="Name"
                  name="name"
                  className="form-control"
                  value={this.state.name}
                  onChange={this.changeNameHandler}
                />
              </div>
              <div className="form-group">
                <label> Nummer: (*) </label>
                <input
                  placeholder="Nummer"
                  name="name"
                  className="form-control"
                  value={this.state.nr}
                  onChange={this.changeNrHandler}
                />
              </div>
              <div className="form-group">
                <label> Beschreibung für Portal: </label>
                <input
                  placeholder="Beschreibung für Portal"
                  name="name"
                  className="form-control"
                  value={this.state.infoPortal}
                  onChange={this.changeInfoPortalHandler}
                />
              </div>
              <div className="form-group">
                <label> Beschreibung für Küche: </label>
                <input
                  placeholder="Beschreibung für Küche"
                  name="name"
                  className="form-control"
                  value={this.state.infoKitchen}
                  onChange={this.changeInfoKitchenHandler}
                />
              </div>
              <div className="form-group">
                <label>Verfügbar in Stores</label>
                <Multiselect
                  style={{
                    chips: {
                      background: "#ff6877",
                    },
                    multiselectContainer: {
                      color: "#ff6877",
                    },
                    searchBox: {
                      border: "none",
                      borderRadius: "0px",
                    },
                  }}
                  options={this.state.stores} // Options to display in the dropdown
                  selectedValues={this.state.selectedStores} // Preselected value to persist in dropdown
                  onSelect={this.addStoreHandler} // Function will trigger on select event
                  onRemove={this.removeStoreHandler} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </div>
              <div className="form-group">
                <label>Verfügbar bei Zahlungsarten</label>
                <Multiselect
                  style={{
                    chips: {
                      background: "#ff6877",
                    },
                    multiselectContainer: {
                      color: "#ff6877",
                    },
                    searchBox: {
                      border: "none",
                      borderRadius: "0px",
                    },
                  }}
                  options={this.state.payments} // Options to display in the dropdown
                  selectedValues={this.state.selectedPayments} // Preselected value to persist in dropdown
                  onSelect={this.addPaymentHandler} // Function will trigger on select event
                  onRemove={this.removePaymentHandler} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </div>
              <div className="form-group">
                <label
                  style={{ fontSize: "17px", textDecoration: "underline" }}
                >
                  {" "}
                  Rezept (Produktionsartikel){" "}
                </label>
                <div style={{ marginBottom: "8px" }}>
                  <input
                    style={{ width: "250px" }}
                    placeholder="Suchen"
                    className="form-control"
                    onChange={(evt) => this.changeSearchHandler(evt)}
                  />
                </div>
                <Grid
                  container
                  spacing={{ xs: 4, md: 1 }}
                  columns={{ xs: 4, sm: 12, md: 12 }}
                >
                  {this.state.components.map((one, index) => (
                    <Grid xs={3} sm={2} md={3.5} key={index}>
                      <Item
                        style={{
                          backgroundColor: one.checked
                            ? this.getPositionData(one).color
                            : "lightGrey",
                        }}
                      >
                        {one.name}
                        <br></br>
                        {one.shoppingArticleList &&
                          one.shoppingArticleList.map((shoppingArticle) => (
                            <div style={{ lineHeight: "70%" }}>
                              <span style={{ fontSize: "10px" }}>
                                {shoppingArticle.shoppingArticle.name}{" "}
                                {shoppingArticle.quantity}{" "}
                                {
                                  shoppingArticle.shoppingArticle
                                    .shoppingArticleUnit
                                }{" "}
                              </span>
                              <br></br>
                            </div>
                          ))}

                        <br></br>
                        <input
                          style={{ width: "20px", height: "20px" }}
                          type="checkbox"
                          checked={one.checked}
                          onChange={(event) =>
                            this.changeActiveHandler(one, event.target.checked)
                          }
                        />
                        <br></br>
                        {one.checked && (
                          <div>
                            <ButtonGroup
                              size="small"
                              aria-label="small outlined button group"
                            >
                              {this.getPositionData(one) &&
                                this.getPositionData(one).amount > 0 && (
                                  <Button
                                    onClick={() => this.handleDecrement(one)}
                                  >
                                    -
                                  </Button>
                                )}
                              {this.getPositionData(one) &&
                                this.getPositionData(one).amount > 0 && (
                                  <Button disabled>
                                    {this.getPositionData(one).amount}
                                  </Button>
                                )}
                              {this.getPositionData(one).amount <
                                this.getPositionData(one).quantity && (
                                <Button
                                  onClick={() => this.handleIncrement(one)}
                                >
                                  +
                                </Button>
                              )}
                            </ButtonGroup>
                            <br></br>
                            {one.shoppingArticleUnit}
                          </div>
                        )}
                      </Item>
                    </Grid>
                  ))}
                </Grid>
                <br></br>
                <Icon
                  name="save"
                  tooltip="Speichern"
                  theme="light"
                  size="medium"
                  onClick={this.saveOrUpdate}
                />
                <Icon
                  name="undo"
                  tooltip="Zurück zu den Artikeln"
                  theme="light"
                  size="medium"
                  onClick={this.cancel.bind(this)}
                />
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div>
                <label
                  style={{ fontSize: "17px", textDecoration: "underline" }}
                >
                  {" "}
                  Artikelpreis:{" "}
                </label>
                <div className="form-group">
                  <label> Netto Verkaufspreis: (*) </label>
                  <input
                    placeholder="Preis"
                    name="Preis"
                    className="form-control"
                    value={this.state.priceWithTax}
                    onChange={this.changePurchasingPriceHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Steuersatz: (*) </label>
                  <select
                    className={"custom-select"}
                    onChange={(evt) => this.changeTaxFeeHandler(evt)}
                  >
                    {this.state.taxFees.map((e, key) => {
                      return (
                        <option key={key} value={e.taxFee}>
                          {e.value} %
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label> Brutto Verkaufspreis: (*) </label>
                  <input
                    placeholder="Preis"
                    disabled
                    name="Bruttopreis"
                    className="form-control"
                    value={this.state.priceWithoutTax}
                  />
                </div>
              </div>
              <div>
                <label
                  style={{ fontSize: "17px", textDecoration: "underline" }}
                >
                  {" "}
                  Artikelfoto:{" "}
                </label>
                <div className="form-group">
                  <FileBase64
                    multiple={true}
                    onDone={this.getFiles.bind(this)}
                  />
                </div>
                <div className="text-center">
                  {this.state.files.length <= 1 &&
                    this.state.files.map((file, i) => {
                      return (
                        <div style={{ marginBottom: "10px" }}>
                          <img
                            width={"100px"}
                            height={"100px"}
                            alt={file.name}
                            key={i}
                            src={file.base64}
                          />
                        </div>
                      );
                    })}
                  <img src="" alt="" />
                </div>
              </div>
              <div>
                <label
                  style={{ fontSize: "17px", textDecoration: "underline" }}
                >
                  {" "}
                  Artikelgruppe:{" "}
                </label>
                <div className="form-group">
                  <select
                    className={"custom-select"}
                    value={this.state.productGroup}
                    onChange={(evt) => this.onDropdownSelectedProductGroup(evt)}
                  >
                    {this.state.productGroups.map((e, key) => {
                      return (
                        <option key={key} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label> Artikelkategorie: </label>
                  <select
                    className={"custom-select"}
                    value={this.state.cId}
                    onChange={(evt) =>
                      this.onDropdownSelectedProductCategory(evt)
                    }
                  >
                    {this.state.productCategories.map((e, key) => {
                      return (
                        <option key={key} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label> Artikelart: </label>
                  <select
                    className={"custom-select"}
                    value={this.state.cId}
                    onChange={(evt) => this.onDropdownSelectedProductType(evt)}
                  >
                    {this.state.productTypes.map((e, key) => {
                      return (
                        <option key={key} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div>
                <label
                  style={{ fontSize: "17px", textDecoration: "underline" }}
                >
                  {" "}
                  Artikelzusatzdaten (Optional):{" "}
                </label>
                <Icon
                  name="expand"
                  tooltip="Anzeigen"
                  theme="light"
                  size="small"
                  onClick={(evt) => this.toggleExpandedTicket(evt)}
                />
                <div
                  className={
                    this.state.expandedTicket
                      ? "expanded-ticket"
                      : "normal-ticket"
                  }
                >
                  <div className="form-group">
                    <label> Pfand: </label>
                    <input
                      placeholder="Herstellername"
                      name="Herstellername"
                      className="form-control"
                      value={this.state.deposit}
                      onChange={this.changeDepositHandler}
                    />
                  </div>
                  <div className="form-group">
                    <label> Maximum pro Kunde: (*) </label>
                    <input
                      placeholder="Maximum pro Kunde"
                      name="Maximum pro Kunde"
                      className="form-control"
                      value={this.state.maxPerClient}
                      onChange={this.changeMaxPerClientHandler}
                    />
                  </div>
                  <div className="form-group">
                    <label> Zubereitungszeit (in Minuten): </label>
                    <input
                      placeholder="Zubereitungszeit"
                      name="Zubereitungszeit"
                      className="form-control"
                      value={this.state.makingDuration}
                      onChange={this.changeMakingDurationHandler}
                    />
                  </div>
                  <div className="form-group">
                    <label> Haltbarkeitsdatum: </label>
                    <br></br>
                    <DatePicker
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      locale="de"
                      value={this.state.validityDate}
                      selected={this.state.validityDate}
                      onChange={(date) => this.changeValidityDateHandler(date)}
                    />
                  </div>
                  <div className="form-group">
                    <label> Transportgewicht (in Gramm): </label>
                    <input
                      placeholder="Transportgewicht (in Gramm)"
                      name="Transportgewicht (in Gramm)"
                      className="form-control"
                      value={this.state.transportWeight}
                      onChange={this.changeTransportWeightHandler}
                    />
                  </div>
                  <div className="form-group">
                    <label> Transportvolumen (in cm3): </label>
                    <input
                      placeholder="Transportvolumen (in cm3)"
                      name="Transportvolumen (in cm3)"
                      className="form-control"
                      value={this.state.transportVolume}
                      onChange={this.changeTransportVolumeHandler}
                    />
                  </div>
                  <div className="form-group">
                    <label> Herstellername: </label>
                    <input
                      placeholder="Herstellername"
                      name="Herstellername"
                      className="form-control"
                      value={this.state.producerName}
                      onChange={this.changeProducerNameHandler}
                    />
                  </div>
                  <div className="form-group">
                    <label> Herstellerland: </label>
                    <Countries
                      className="form-control"
                      ref="cList"
                      name="country"
                      empty=" -- Land auswählen --"
                      onChange={this.changeProducerCountryHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withRouter)(CreateArticleComponent);
