import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";
import $ from "jquery";
import Cookies from "universal-cookie";
import UserService from "../../../services/UserService";
import Icon from "react-crud-icons";
import { withRouter } from "react-router-dom";
import Moment from "moment";
import compose from "recompose/compose";
import { translate } from "react-switch-lang";
const cookies = new Cookies();

class Usercontent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  addstars = (e) => {
    var elem = e.target,
      parentTask = elem.closest(".ms-rating-item");
    $(parentTask).prevAll().removeClass("rated");
    $(parentTask).addClass("rated");
    $(parentTask).nextAll().addClass("rated");
  };

  componentDidMount() {
    UserService.getAll().then((res1) => {
      this.setState({
        users: res1.data,
      });
    });
  }

  editUser(id) {
    this.props.history.push("/edit-user/" + id);
  }
  

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb />
            <div className="row">
              <div className="col-xl-12">
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6>Benutzer</h6>
                  </div>
                  <div className="ms-panel-body">
                    <div className="table-responsive">
                      <table className="table table-hover thead-primary">
                        <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Aktiv</th>
                            <th scope="col">Rolle</th>
                            <th scope="col">Passwort(encypted)</th>
                            <th scope="col">Station</th>
                            <th scope="col">Zuletzt eingecheckt am</th>
                            <th scope="col">Zuletzt ausgecheckt am</th>
                            <th scope="col">Bearbeiten / Löschen</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.users.map((item, i) => (
                            <tr key={i}>
                              <th scope="row">{item.id}</th>
                              <td>{item.name}</td>
                              <td>{item.active == 1 ? "JA" : "NEIN"}</td>
                              <td>{item.roles && item.roles}</td>
                              <td>{item.password}</td>
                              <td>{item.station && item.station.name}</td>
                              <td>
                                {item.checkedTime &&
                                  Moment(item.checkedTime).format(
                                    "D/M/YYYY HH:mm"
                                  )}
                              </td>
                              <td>
                                {item.checkedOutTime &&
                                  Moment(item.checkedOutTime).format(
                                    "D/M/YYYY HH:mm"
                                  )}
                              </td>
                              <td>
                                <button
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      item.passwordClean
                                    );
                                  }}
                                >
                                  Kopieren
                                </button>
                                <Icon
                                  name="edit"
                                  tooltip="User bearbeiten"
                                  theme="light"
                                  size="medium"
                                  onClick={() => this.editUser(item.id)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withRouter)(translate(Usercontent));
