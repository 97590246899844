import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL + "/api/v1/bills";
const API_BASE_URL_REF = process.env.REACT_APP_API_URL + "/api/v1";

class ArticleService {
  async getArticles() {
    const res = await axios.get(API_BASE_URL, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        "Cache-Control": "no-cache",
        Connection: "keep-alive",
      },
    });
    return res;
  }

  getArticlesById(id) {
    return axios.get(API_BASE_URL + "/store/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getAllSubs(id) {
    return axios.get(API_BASE_URL + "/subs/store/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  create(article, type, products) {
    return axios.post(API_BASE_URL + "/" + type + "/" + products, article, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  createProductionArticle(name, nr, components) {
    return axios.post(
      API_BASE_URL + "/components/" + name + "/" + nr,
      components,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  getAllProductionArticles() {
    return axios.get(API_BASE_URL + "/components/production/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  createMenu(article, children, sub) {
    return axios.post(
      API_BASE_URL + "/parent/" + sub + "/" + children,
      article,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  increaseAvail(id) {
    return axios.post(
      API_BASE_URL + "/increaseAvail/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  getAllTaxFees() {
    return axios.get(API_BASE_URL + "/taxfees", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getAllLimits() {
    return axios.get(API_BASE_URL + "/limits", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getAllUnits() {
    return axios.get(API_BASE_URL + "/units/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getAllPacks() {
    return axios.get(API_BASE_URL + "/packs/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getAllShoppings() {
    return axios.get(API_BASE_URL + "/shoppings/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  decreaseAvail(id) {
    return axios.post(
      API_BASE_URL + "/decreaseAvail/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  getById(id) {
    return axios.get(API_BASE_URL + "/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  update(article, id) {
    return axios.put(API_BASE_URL + "/" + id, article, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  addDaysPrice(daysBefore, id, itemType) {
    return axios.post(
      API_BASE_URL + "/daysPrice/" + id + "/" + itemType,
      daysBefore,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  allDaysPrice(id) {
    return axios.get(API_BASE_URL + "/daysPrice/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  allDatesPrice(id) {
    return axios.get(API_BASE_URL + "/datePrice/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  allTimesPrice(id) {
    return axios.get(API_BASE_URL + "/timePrice/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  addDatePrice(daysBefore, id, type) {
    return axios.post(
      API_BASE_URL + "/datePrice/" + id + "/" + type,
      daysBefore,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  activateArticle(id) {
    return axios.post(
      API_BASE_URL + "/activate/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  deactivateArticle(id) {
    return axios.post(
      API_BASE_URL + "/deactivate/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  addTimePrice(daysBefore, id, type) {
    return axios.post(
      API_BASE_URL + "/timePrice/" + id + "/" + type,
      daysBefore,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  delete(id) {
    return axios.delete(API_BASE_URL + "/" + id);
  }

  createShopping(s) {
    return axios.post(API_BASE_URL + "/shoppings/", s, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  createFooter(s) {
    return axios.post(API_BASE_URL + "/footers", s, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  createTemplate(s) {
    return axios.post(API_BASE_URL + "/templates", s, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getAllArticleComponents() {
    return axios.get(API_BASE_URL + "/components/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getAllArticleAmounts() {
    return axios.get(API_BASE_URL_REF + "/amounts/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getAllArticleComponentsById(id) {
    return axios.get(API_BASE_URL + "/components/items/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  createShoppingItem(sId, supId, c) {
    return axios.post(API_BASE_URL + "/shoppings/" + sId + "/" + supId, c, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  createShoppingArticle(sId, c) {
    return axios.post(API_BASE_URL + "/shoppingarticles/" + sId, c, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  updateShoppingArticle(sId, c) {
    return axios.post(API_BASE_URL + "/shoppingarticles/update/" + sId, c, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  createShoppingArticleItem(sId, saId, taxFee, c) {
    return axios.post(
      API_BASE_URL + "/shoppingitems/" + sId + "/" + saId + "/" + taxFee,
      c,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  createOfferArticleItem(sId, saId, taxFee, c) {
    return axios.post(
      API_BASE_URL + "/offeritems/" + sId + "/" + saId + "/" + taxFee,
      c,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  getShoppingArticleById(id) {
    return axios.get(API_BASE_URL + "/components/one/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  deleteShoppingArticleItem(id, shoppingId) {
    return axios.post(
      API_BASE_URL + "/shoppingitems/delete/" + id + "/" + shoppingId,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  checkShopping(id) {
    return axios.post(
      API_BASE_URL + "/shoppings/check/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  saveArticle(p, subName, allergens) {
    return axios.post(
      API_BASE_URL + "/import/" + subName + "/" + allergens,
      p,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  saveTopping(p, subName, groups, allergens) {
    return axios.post(
      API_BASE_URL +
        "/toppings/import/" +
        subName +
        "/" +
        groups +
        "/" +
        allergens,
      p,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  saveToppingPrice(
    articleName,
    producentName,
    priceWithoutTax,
    taxFee,
    priceWithTax
  ) {
    return axios.post(
      API_BASE_URL +
        "/toppings/prices/import/" +
        articleName +
        "/" +
        producentName +
        "/" +
        priceWithoutTax +
        "/" +
        taxFee +
        "/" +
        priceWithTax,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  saveToppingLanguage(articleName, en, fr) {
    return axios.post(
      API_BASE_URL +
        "/toppings/languages/import/" +
        articleName +
        "/" +
        en +
        "/" +
        fr,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  saveArticlePrice(
    articleName,
    producentName,
    priceWithoutTax,
    taxFee,
    priceWithTax
  ) {
    return axios.post(
      API_BASE_URL +
        "/prices/import/" +
        articleName +
        "/" +
        producentName +
        "/" +
        priceWithoutTax +
        "/" +
        taxFee +
        "/" +
        priceWithTax,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  saveArticleDuration(articleName, duration) {
    return axios.post(
      API_BASE_URL + "/durations/import/" + articleName + "/" + duration,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  saveArticleStation(articleNr, station) {
    return axios.post(
      API_BASE_URL + "/stations/import/" + articleNr + "/" + station,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }
  
  saveArticleMaterial(articleNr, materials) {
    return axios.post(
      API_BASE_URL + "/materials/import/" + articleNr + "/" + materials,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  saveArticleMaterialCapacity(articleNr, materials) {
    return axios.post(
      API_BASE_URL + "/materialCapacity/import/" + articleNr + "/" + materials,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  saveArticleLanguage(p) {
    return axios.post(API_BASE_URL + "/languages/import", p, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  saveBill(p, addressId, offerId) {
    return axios.post(API_BASE_URL + "/" + addressId + "/" + offerId , p, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }
}

export default new ArticleService();
