import React, { Component} from 'react'
import ArticleService from '../services/ArticleService';
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import { withRouter } from 'react-router-dom'
import PropTypes from "prop-types";
import compose from 'recompose/compose'
import {
    translate,
} from 'react-switch-lang';
import { Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class EditArticleComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nr: 0,
            name: '',
            availability: 0,
            maxPerClient: 0,
            priceWithoutTax: 0.0,
            makingDuration: 0,
            priceWithTax: 0.0,
            taxFee: 0,
            transportVolume: 0,
            validityDate: null,
            transportWeight: 0.0,
            deposit: 0.0,
            producerName: '',
            producerCountry: '',
            productGroups: [],
            productCategories: [],
            productTypes: [],
            productGroup: 0,
            productCategory: 0,
            productType: 0,
            taxFees: []
        }
    }

    saveOrUpdate = (e) => {
        e.preventDefault();
        let a = {
            name: this.state.name,
            description: this.state.description,
            availability: this.state.availability,
            maxPerClient: this.state.maxPerClient,
            priceWithTax: this.state.priceWithTax,
            taxFee: this.state.taxFee,
            priceWithoutTax: this.state.priceWithoutTax,
            makingDuration: this.state.makingDuration,
        };
        console.log('Article => ' + JSON.stringify(a));
        ArticleService.update(a, this.props.articleId).then(res => {
            this.props.history.push('/menu-list/');
        });
    }

    componentDidMount() {
        ArticleService.getById(this.props.articleId).then((res) => {
            ArticleService.getAllTaxFees().then((res2) => {
                this.setState({
                    name: res.data.name,
                    nr: res.data.nr,
                    availability: res.data.availability,
                    maxPerClient: res.data.maxPerClient,
                    priceWithTax: res.data.priceWithTax,
                    priceWithoutTax: res.data.priceWithoutTax,
                    taxFee: res.data.taxFee,
                    makingDuration: res.data.makingDuration,
                    description: res.data.description,
                    taxFees: res2.data,
                });
            });
        });
    }

    changeNameHandler = (event) => {
        this.setState({ name: event.target.value });
    }

    changeAvailibilityHandler = (event) => {
        this.setState({ availability: event.target.value });
    }

    changeMaxPerClientHandler = (event) => {
        this.setState({ maxPerClient: event.target.value });
    }

    changeTaxFeeHandler = (event) => {
        var taxfeeNew = event.target.value;
        var priceWithoutTax = this.state.priceWithoutTax;
        if (this.findTaxFee(taxfeeNew)[0] != undefined) {
            this.setState({ taxFee: taxfeeNew, priceWithTax: Number(Number(priceWithoutTax) + ((priceWithoutTax * this.findTaxFee(taxfeeNew)[0].value) / 100)).toFixed(2) });
        }
    }

    findTaxFee(taxFee) {
        return this.state.taxFees.filter(item => {
            return item.taxFee == taxFee
        })
    };

    changePurchasingPriceHandler = (event) => {
        var priceWithoutTax = event.target.value;
        this.setState({ priceWithoutTax: event.target.value });
        if (this.findTaxFee(this.state.taxFee)[0] != undefined) {
            this.setState({ priceWithTax: Number(Number(priceWithoutTax) + ((priceWithoutTax * this.findTaxFee(this.state.taxFee)[0].value) / 100)).toFixed(2) });
        }
    }

    changeMakingDurationHandler = (event) => {
        this.setState({ makingDuration: event.target.value });
    }

    changeDescriptionHandler = (event) => {
        this.setState({ description: event.target.value });
    }

    find(idToSearch) {
        return this.state.productGroups.filter(item => {
            return item.id == idToSearch
        })
    };

    cancel() {
        this.props.history.push('/menu-list/');
    }

    render() {
        if (cookies.get("token") !== 'b5c9667644da67241316db50cc788bfd078849de') {
            return <Redirect to="/login" />;
        }
        return (
            <div>
                <br></br>
                <div className="container">
                    <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3">
                            <div className="card-body">
                            <div className="form-group">
                                    <label> Nummer: (*) </label>
                                    <input className="form-control"
                                        value={this.state.nr} disabled/>
                                </div>
                                <div className="form-group">
                                    <label> Name: (*) </label>
                                    <input placeholder="Name" name="name" className="form-control"
                                        value={this.state.name} onChange={this.changeNameHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Beschreibung: </label>
                                    <input placeholder="Herstellername" name="Herstellername" className="form-control"
                                        value={this.state.description} onChange={this.changeDescriptionHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Nettopreis: (*) </label>
                                    <input placeholder="Preis" name="Preis" className="form-control"
                                        value={this.state.priceWithoutTax} onChange={this.changePurchasingPriceHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Steuersatz: (*) </label>
                                    <select className={'custom-select'} onChange={(evt) => this.changeTaxFeeHandler(evt)}>
                                        {this.state.taxFees.map((e, key) => {
                                            if (e.taxFee === this.state.taxFee) {
                                                return <option key={key} value={e.taxFee} selected>{e.value} %</option>
                                            }
                                            return <option key={key} value={e.taxFee} >{e.value} %</option>;
                                        })}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label> Bruttopreis (*) </label>
                                    <input placeholder="Preis" name="Preis" className="form-control"
                                        value={this.state.priceWithTax} onChange={this.changeCostPriceHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Verfügbarkeit: (*) </label>
                                    <input placeholder="Verfügbarkeit" name="Verfügbarkeit" className="form-control"
                                        value={this.state.availability} onChange={this.changeAvailibilityHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Maximum pro Kunde: (*) </label>
                                    <input placeholder="Maximum pro Kunde" name="Maximum pro Kunde" className="form-control"
                                        value={this.state.maxPerClient} onChange={this.changeMaxPerClientHandler} />
                                </div>
                                <div className="form-group">
                                    <label> Zubereitungszeit (in Minuten): </label>
                                    <input placeholder="Zubereitungszeit" name="Zubereitungszeit" className="form-control"
                                        value={this.state.makingDuration} onChange={this.changeMakingDurationHandler} />
                                </div>
                                <Icon name="save" tooltip="Speichern" theme="light" size="medium" onClick={this.saveOrUpdate} />
                                <Icon name="undo" tooltip="Zurück zu den Artikeln" theme="light" size="medium" onClick={this.cancel.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
EditArticleComponent.propTypes = {
    t: PropTypes.func.isRequired,
};

export default compose(
    withRouter,
)(translate(EditArticleComponent))
