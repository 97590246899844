import React, { Component } from "react";
import Breadcrumb from "./Breadcrumb";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import $ from "jquery";
import ProducentService from "../../../services/ProducentService";
import { Redirect, Link } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Content extends Component {
  componentDidMount() {
    ProducentService.getAllSuppliers().then((res) => {
      const columns = [
        {
          title: "ID",
          data: "id",
        },
        {
          title: "Name",
          data: "name",
        },
        {
          title: "Nummer",
          data: "nr",
        },
        {
          title: "Strasse",
          data: "street",
        },
        {
          title: "Hausnummer",
          data: "number",
        },
        {
          title: "Postleitzahl",
          data: "postalCode",
        },
        {
          title: "Stadt",
          data: "city",
        },
        {
          title: "Land",
          data: "country",
        },
        {
          title: "Telefonnummer",
          data: "telefonNumber",
        },
        {
          title: "Email",
          data: "email",
        },
        {
          title: "Aktiv",
          data: "available",
        },
      ];
      $("#data-table-1").DataTable({
        data: res.data,
        columns: columns,
        language: {
          url: "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json",
        },
      });
    });
  }

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb />
            <div className="ms-panel">
            <Link
                  style={{
                    fontSize: "25px",
                    color: "black",
                    margin: "10px",
                  }}
                  title="Lieferant anlegen"
                  to={"/add-supplier"}
                >
                  +
                </Link>
                <br></br><br></br>
              <div className="ms-panel-header">
                <h6>Lieferanten</h6>
              </div>
              <div className="ms-panel-body">
                <div className="table-responsive">
                  <table
                    id="data-table-1"
                    className="table w-100 thead-primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
