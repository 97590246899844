import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import Moment from "moment";
import update from "immutability-helper";
import OrderPositionService from "../services/OrderPositionService";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { translate } from "react-switch-lang";
const cookies = new Cookies();

class KitchenManagerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
    this.onRowReorder = this.onRowReorder.bind(this);
  }

  componentDidMount() {
    this.setData();
    this.updateInterval = setInterval(() => {
      this.setData();
    }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval);
  }

  setData() {
    OrderPositionService.getPendingPositions(0).then((data) => {
      var n = [];
      data.data.forEach((element) => {
        var updated = update(element, { checked: { $set: this.state.products.find(o => o.id===element.id)?this.state.products.find(o => o.id===element.id).checked:false } });
        n.push(updated);
      });
      this.setState({ products: n });
    });
  }

  onRowReorder(e) {
    var p = e.value;
    var d = [];
    p.forEach((element) => {
      d.push({
        positionId: element.id,
        checked: element.checked,
      });
    });
    OrderPositionService.reArrange(d).then((res2) => {
      OrderPositionService.getPendingPositions(0).then((data) => {
        var n = [];
        data.data.forEach((element) => {
          var updated = update(element, { checked: { $set: false } });
          n.push(updated);
        });
        this.setState({ products: n });
      });
      this.toast.show({
        severity: "success",
        summary: "Prio geändert",
        life: 3000,
      });
    });
  }

  statusBodyTemplate = (rowData) => {
    return <span>{Moment(rowData.created).format("DD-MM-YYYY HH:mm")}</span>;
  };

  statusAllTemplate = (rowData) => {
    return rowData.status === "PROGRESSING" ? (
      ""
    ) : (
      <input
        type="checkbox"
        checked={rowData.checked}
        onChange={(e) => this.handleCheck(e, rowData.saleItemId)}
      ></input>
    );
  };

  statusTemplate = (rowData) => {
    return rowData.status === "PROGRESSING" ? (
      ""
    ) : (
      <i className="p-datatable-reorderablerow-handle pi pi-bars"></i>
    );
  };

  statusTemplate2 = (rowData) => {
    const { t } = this.props;
    return t(rowData.status.toLowerCase());
  };

  handleCheck = (e, saleItemId) => {
    var c = e.target.checked;
    var p = this.state.products;
    var d = [];
    p.forEach((one) => {
      var updated = update(one, {
        checked: { $set: one.saleItemId === saleItemId ? c : false },
      });
      d.push(updated);
    });
    this.setState({
      products: d,
    });
  };

  statusKitchenTemplate = (rowData) => {
    return (
      <span
        style={{
          textAlign: "center",
          display: "block",
          minHeight: "35px",
          backgroundColor:
            rowData.station === undefined
              ? ""
              : rowData.station.id === 1
              ? "lightgreen"
              : rowData.station.id === 2
              ? "lightblue"
              : rowData.station.id === 3
              ? "yellow"
              : rowData.station.id === 4
              ? "orange"
              : rowData.station.id === 5
              ? "magenta"
              : "lightGray",
        }}
      >
        {rowData.station && rowData.station.name}
      </span>
    );
  };

  statusKitchenTemplate1 = (rowData) => {
    var end = rowData.end;
    var diffDuration = null;
    if (end == null) {
      const startDate = Moment(rowData.start);
      const timeEnd = Moment(new Date());
      const diff = timeEnd.diff(startDate);
      diffDuration = Moment.duration(diff).minutes() + " Min";
    } else {
      diffDuration = "------";
    }
    return (
      <span
        style={{
          fontSize: "18px",
          color:
            diffDuration !== "------" &&
            diffDuration.split(" ")[0] > rowData.makingDuration
              ? "red"
              : "green",
        }}
      >
        {diffDuration}
      </span>
    );
  };

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <Toast
          ref={(el) => {
            this.toast = el;
          }}
        ></Toast>
        <div>
          <DataTable
            value={this.state.products}
            reorderableRows
            onRowReorder={this.onRowReorder}
            responsiveLayout="scroll"
          >
            <Column body={this.statusTemplate} style={{ width: "5em" }} />
            <Column
              filter={true}
              sortable={true}
              field="prio"
              header="Prio"
            ></Column>
            <Column
              field="created"
              filter={true}
              sortable={true}
              header="Ganze bestellung"
              body={this.statusAllTemplate}
            ></Column>
            <Column
              filter={true}
              sortable={true}
              field="billNumber"
              header="BestellungsNr"
              style={{ width: "3em" }}
            ></Column>
            <Column
              filter={true}
              sortable={true}
              field="articleNr"
              header="ArtikelNr"
            ></Column>
            <Column
              filter={true}
              sortable={true}
              field="articleName"
              header="Artikel"
            ></Column>
            <Column
              filter={true}
              sortable={true}
              style={{ width: "2em" }}
              field="note"
              header="Notiz"
            ></Column>
            <Column
              filter={true}
              sortable={true}
              field="created"
              header="Eingangszeit"
              body={this.statusBodyTemplate}
            ></Column>
            <Column
              filter={true}
              sortable={true}
              field="postalCode"
              header="Postleitzahl"
            ></Column>
            <Column
              filter={true}
              sortable={true}
              field="status"
              body={this.statusTemplate2}
              header="Status"
            ></Column>
            <Column
              field="station.name"
              header="Küche"
              filter={true}
              sortable={true}
              body={this.statusKitchenTemplate}
            ></Column>
            <Column
              field=""
              filter={true}
              sortable={true}
              body={this.statusKitchenTemplate1}
              header="In der Küche seit"
            ></Column>
          </DataTable>
        </div>
      </div>
    );
  }
}

KitchenManagerComponent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(withRouter)(translate(KitchenManagerComponent));
