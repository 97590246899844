import React, { Component } from 'react';
import Icon from 'react-crud-icons';
import '../../node_modules/react-crud-icons/dist/css/react-crud-icons.css';
import ArticleService from '../services/ArticleService';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class AddFooterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      forPrice: false,
      forDuration: false,
      forDescription: false,
    };
    this.changeNameHandler = this.changeNameHandler.bind(this);
    this.saveOrUpdate = this.saveOrUpdate.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  saveOrUpdate = (e) => {
    e.preventDefault();
    const c = { name: this.state.name, forPrice: this.state.forPrice, forDuration: this.state.forDuration, forDescription: this.state.forDescription };
    console.log('Footer => ' + JSON.stringify(c));
    ArticleService.createFooter(c).then((res) => {
      this.props.history.push('/footers/');
    });
  };

  changeNameHandler = (event) => {
    this.setState({ name: event.target.value });
  };

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked });
  };

  cancel = () => {
    this.props.history.push('/shopping/');
  };

  render() {
    if (cookies.get('token') !== 'b5c9667644da67241316db50cc788bfd078849de') {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="card col-md-6 offset-md-3 offset-md-3">
              <div className="card-body">
                <div className="form-group">
                  <label>Name: </label>
                  <input
                    placeholder="Name"
                    name="name"
                    className="form-control"
                    value={this.state.name}
                    onChange={this.changeNameHandler}
                  />
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="forPrice"
                      checked={this.state.forPrice}
                      onChange={this.handleCheckboxChange}
                    />
                    Für Nettopreis
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="forDuration"
                      checked={this.state.forDuration}
                      onChange={this.handleCheckboxChange}
                    />
                    Für Vertragslaufzeit
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="forDescription"
                      checked={this.state.forDescription}
                      onChange={this.handleCheckboxChange}
                    />
                    Für Leistung und Durchführung
                  </label>
                </div>
                <Icon name="save" tooltip="Speichern" theme="light" size="medium" onClick={this.saveOrUpdate} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withRouter)(AddFooterComponent);