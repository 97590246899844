import React, { Component } from "react";
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import Cookies from "universal-cookie";
import OrderService from "../services/OrderService";
import { translate } from "react-switch-lang";
import PropTypes from "prop-types";
const cookies = new Cookies();

class AddNoteComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      itemNoteTypes: [],
      itemNoteTypeId: 0,
      itemId: this.props.match.params.id,
    };
    this.changeNameHandler = this.changeNameHandler.bind(this);
    this.changeNoteHandler = this.changeNoteHandler.bind(this);
    this.saveOrUpdate = this.saveOrUpdate.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  saveOrUpdate = (e) => {
    e.preventDefault();
    OrderService.createNote(this.state.itemId, this.state.itemNoteTypeId, this.state.name).then((res) => {
      this.props.history.push("/");
    });
  };

  changeNameHandler = (event) => {
    this.setState({ name: event.target.value });
  };

  changeNoteHandler = (event) => {
    this.setState({ itemNoteTypeId: event.target.value });
  };

  cancel = () => {
    this.props.history.push("/");
  };

  componentDidMount() {
    OrderService.getNoteTypes().then((res5) => {
      this.setState({ itemNoteTypes: res5.data});
    });
  }

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    const { t } = this.props;
    return (
      <div>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="card col-md-6 offset-md-3 offset-md-3">
              <div className="card-body">
                <div className="form-group">
                  <label> Notizart: </label>
                  <select className={"custom-select"} name="noteType"
                  onChange={this.changeNoteHandler}>
                    <option value="Notizart auswählen">
                      Notizart auswählen
                    </option>
                    {this.state.itemNoteTypes.map((e, key) => {
                      return (
                        <option key={key} value={key}>
                          {t(e.toLowerCase())}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label> Notiz: </label>
                  <input
                    placeholder="Notiz"
                    name="Notiz"
                    className="form-control"
                    value={this.state.name}
                    onChange={this.changeNameHandler}
                  />
                </div>
                <Icon
                  name="save"
                  tooltip="Speichern"
                  theme="light"
                  size="medium"
                  onClick={this.saveOrUpdate}
                />
                <Icon
                  name="undo"
                  tooltip="Zurück zu den Bestellungen"
                  theme="light"
                  size="medium"
                  onClick={this.cancel.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddNoteComponent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(withRouter)(translate(AddNoteComponent));
