import React, { Component } from 'react';
import Breadcrumb from './Breadcrumb';
import "datatables.net-bs4/js/dataTables.bootstrap4"
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css"
import $ from 'jquery';
import {
    translate,
} from 'react-switch-lang';
import PropTypes from 'prop-types';
import ArticleService from '../../../services/ArticleService';
import { Link, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Content extends Component {
    componentDidMount() {
        ArticleService.getAllProductionArticles().then((res) => {
            const { t } = this.props;
            const columns = [
                {
                    title: 'ID',
                    data: 'id'
                },
                {
                    title: 'Nummer',
                    data: 'nr'
                },
                {
                    title: 'Name',
                    data: 'name'
                },
                {
                    title: 'Bestandteile (Notizen)',
                    data: 'shoppingArticleList'
                }
            ];
            $('#data-table-1').DataTable({
                data: res.data,
                columns: columns,
                language: {
                    "url": "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json"
                }, 'columnDefs': [
                    {
                        "render": function (data, type, row) {
                            return Array.prototype.map.call(data, function (item) { return item.shoppingArticle.name + " " + item.quantity + " " + item.shoppingArticle.shoppingArticleUnit + " " + item.notice }).join("<br>");
                        },
                        "targets": [3],
                    }
                ]
            });
        })
    }

    render() {
        if (cookies.get("token") !== 'b5c9667644da67241316db50cc788bfd078849de') {
            return <Redirect to="/login" />;
        }
        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb />
                        <div className="ms-panel">
                            <div className="ms-panel-header">
                                <h6>Produktionsartikel <br></br>
                                    <Link style={{ fontSize: '25px', color: 'black' }} title='Neues Produktionsartikel anlegen' to={'/add-production-article'}>+</Link>
                                </h6>
                            </div>
                            <div className="ms-panel-body">

                                <div className="table-responsive">
                                    <table id="data-table-1" className="table w-100 thead-primary" />
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        );
    }
}
Content.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Content);