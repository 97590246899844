import React, { Component } from "react";
import OrderService from "../services/OrderService";
import Icon from "react-crud-icons";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ProductsService from "../services/ProductsService";
import CurrencyService from "../services/CurrencyService";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import update from "immutability-helper";
import Tooltip from "@mui/material/Tooltip";
import { Redirect } from "react-router-dom";
import ProducentService from "../services/ProducentService";
import { translate } from "react-switch-lang";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const cookies = new Cookies();

class CreateSelfOrderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      storeId: 0,
      successMessage: "",
      data: [],
      search: "",
      positions: [],
      stores: [],
      currency: "",
      filterValue: "",
      filteredData: [],
      name: "",
      postalCode: "",
      number: "",
      street: "",
      city: "",
      country: "",
      telefonNumber: "",
      cId: 0,
      address: "",
      itemNoteTypes: [],
      itemPositionNotes: [],
      itemNoteDriver: "",
      itemNoteKitchen: "",
      itemNoteGeneral: "",
    };
    this.onDropdownSelectedStore = this.onDropdownSelectedStore.bind(this);
    this.autocomplete = null;
  }

  add = (e) => {
    e.preventDefault();
    OrderService.create(
      0,
      this.state.name,
      this.state.telefonNumber,
      this.state.storeId,
      this.state.positions,
      Number(
        this.state.positions.reduce((a, v) => (a = a + v.total), 0)
      ).toFixed(2),
      this.state.itemNoteDriver.length > 1 ? this.state.itemNoteDriver : "NONE",
      this.state.itemNoteKitchen.length > 1
        ? this.state.itemNoteKitchen
        : "NONE",
      this.state.itemNoteGeneral.length > 1
        ? this.state.itemNoteGeneral
        : "NONE"
    ).then((res) => {
      this.props.history.push("/");
    });
  };

  componentDidMount() {
    ProducentService.getStores(1).then((res4) => {
      ProductsService.getProducts(res4.data[0].id).then((res) => {
        CurrencyService.getActualCurrency(res4.data[0].id).then((res2) => {
          ProducentService.getStoreById(res4.data[0].id).then((res3) => {
            OrderService.getNoteTypes().then((res5) => {
              this.setState({
                loading: false,
                storeId: res4.data[0].id,
                data: res.data[0].products,
                filteredData: res.data[0].products,
                currency: res2.data.newValue,
                stores: res4.data,
                itemNoteTypes: res5.data,
              });
            });
          });
        });
      });
    });
  }

  setFilteredValue(name) {
    this.setState({
      filterValue: name,
      filteredData: this.state.data.filter((gr) => gr.name.includes(name)),
    });
  }

  onDropdownSelectedStore = (event) => {
    let one = event.target.value;
    ProductsService.getProducts(one).then((res) => {
      CurrencyService.getActualCurrency(one).then((res2) => {
        ProducentService.getStoreById(one).then((res3) => {
          this.setState({
            data: res.data[0].products,
            filteredData: res.data[0].products,
            currency: res2.data.newValue,
            storeId: one,
          });
        });
      });
    });
  };

  addToCart(id, price, quantity, name, is_topping) {
    if (this.state.articleId != null) {
      var pos = {
        identifier: id,
        price: price,
        quantity: quantity,
        total: price * quantity,
        name: name,
        note: "",
        is_topping: is_topping,
        reference_article_id: this.state.articleId,
      };
    } else {
      var pos = {
        identifier: id,
        price: price,
        quantity: quantity,
        total: price * quantity,
        name: name,
        note: "",
        is_topping: is_topping,
      };
    }
    console.log(pos);
    var newData = [];
    newData = this.state.positions;
    newData.push(pos);
    this.setState({
      positions: newData,
    });
  }

  removeFromCart = (identifier) => {
    let filteredArray = this.state.positions;
    filteredArray = filteredArray.filter((item) => {
      return item.identifier !== identifier;
    });
    this.setState({
      positions: filteredArray,
    });
  };

  cancel() {
    this.props.history.push("/");
  }

  handleNote(id, event) {
    var noteString = event.target.value;
    var data = this.state.positions;
    var element = data.find((element) => {
      return element.identifier === id;
    });
    console.log(id);
    var updated = update(element, { note: { $set: noteString } });
    var newData = update(data, {
      $splice: [[this.state.positions.indexOf(element), 1, updated]],
    });
    console.log(newData);
    this.setState({ positions: newData });
  }

  addTopping = (identifier) => {
    this.setState({
      articleId: identifier,
      filteredData: this.state.filteredData.filter((element) => {
        return element.groups[0].name == "Topping";
      }),
      data: this.state.filteredData.filter((element) => {
        return element.groups[0].name == "Topping";
      }),
    });
  };

  resetProducts() {
    this.setState({
      articleId: null,
      loading: true,
      data: [],
      filteredData: [],
    });
    ProductsService.getProducts().then((res) => {
      this.setState({
        data: res.data[0].products,
        filteredData: res.data[0].products,
        loading: false,
      });
    });
  }

  handleIncrement(a, total) {
    var data = this.state.positions;
    var element = data.find((element) => {
      return element.identifier === a.id;
    });
    if (element === undefined) {
      this.addToCart(a.id, a.priceWithoutTax, 1, a.name, a.children == null);
    } else if (a.maxPerClient < element.quantity + 1) {
    } else {
      var updated = update(element, {
        quantity: { $set: element.quantity + 1 },
        total: { $set: total },
      });
      var newData = update(data, {
        $splice: [[this.state.positions.indexOf(element), 1, updated]],
      });
      this.setState({ positions: newData });
    }
  }

  handleDecrement(a, total) {
    var data = this.state.positions;
    var element = data.find((element) => {
      return element.identifier === a.id;
    });
    if (element.quantity <= 1) {
      this.removeFromCart(element.identifier);
    } else {
      var updated = update(element, {
        quantity: { $set: element.quantity - 1 },
        total: { $set: total },
      });
      var newData = update(data, {
        $splice: [[this.state.positions.indexOf(element), 1, updated]],
      });
      this.setState({ positions: newData });
    }
  }

  getPositionData(a) {
    var element = this.state.positions.find((one) => {
      return one.identifier === a.id;
    });
    return element;
  }

  isNumber(str) {
    if (str.trim() === "") {
      return false;
    }
    return !isNaN(str);
  }

  changeNameHandler = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  changeItemNoteDriverHandler = (event) => {
    this.setState({
      itemNoteDriver: event.target.value,
    });
  };

  changeItemNoteKitchenHandler = (event) => {
    this.setState({
      itemNoteKitchen: event.target.value,
    });
  };

  changeItemNoteGeneralHandler = (event) => {
    this.setState({
      itemNoteGeneral: event.target.value,
    });
  };

  handleChange = (address) => {
    this.setState({
      address: address,
      street: address.split(" ")[0],
      number:
        address.split(",")[0] !== undefined
          ? address.split(",")[0].split(" ")[1]
          : "",
      city:
        address.split(",")[1] !== undefined
          ? address.split(",")[1].trim().split(" ")[1]
          : "",
      country:
        address.split(",")[2] !== undefined ? address.split(",")[2].trim() : "",
      postalCode:
        address.split(",")[1] !== undefined
          ? address.split(",")[1].trim().split(" ")[0]
          : "",
    });
  };

  changeTelefonNumberHandler = (event) => {
    this.setState({
      telefonNumber: event.target.value,
    });
  };

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    const { t } = this.props;
    return (
      <div className="row">
        <div className="col">
          <Icon
            name="undo"
            tooltip="Zurück zu den Bestellungen"
            theme="light"
            size="medium"
            onClick={this.cancel.bind(this)}
          />
          <div>
            <p
              style={{
                fontSize: "18px",
                textDecoration: "underline",
                textTransform: "uppercase",
                marginRight: "10px",
                marginTop: "40px",
              }}
            >
              {" "}
              Store{" "}
            </p>
            <div>
              <select
                className={"custom-select"}
                style={{ width: "300px" }}
                name="kunde"
                defaultValue={this.state.storeId}
                onChange={(evt) => this.onDropdownSelectedStore(evt)}
              >
                {this.state.stores.map((e, key) => {
                  return (
                    <option key={key} value={e.id}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div>
            <div>
              <p
                style={{
                  fontSize: "18px",
                  textDecoration: "underline",
                  textTransform: "uppercase",
                  marginRight: "10px",
                  marginTop: "40px",
                }}
              >
                {" "}
                Kunde{" "}
              </p>
              <div style={{ marginTop: "15px" }}>
                <div className="form-group" style={{ width: "300px" }}>
                  <label> Name: </label>
                  <input
                    placeholder="Name"
                    name="Name"
                    className="form-control"
                    value={this.state.name}
                    onChange={this.changeNameHandler}
                  />
                </div>
                <div className="form-group" style={{ width: "300px" }}>
                  <label> Telefonnummer: </label>
                  <input
                    placeholder="Telefonnummer"
                    name="Telefonnummer"
                    className="form-control"
                    value={this.state.telefonNumber}
                    onChange={this.changeTelefonNumberHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ height: "800px", width: "300px", marginTop: "30px" }}
            className="form-group"
          >
            {this.state.positions.length > 0}
            {this.state.positions
              .filter((one) => one.reference_article_id == null)
              .map((d) => (
                <div>
                  <ul>
                    <li>
                      {d.quantity} {d.name}{" "}
                      <div style={{ float: "right" }}>
                        {Number(d.total.toFixed(2))}
                        {this.state.currency}
                        <button
                          style={{ backgroundColor: "tomato" }}
                          onClick={() => this.removeFromCart(d.identifier)}
                        >
                          -
                        </button>
                      </div>
                      <br></br>
                      <br></br>
                      {this.state.positions
                        .filter(
                          (one) => one.reference_article_id === d.identifier
                        )
                        .map((d) => (
                          <div>
                            <ul>
                              <li>
                                {d.quantity} {d.name}{" "}
                                <div style={{ float: "right" }}>
                                  {Number(d.total.toFixed(2))}
                                  {this.state.currency}
                                  <button
                                    style={{ backgroundColor: "tomato" }}
                                    onClick={() =>
                                      this.removeFromCart(d.identifier)
                                    }
                                  >
                                    -
                                  </button>
                                </div>
                                <br></br>
                                <br></br>
                              </li>
                            </ul>
                          </div>
                        ))}
                    </li>
                    {!d.is_topping && (
                      <li>
                        {" "}
                        <br></br>
                        <button
                          style={{ float: "left", backgroundColor: "#ff6877" }}
                          onClick={() => this.addTopping(d.identifier)}
                        >
                          Topping +
                        </button>{" "}
                        <button
                          style={{
                            float: "right",
                            backgroundColor: "lightgreen",
                          }}
                          onClick={() => this.resetProducts()}
                        >
                          &#10003;
                        </button>
                        <br></br>
                        <br></br>
                      </li>
                    )}
                  </ul>
                  <div style={{ paddingBottom: "20px" }}>
                    Notiz:{" "}
                    <input
                      style={{ width: "300px" }}
                      className="form-control"
                      onChange={(event) => this.handleNote(d.identifier, event)}
                    ></input>
                  </div>
                </div>
              ))}
            <ul style={{ marginTop: "35px" }}>
              {this.state.positions.length > 0 &&
                this.state.itemNoteTypes &&
                this.state.itemNoteTypes.map((d) => (
                  <ul>
                    <li>Notiz {t(d.toLowerCase())}</li>
                    <li>
                      <input
                        style={{ width: "300px" }}
                        className="form-control"
                        value={
                          d == "DRIVER"
                            ? this.state.itemNoteDriver
                            : d == "KITCHEN"
                            ? this.state.itemNoteKitchen
                            : this.state.itemNoteGeneral
                        }
                        onChange={
                          d == "DRIVER"
                            ? this.changeItemNoteDriverHandler
                            : d == "KITCHEN"
                            ? this.changeItemNoteKitchenHandler
                            : this.changeItemNoteGeneralHandler
                        }
                      />
                    </li>
                  </ul>
                ))}
              {this.state.positions.length > 0 && (
                <li style={{ marginTop: "10px" }}>
                  <b>Summe:</b>
                </li>
              )}
              {this.state.positions.length > 0 && (
                <li style={{ float: "right", marginTop: "10px" }}>
                  {Number(
                    this.state.positions.reduce((a, v) => (a = a + v.total), 0)
                  ).toFixed(2)}{" "}
                  {this.state.currency}
                </li>
              )}
            </ul>
            {this.state.positions.length > 0 && (
              <button
                className="btn btn-success btn-sm"
                name="save"
                theme="light"
                size="medium"
                onClick={this.add}
              >
                Bestellung abschließen
              </button>
            )}
          </div>
        </div>
        <div className="col" style={{ marginTop: "15px" }}>
          <div className="header">
            <h1
              style={{
                color: "green",
                fontSize: "40px",
                fontWeight: "bolder",
              }}
              onClick={() =>
                this.setState({
                  showCart: false,
                })
              }
            ></h1>
            <div>
              <input
                style={{ width: "300px" }}
                type="text"
                key={this.state.data.id}
                value={this.state.search}
                className="form-control"
                placeholder="Artikel suchen"
                onChange={(e) =>
                  this.setState({
                    search: e.target.value,
                  })
                }
              ></input>
            </div>
          </div>
          <br />
          {this.state.loading && (
            <Box style={{ paddingLeft: "455px" }}>
              <CircularProgress color="inherit" />
            </Box>
          )}
          <div
            style={{
              width: "950px",
              fontSize: "11.5px",
              fontFamily: "Dosis",
            }}
          >
            <div className="filter-buttons">
              {!this.state.loading &&
                this.state.data.map((data) => (
                  <div>
                    {data.availability === true && data.name === "Toppings" && (
                      <button
                        onClick={() => this.setFilteredValue(data.name)}
                        className={`filter-button-2 ${
                          this.state.filterValue === data.name
                            ? "selected-button"
                            : ""
                        }`}
                        type="submit"
                        value="search"
                      >
                        {data.name}
                      </button>
                    )}
                    {data.availability === true && data.name !== "Toppings" && (
                      <button
                        onClick={() => this.setFilteredValue(data.name)}
                        className={`filter-button ${
                          this.state.filterValue === data.name
                            ? "selected-button"
                            : ""
                        }`}
                        type="submit"
                        value="search"
                      >
                        {data.name}
                      </button>
                    )}
                  </div>
                ))}
            </div>
            <br />
            <br />
            <div className="columns is-multiline">
              {this.state.filteredData.map((data) =>
                data.groups.map((g) =>
                  g.subs.map((sub) =>
                    sub.articles
                      .concat(sub.extras)
                      .filter((el) =>
                        el.name
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase())
                      )
                      .map((a) => (
                        <div
                          className="column is-one-third is-flex is-justify-content-space-around"
                          key={a.nr}
                        >
                          <div className="card ">
                            <div className="card-image">
                              <figure className="image is-4by3">
                                <img
                                  style={{
                                    marginTop: "15px",
                                    webkitFilter:
                                      a.availability <= 0
                                        ? "grayscale(100%)"
                                        : "",
                                    filter:
                                      a.availability <= 0
                                        ? "grayscale(100%)"
                                        : "",
                                  }}
                                  src={`${a.picture}`}
                                  className="item-image"
                                  alt=""
                                />
                              </figure>
                              {a.description && (
                                <Tooltip
                                  title={
                                    <span style={{ fontFamily: "Dosis" }}>
                                      {a.description && a.description.name}
                                    </span>
                                  }
                                >
                                  <p className="title is-4">
                                  {a.nr}
                                  <br></br>
                                  {a.name}
                                  </p>
                                </Tooltip>
                              )}
                              {a.description == null && (
                                <p className="title is-4">{a.name}</p>
                              )}
                              <h3
                                style={{
                                  color: "rgb(95, 95, 95)",
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                {Number(
                                  this.getPositionData(a)
                                    ? Number(
                                        this.getPositionData(a).quantity *
                                          a.priceWithoutTax
                                      ).toFixed(2)
                                    : Number(a.priceWithoutTax).toFixed(2)
                                )}{" "}
                                {this.state.currency}
                              </h3>
                              <ButtonGroup
                                size="small"
                                aria-label="small outlined button group"
                              >
                                {this.getPositionData(a) &&
                                  this.getPositionData(a).quantity > 0 && (
                                    <Button
                                      onClick={() =>
                                        this.handleDecrement(
                                          a,
                                          a.priceWithoutTax *
                                            (this.getPositionData(a)
                                              ? this.getPositionData(a)
                                                  .quantity - 1
                                              : 1)
                                        )
                                      }
                                    >
                                      -
                                    </Button>
                                  )}
                                {this.getPositionData(a) &&
                                  this.getPositionData(a).quantity > 0 && (
                                    <Button disabled>
                                      {this.getPositionData(a).quantity}
                                    </Button>
                                  )}
                                {
                                  <Button
                                    onClick={() =>
                                      this.handleIncrement(
                                        a,
                                        a.priceWithoutTax *
                                          (this.getPositionData(a)
                                            ? this.getPositionData(a).quantity +
                                              1
                                            : 1)
                                      )
                                    }
                                  >
                                    +
                                  </Button>
                                }
                              </ButtonGroup>
                            </div>
                          </div>
                        </div>
                      ))
                  )
                )
              )}
            </div>
          </div>
        </div>
        <div className="col"></div>
      </div>
    );
  }
}
CreateSelfOrderComponent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(withRouter)(translate(CreateSelfOrderComponent));
