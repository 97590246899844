import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import OrderPositionService from "../services/OrderPositionService";
import OrderService from "../services/OrderService";
import update from "immutability-helper";
import Moment from "moment";
import "moment/locale/de";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import $, { event } from "jquery";
import ReactDOM from "react-dom";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import DatePicker from "react-datepicker";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import Multiselect from "multiselect-react-dropdown";
import { translate } from "react-switch-lang";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import Icon from "react-crud-icons";
const cookies = new Cookies();

class ListTemplatesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      expandedTicket: true,
      loading: true,
      success: false,
      error: false,
      searchInput: "",
      filteredData: [],
      open: false,
      id: 0,
      cancelReason: "",
      play: false,
      audio: new Audio(
        "https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"
      ),
      start: new Date(),
      end: new Date(),
    };
    Moment.locale("de");
    this.changeDateStartHandler = this.changeDateStartHandler.bind(this);
    this.changeDateEndHandler = this.changeDateEndHandler.bind(this);
    this.delete = this.delete.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
  }

  changeDateStartHandler = (event) => {
    this.setState({
      start: event,
    });
  };

  changeDateEndHandler = (event) => {
    this.setState({
      end: event,
    });
  };

  delete(id) {
    OrderService.deleteTemplate(id).then((resDel) => {
      OrderService.getTemplates().then((res) => {
        this.setState({
          orders: res.data,
          loading: false,
          filteredData: res.data,
        });
        this.setDataTable(res.data);
      });
    });
  }

  view(id) {
    OrderPositionService.getPdf(id);
  }

  view2(id) {
    OrderPositionService.getCSV(id);
  }

  generate(id) {
    OrderPositionService.getBill(id);
    this.props.history.push("/");
  }

  addNote(id) {
    this.props.history.push("/order-add-note/" + id);
  }

  handleOpen(id) {
    this.setState({
      open: true,
      id: id,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  matchIt(event, id) {
    if (event.target.checked === true) {
      OrderService.matchArticle(id).then((res2) => {
        window.location.reload();
      });
    }
  }

  componentDidMount() {
    this.state.audio.addEventListener("ended", () =>
      this.setState({ play: false })
    );
    OrderService.getTemplates().then((res) => {
      this.setState({
        orders: res.data,
        loading: false,
        filteredData: res.data,
      });
      this.setDataTable(res.data);
    });
  }

  toggleExpandedTicket(rowData) {
    var existing = this.state.filteredData.filter(
      (one) => one.id === rowData.id
    )[0];
    var updated = update(existing, {
      checked: {
        $set: existing === undefined ? true : existing.checked ? false : true,
      },
    });
    var NewData = update(this.state.filteredData, {
      $splice: [[this.state.filteredData.indexOf(existing), 1, updated]],
    });
    this.setState({
      orders: update(this.state.filteredData, {
        $splice: [[this.state.filteredData.indexOf(existing), 1, updated]],
      }),
      filteredData: NewData,
    });
    this.setDataTable(NewData);
  }

  cancelOrder() {
    OrderService.cancelItem(this.state.id, this.state.cancelReason).then(
      (res1) => {
        OrderService.getOrders().then((res) => {
          this.setState({ orders: res.data, filteredData: res.data });
          window.location.reload();
        });
      }
    );
  }

  changeCancelReasonHandler(e) {
    this.setState({
      cancelReason: e.target.value,
    });
  }

  cancel() {
    this.props.history.push("/");
  }

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput, orders } = this.state;
    let filteredData = orders.filter((value) => {
      return (
        (value.client &&
          value.client.name
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        value.id.toString().toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  sendMail(id) {
    OrderService.sendMail(id).then((res) => {
      if (res.data) {
        this.setState({ success: true });
        setTimeout(() => {
          this.setState({
            success: false,
          });
        }, 5000);
      } else {
        this.setState({ error: true });
        setTimeout(() => {
          this.setState({
            error: false,
          });
        }, 5000);
      }
    });
  }

  openFile(blobFile, file) {
    var blob = new Blob([this.base64ToArrayBuffer(blobFile)], {
      type: "application/pdf",
    });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = file;
    link.download = fileName;
    link.click();
  }

  setDataTable(dataP) {
    var data = [];
    dataP.forEach((element) => {
      var updated = update(element, {
        checkedProducent: {
          $set: false,
        },
      });
      data.push(updated);
    });
    dataP = data;
    const { t } = this.props;
    const columns = [
      {
        title: t("name"),
        data: "name",
      },
      {
        title: "Bearbeiten/Storno",
        data: "id",
      },
    ];
    $("#data-table-5").DataTable({
      order: [[0, "desc"]],
      data: dataP,
      columns: columns,
      bFilter: false,
      bDestroy: true,
      bStateSave: true,
      fnStateSave: function (oSettings, oData) {
        localStorage.setItem("offersDataTables", JSON.stringify(oData));
      },
      fnStateLoad: function (oSettings) {
        return JSON.parse(localStorage.getItem("offersDataTables"));
      },
      language: {
        processing: "DataTables is currently busy",
        url: "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json",
      },
      columnDefs: [
        {
          targets: [1],
          width: 80,
          createdCell: (td, cellData, rowData, row, col) => {
            ReactDOM.render(
              <div>
                <Icon onClick={this.edit} name="edit"/>
                <Icon onClick={() => this.delete(rowData.id)} name="delete"/>
              </div>,
              td
            );
          },
        },
      ],
    });
  }

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-body">
                <br />
                <div className="row">
                  <div
                    className="ms-panel ms-panel-fh"
                    style={{
                      margin: "0 auto",
                      paddingBottom: "20px",
                      backgroundColor: "inherit",
                    }}
                  ></div>
                </div>
                {this.state.success && (
                  <Stack
                    sx={{ marginLeft: "550px", width: "338px" }}
                    spacing={2}
                  >
                    <Alert severity="success">
                      Rechnung als E-Mail erfolgreich geschickt!
                    </Alert>
                  </Stack>
                )}
                {this.state.error && (
                  <Stack
                    sx={{ marginLeft: "550px", width: "338px" }}
                    spacing={2}
                  >
                    <Alert severity="error">
                      Fehler aufgetreten, E-Mail nicht hinterlegt!
                    </Alert>
                  </Stack>
                )}
                {this.state.loading && (
                  <Box style={{ paddingLeft: "450px" }}>
                    <CircularProgress color="inherit" />
                  </Box>
                )}
                {!this.state.loading && (
                  <div className="table-responsive">
                    <table
                      id="data-table-5"
                      className="table w-100 thead-primary"
                    ></table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ListTemplatesComponent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(withRouter)(translate(ListTemplatesComponent));
