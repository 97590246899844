import React, { Component } from "react";
import Icon from "react-crud-icons";
import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import ClientService from "../services/ClientService";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { translate } from "react-switch-lang";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class EditClientComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      name: "",
      surname: "",
      telefonNumber: "",
      telefonNumber2: "",
      contactPerson: "",
      contactPerson2: "",
      email: "",
    };
    this.cList = React.createRef();
    this.changeNameHandler = this.changeNameHandler.bind(this);
    this.changeSurNameHandler = this.changeSurNameHandler.bind(this);
    this.changeEmailHandler = this.changeEmailHandler.bind(this);
    this.changeTelefonNumberHandler =
      this.changeTelefonNumberHandler.bind(this);
    this.changeTelefonNumber2Handler =
      this.changeTelefonNumber2Handler.bind(this);
      this.changeContactPersonHandler =
      this.changeContactPersonHandler.bind(this);
    this.changeContactPerson2Handler =
      this.changeContactPerson2Handler.bind(this);
    this.saveOrUpdate = this.saveOrUpdate.bind(this);
  }

  componentDidMount() {
    ClientService.getById(this.state.id).then((res) => {
      let c = res.data;
      this.setState({
        name: c.name,
        surname: c.surname,
        telefonNumber: c.telefonNumber,
        telefonNumber2: c.telefonNumber2,
        contactPerson: c.contactPerson,
        contactPerson2: c.contactPerson2,
        email: c.email,
      });
    });
  }

  saveOrUpdate = (e) => {
    e.preventDefault();
    let c = {
      name: this.state.name,
      surname: this.state.surname,
      telefonNumber: this.state.telefonNumber,
      telefonNumber2: this.state.telefonNumber2,
      contactPerson: this.state.contactPerson,
      contactPerson2: this.state.contactPerson2,
      email: this.state.email,
    };
    console.log("Client => " + JSON.stringify(c));
    ClientService.update(c, this.state.id).then((res) => {
      this.props.history.push("/customer-list/");
    });
  };

  changeNameHandler = (event) => {
    this.setState({ name: event.target.value });
  };

  changeSurNameHandler = (event) => {
    this.setState({ surname: event.target.value });
  };

  changeEmailHandler = (event) => {
    this.setState({ email: event.target.value });
  };

  changeTelefonNumberHandler = (event) => {
    this.setState({ telefonNumber: event.target.value });
  };

  changeTelefonNumber2Handler = (event) => {
    this.setState({ telefonNumber2: event.target.value });
  };

  changeContactPersonHandler = (event) => {
    this.setState({ contactPerson: event.target.value });
  };

  changeContactPerson2Handler = (event) => {
    this.setState({ contactPerson2: event.target.value });
  };

  cancel() {
    this.props.history.push("/customer-list/");
  }

  render() {
    if (cookies.get("token") !== "b5c9667644da67241316db50cc788bfd078849de") {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="card col-md-6 offset-md-3 offset-md-3">
              <div className="card-body">
                <div className="form-group">
                  <label> Vorname: </label>
                  <input
                    placeholder="Name"
                    name="Name"
                    className="form-control"
                    value={this.state.name}
                    onChange={this.changeNameHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Nachname: </label>
                  <input
                    placeholder="Name"
                    name="Name"
                    className="form-control"
                    value={this.state.surname}
                    onChange={this.changeSurNameHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Telefonnummer: </label>
                  <input
                    placeholder="Telefonnummer"
                    name="Telefonnummer"
                    className="form-control"
                    value={this.state.telefonNumber}
                    onChange={this.changeTelefonNumberHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Telefonnummer 2: </label>
                  <input
                    placeholder="Telefonnummer 2"
                    name="Telefonnummer 2"
                    className="form-control"
                    value={this.state.telefonNumber2}
                    onChange={this.changeTelefonNumber2Handler}
                  />
                </div>
                <div className="form-group">
                  <label> Ansprechpartner: </label>
                  <input
                    placeholder="Ansprechpartner"
                    name="Ansprechpartner"
                    className="form-control"
                    value={this.state.contactPerson}
                    onChange={this.changeContactPersonHandler}
                  />
                </div>
                <div className="form-group">
                  <label> Ansprechpartner 2: </label>
                  <input
                    placeholder="Ansprechpartner 2"
                    name="Ansprechpartner2"
                    className="form-control"
                    value={this.state.contactPerson2}
                    onChange={this.changeContactPerson2Handler}
                  />
                </div>
                <div className="form-group">
                  <label> Email: </label>
                  <input
                    placeholder="Email"
                    name="Email"
                    className="form-control"
                    value={this.state.email}
                    onChange={this.changeEmailHandler}
                  />
                </div>
                <Icon
                  name="save"
                  tooltip="Speichern"
                  theme="light"
                  size="medium"
                  onClick={this.saveOrUpdate}
                />
                <Icon
                  name="undo"
                  tooltip="Zurück zur Kundendatenbank"
                  theme="light"
                  size="medium"
                  onClick={this.cancel.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditClientComponent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(withRouter)(translate(EditClientComponent));
