import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Recentorder from './Recentorder';
import Scrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { OverlayTrigger, Tooltip, Tab, Nav } from "react-bootstrap";
import Chart from './Chart';
import Trendingorder from './Trendingorder';
import Orderchart from './Orderchart';
import Restaurantlisting from './Restaurantlisting';

const phonetip = (
    <Tooltip>
        Call
    </Tooltip>
);
const videotip = (
    <Tooltip>
        Video Call
    </Tooltip>
);
const invitetip = (
    <Tooltip>
        Add to Chat
    </Tooltip>
);
class Homecontent extends Component {
    render() {
        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-12">
                        <Chart />
                    </div>
                    {/* Recent Orders Requested */}
                    <div className="col-xl-6 col-md-12">
                        <div className="ms-panel">
                            <div className="ms-panel-header">
                                <div className="d-flex justify-content-between">
                                    <div className="align-self-center align-left">
                                        <h6>Aktuelle Bestellungen</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="ms-panel-body">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Food Item</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Product ID</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="ms-table-f-w"> <img src="assets/img/costic/pizza.jpg" alt="people" /> Pizza </td>
                                                <td>$19.99</td>
                                                <td>67384917</td>
                                            </tr>
                                            <tr>
                                                <td className="ms-table-f-w"> <img src="assets/img/costic/french-fries.jpg" alt="people" /> French Fries </td>
                                                <td>$14.59</td>
                                                <td>789393819</td>
                                            </tr>
                                            <tr>
                                                <td className="ms-table-f-w"> <img src="assets/img/costic/cereals.jpg" alt="people" /> Multigrain Hot Cereal </td>
                                                <td>$25.22</td>
                                                <td>137893137</td>
                                            </tr>
                                            <tr>
                                                <td className="ms-table-f-w"> <img src="assets/img/costic/egg-sandwich.jpg" alt="people" /> Fried Egg Sandwich </td>
                                                <td>$11.23</td>
                                                <td>235193138</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-header new">
                                <h6>Umsatz</h6>
                                <select className="form-control new" id="exampleSelect">
                                    <option value={1}>Januar</option>
                                    <option value={2}>Februar</option>
                                    <option value={3}>März </option>
                                    <option value={4}>April</option>
                                    <option value={5}>Mai</option>
                                    <option value={1}>Juni</option>
                                    <option value={2}>Juli</option>
                                    <option value={3}>August</option>
                                    <option value={4}>September</option>
                                    <option value={5}>Oktober</option>
                                    <option value={4}>November</option>
                                    <option value={5}>Dezember</option>
                                </select>
                            </div>
                            <div className="ms-panel-body">
                                <span className="progress-label"> <strong>1 Woche</strong> </span>
                                <div className="progress">
                                    <div className="progress-bar bg-primary" role="progressbar" style={{ width: '25%' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>25%</div>
                                </div>
                                <span className="progress-label"> <strong>2 Woche</strong> </span>
                                <div className="progress">
                                    <div className="progress-bar bg-primary" role="progressbar" style={{ width: '50%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}>50%</div>
                                </div>
                                <span className="progress-label"> <strong>3 Woche</strong> </span>
                                <div className="progress">
                                    <div className="progress-bar bg-primary" role="progressbar" style={{ width: '75%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}>75%</div>
                                </div>
                                <span className="progress-label"> <strong>4 Woche</strong> </span>
                                <div className="progress">
                                    <div className="progress-bar bg-primary" role="progressbar" style={{ width: '40%' }} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100}>40%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Food Orders */}
                    <div className="col-md-12">
                        <Trendingorder />
                    </div>
                    {/* END/Food Orders */}
                    {/* Recent Orders Requested */}
                    <div className="col-xl-7 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-header">
                                <div className="d-flex justify-content-between">
                                    <div className="ms-header-text">
                                        <h6>Order Timing Chart</h6>
                                    </div>
                                </div>
                            </div>
                            <Orderchart />
                        </div>
                    </div>
                    {/* Favourite Products */}
                    <div className="col-xl-5 col-md-12">
                        <div className="ms-panel ms-widget ms-crypto-widget">
                            <div className="ms-panel-header">
                                <h6>Täglicher Umsatz pro Store</h6>
                            </div>
                            <div className="ms-panel-body p-0">
                                <div className="table-responsive">
                                    <table className="table table-hover thead-light">
                                        <thead>
                                            <tr>
                                                <th scope="col">Store</th>
                                                <th scope="col">Bestellungen</th>
                                                <th scope="col">Entwicklung</th>
                                                <th scope="col">Gewinn</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Hunger House</td>
                                                <td>8528</td>
                                                <td className="ms-text-success">+17.24%</td>
                                                <td>7.65%</td>
                                            </tr>
                                            <tr>
                                                <td>Food Lounge</td>
                                                <td>4867</td>
                                                <td className="ms-text-danger">-12.24%</td>
                                                <td>9.12%</td>
                                            </tr>
                                            <tr>
                                                <td>Delizious</td>
                                                <td>7538</td>
                                                <td className="ms-text-success">+32.04%</td>
                                                <td>14.29%</td>
                                            </tr>
                                            <tr>
                                                <td>Netherfood</td>
                                                <td>1614</td>
                                                <td className="ms-text-danger">-20.75%</td>
                                                <td>12.25%</td>
                                            </tr>
                                            <tr>
                                                <td>Rusmiz</td>
                                                <td>7538</td>
                                                <td className="ms-text-success">+32.04%</td>
                                                <td>14.29%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* Favourite Products */}
                        {/* Total Earnings */}
                        <div className="ms-panel">
                            <div className="ms-panel-header">
                                <h6>Gesamtumsatz</h6>
                            </div>
                            <div className="ms-panel-body p-0">
                                <div className="ms-quick-stats">
                                    <div className="ms-stats-grid">
                                        <i className="fa fa-star" />
                                        <p className="ms-text-dark">8,033</p>
                                        <span>Heute</span>
                                    </div>
                                    <div className="ms-stats-grid">
                                        <i className="fa fa-university" />
                                        <p className="ms-text-dark">3,039</p>
                                        <span>Gestern</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Recent Support Tickets */}
                    {/* client chat */}
                </div>
            </div>

        );
    }
}

export default Homecontent;