import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL + "/api/v1/users";
const API_BASE_URL_MAILS = process.env.REACT_APP_API_URL + "/api/v1/mails";

class UserService {
  getAll() {
    return axios.get(API_BASE_URL, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getMails() {
    return axios.get(API_BASE_URL_MAILS, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getAllWorkers() {
    return axios.get(API_BASE_URL + "/workers", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getWorkerById(id) {
    return axios.get(API_BASE_URL + "/workers/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  removeWorker(id) {
    return axios.delete(API_BASE_URL + "/workers/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  updateWorker(one) {
    return axios.post(API_BASE_URL + "/workers/update", one, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getObjectList() {
    return axios.get(API_BASE_URL + "/objects", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getTimes(worker, year, month) {
    return axios.get(
      API_BASE_URL + "/workers/" + worker + "/" + year + "/" + month,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  printTimeTable(id) {
    return axios.get(API_BASE_URL + "/workers/times/" + id, {
      responseType: "blob",
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  updateTime(endpoint, id, data) {
    return axios.put(API_BASE_URL + endpoint + `/${id}`, data, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  saveTimes(worker, year, month, data) {
    return axios.post(
      API_BASE_URL + `/workers/${worker}/${year}/${month}`,
      data,
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  createWorker(worker) {
    return axios.post(API_BASE_URL + "/workers/", worker, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  create(client) {
    return axios.post(API_BASE_URL, client, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  exists(name, password) {
    return axios.get(API_BASE_URL + "/" + name + "/" + password, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getById(id) {
    return axios.get(API_BASE_URL + "/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getStations() {
    return axios.get(API_BASE_URL + "/stations", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getMaterials() {
    return axios.get(API_BASE_URL + "/materials", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  getMaterialStatusList() {
    return axios.get(API_BASE_URL + "/materials/statuslist/", {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  switchOff(id) {
    return axios.post(
      API_BASE_URL + "/materials/switchOff/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  setDefect(id) {
    return axios.post(
      API_BASE_URL + "/materials/setDefect/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  setFixed(id) {
    return axios.post(
      API_BASE_URL + "/materials/setFixed/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  switchOn(id) {
    return axios.post(
      API_BASE_URL + "/materials/switchOn/" + id,
      {},
      {
        headers: {
          "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
        },
      }
    );
  }

  update(u, id) {
    return axios.put(API_BASE_URL + "/" + id, u, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }

  delete(id) {
    return axios.delete(API_BASE_URL + "/" + id, {
      headers: {
        "X-API-KEY": "b5c9667644da67241316db50cc788bfd078849de",
      },
    });
  }
}

export default new UserService();
